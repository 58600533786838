import React, { useEffect } from "react";
import FormSender from "../components/orderPage/formSender/FormSender";
import { useParams } from "react-router";

const CONFIG = {
  cz: {
    title: "Objednej kurz",
    description: "Máš zájem o kurz? Potřebuješ více informací?",
  },
  cs: {
    title: "Objednej kurz",
    description: "Máš zájem o kurz? Potřebuješ více informací?",
  },
  hu: {
    title: "Rendelj tanfolyamot",
    description: "Érdekel a tanfolyam? Szeretnél több információt?",
  },
  en: {
    title: "Order a course",
    description: "Interested in the course? Would you like more information?",
  },
  sk: {
    title: "Objednať kurz",
    description: "Máte záujem o kurz? Chceli by ste viac informácií?",
  },
  lt: {
    title: "Užsakyk kursą",
    description: "Domina kursas? Norėtumėte daugiau informacijos?",
  },
  hr: {
    title: "Naruči tečaj",
    description: "Zanima te tečaj? Želiš više informacija?",
  },
  ro: {
    title: "Comandă un curs",
    description: "Ești interesat de curs? Ai dori mai multe informații?",
  },
};

const OrderPage = ({ data }) => {
  const { lang } = useParams();

  useEffect(() => {
    document.title = CONFIG[lang]?.title;
    document.getElementsByName("description")[0].content =
      CONFIG[lang]?.description;
  }, [lang]);

  return (
    <div>
      <FormSender data={data.formControll} />
    </div>
  );
};

export default OrderPage;
