import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";
import new_sweden from "../images/newProductPage/languages/SE.png";
import new_norway from "../images/newProductPage/languages/NO.png";
import new_grece from "../images/newProductPage/languages/GR.png";

import screen3 from "../images/newProductPage/slovak/1.png";
import screen4 from "../images/newProductPage/slovak/2.png";
import screen5 from "../images/newProductPage/slovak/3.png";
import screen6 from "../images/newProductPage/slovak/4.png";
import screen1 from "../images/newProductPage/slovak/5.png";
import screen2 from "../images/newProductPage/slovak/6.png";
import screen7 from "../images/newProductPage/slovak/7.png";

import Ildiko from "../images/newProductPage/slovak/Pavel.png";
import Katalin from "../images/newProductPage/slovak/Martin.png";
import Istvan from "../images/newProductPage/slovak/Vaclav.png";
import Zoltan from "../images/newProductPage/slovak/Radim.png";
import Gabor from "../images/newProductPage/slovak/Libuse.png";
import Maria from "../images/newProductPage/slovak/Lucie.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const slovak = {
  navbar: {
    data: [
      { title: "Objednať kurz", path: "/course" },
      { title: "Prihlásiť sa", path: "/start" },
      { title: "eLingo", path: "/" },
      { title: "O nás", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      hero: {
        languages: [
          "Anglicky",
          "Španielsky",
          "Nemecky",
          "Holandsky",
          "Taliansky",
          "Rusky",
          "Poľsky",
          "Francúzsky",
          "Portugalsky",
          // "Grécky",
          // "Švédsky",
          // "Nórsky",
        ],
        title: "Hovorte smelšie,",
        prefix: "sebavedomo",
        subtitle:
          "Získajte jazykové zručnosti prostredníctvom nášho kurzu založeného na skutočných situáciách!",
        buttonText: "Objednať teraz!",
      },
      labelInfo: {
        number: "9",
        numberDescription: "jazykov",
        itemTwo: "14 tisíc recenzií",
        itemThree: {
          top: "Viac ako 200 tisíc",
          bottom: "študentov",
        },
      },
      learning: {
        title: "Ako vyzerá kurz?",
        config: [
          {
            category: "Učte sa jazyk najľahším spôsobom",
            description:
              "Stovky lekcií s príkladmi jazyka používaných v skutočných konverzáciách. Praktické frázy vyučované v kurzoch navrhnutých rodilými hovorcami. Cvičenia efektívne učia čítanie, počúvanie a hovorenie",
          },
          {
            category: "Praktické lekcie",
            description:
              "Učenie na všetkých úrovniach pozostáva z desiatok lekcií. Ak neviete svoju úroveň - začnite so základmi. Naučte sa pozdravy, dni v týždni, ako objednať jedlo v reštaurácii. Každá lekcia obsahuje príklady zo všedného života.",
          },
          {
            category: "Úlohy",
            description:
              "Či už máte radi každodenné riešenie krížoviek alebo naopak takýmto aktivitám radšej vyhýbate, nemožno poprieť, že tieto hlavolamy sú vynikajúce na rozvoj pamäti a umožňujú opakovanie naučeného materiálu.",
          },
          {
            category: "Slová, frázy a výrazy",
            description:
              "Každú lekciu sprevádzajú starostlivo vybrané obrázky, ktoré robia učebný proces intuitívnym a kontextovým. Najčastejšie používané témy pokrývajú široký rozsah slovnej zásoby z každodenného života a cestovania, čo vám umožňuje komunikovať bez jazykových bariér v týchto oblastiach.",
          },
        ],
        photoData: [
          screen3,
          screen4,
          screen5,
          screen6,
          screen1,
          screen2,
          screen7,
        ],
      },
      languages: {
        languages: [
          { image: new_england, lang: "Anglický" },
          { image: new_french, lang: "Francúzsky" },
          { image: new_spanish, lang: "Španielsky" },
          { image: new_niderland, lang: "Holandský" },
          { image: new_germany, lang: "Nemecký" },
          { image: new_italy, lang: "Taliansky" },
          { image: new_russia, lang: "Ruský" },
          { image: new_portugal, lang: "Portugalský" },
          { image: new_polish, lang: "Poľský" },
          // { image: new_grece, lang: "Grécky" },
          // { image: new_sweden, lang: "Švédsky" },
          // { image: new_norway, lang: "Nórsky" },
        ],
        title: "Dostupné jazyky:",
        description: ` Pripravte sa na svoj ďalší výlet, pridajte jazykové zručnosti do svojho životopisu alebo sa ponorte do novej kultúry: objavte širokú kolekciu lekcií eLingo a naučte sa, čo potrebujete na začatie konverzácie, bez ohľadu na vašu úroveň skúseností.`,
      },
      success: {
        title: "Príbehy o úspechu",
        subtitle: ` Pozrite sa, ako iní hovoria plynule pomocou eLingo`,
        config: [
          {
            title: "Ideálne pre študentov",
            name: "Pavel",
            comment: `Ako študent si cením eLingo za jeho flexibilitu a prispôsobenie sa môjmu nabitým rozvrhom. Môžem sa učiť kdekoľvek a kedykoľvek, čo je neoceniteľné počas prípravy na zahraničné jazykové skúšky.`,
            photo: Ildiko,
          },
          {
            title: "Interaktívne a zábavné",
            name: "Martin",
            comment: `Nikdy predtým nebola náučka jazyka taká zábavná! eLingo ponúka množstvo interaktívnych hier a výziev, ktoré udržiavajú môj záujem a motiváciu. Odporúčam každému, kto hľadá príjemný spôsob, ako sa naučiť jazyk.`,
            photo: Katalin,
          },
          {
            title: "Skvelá podpora pre biznis",
            name: "Václav",
            comment: `Používam eLingo na učenie jazykov pre obchodné potreby a som ohromený. Program mi pomohol v komunikácii s medzinárodnými klientmi a partnermi, čo výrazne uľahčilo vedenie biznisu.`,
            photo: Istvan,
          },
          {
            title: "Ideálne pre začiatočníkov",
            name: "Radim",
            comment: `Ako začiatočníčka v učení jazyka, eLingo sa ukázalo ako ideálne riešenie. Lekcie sú ľahko pochopiteľné a systém opakovaní pomáha upevniť si materiál. Odporúčam to všetkým, ktorí začínajú svoje dobrodružstvo s novým jazykom.`,
            photo: Zoltan,
          },
          {
            title: "Efektívne učenie jazyka",
            name: "Libuše",
            comment: `eLingo je fantastický nástroj na učenie sa jazykov. Vďaka intuitívnym lekciám a interaktívnym cvičeniam je učenie efektívne a príjemné. Už po niekoľkých týždňoch som si všimol značné zlepšenie mojich jazykových zručností.`,
            photo: Gabor,
          },
          {
            title: "Flexibilné a prispôsobivé učenie",
            name: "Lucie",
            comment: `eLingo mi veľmi pomohlo pri zvládaní nového jazyka. Ich prispôsobivý prístup umožňuje učiť sa podľa vlastného tempa a záujmov. Vďaka rozmanitým tematickým lekciám som získala nielen jazykové zručnosti, ale aj cenné kultúrne znalosti.`,
            photo: Maria,
          },
        ],
      },
    },
    course: {
      title: "Ako vyzerá kurz?",
      right:
        "Na platforme eLingo nenájdete nudné, suché gramatické pravidlá, ktoré musíte za jeden deň naučiť. Učíte sa vety a výrazy používané v každodennom živote, potom ich upevňujete v pamäti jednoduchými cvičeniami a opakovaniami. Cieľom kurzu je učenie spríjemniť.",
      right2Title: "Skutočné lekcie",
      right2Bottom:
        "Učenie na každej úrovni pozostáva z desiatok lekcií. Ak neviete svoju úroveň - začnite so základmi. Naučte sa pozdravy, dni v týždni, svoje pocity. Naučte sa, ako objednať jedlo v reštaurácii a ako zavolať taxík. Každá lekcia zahŕňa príklady z každodenného života. Každá lekcia je trochu iná, pozostáva z rôznych úloh a cvičení, ale každá tvorí celok - aby ste mohli jazyk ľahko ovládnuť. Lekciu môžete začať kedykoľvek.",
      flexDown: [
        {
          title: "Počas učenia sa stretnete s prvkami ako:",
          text: `
Audio nahrávky učiace správnu výslovnosť,
`,
          text1: "Výrazy a vety z reálneho života,",
          text2: "Obrázky, ktoré vám pomôžu vizuálne spájať ",
          text3: "a zapamätať si slová a výrazy,",
          text4: "...a mnoho iných.",
        },
        {
          title: "Úlohy",
          text: `Bez ohľadu na to, či máte radi každodenné riešenie hlavolamov alebo naopak, radšej sa takýmto aktivitám vyhýbate, nemožno poprieť, že tieto hlavolamy sú vynikajúce na rozvoj pamäte a umožňujú opakovanie naučeného materiálu.`,
        },
        {
          title: "Repetitions",
          text: `More advanced learners can repeat vocabulary in continuous learning mode and test their knowledge in every subject area. It includes all available vocabulary, from every category. A learning algorithm constantly checks the level of knowledge and adjusts the following lessons accordingly.`,
        },
      ],
    },
    learningWithVerbalia: {
      title: "Učenie sa na jazykovej platforme eLingo:",
      cards: [
        {
          icon: learning1,
          headline: `Vysoká kvalita`,
          text: `
    Počas vývoja eLingo si expertný tím stanovil jeden z najdôležitejších cieľov: naučiť sa cudzí jazyk čo najrýchlejšie našou metódou (bez ohľadu na vek študenta!).
      `,
        },
        {
          icon: learning2,
          headline: `Lekcie bez odchodu z domu`,
          text: `Na používanie aplikácie eLingo potrebujete len telefón, počítač alebo tablet. S eLingo sa môžete učiť kdekoľvek: vo fronte, v autobuse a najmä doma bez potreby ísť niekam. Učenie sa počas kúpania alebo venčenia psa? S eLingo to je možné!
    `,
          text2:
            "Učenie sa s eLingo ušetrí veľa času na cestovanie a ľahko sa integruje do vášho rozvrhu!",
        },
        {
          icon: learning3,
          headline: `Krátké, ale systematické lekcie`,
          text: `Najdôležitejšie pri učení jazyka je pravidelnosť. Zároveň je pre nás všetkých ťažké nájsť potrebný čas. S tým na mysli je eLingo navrhnutá tak, aby učila v krátkych, ale systematických lekciách.`,
        },
      ],
    },
  },
  aboutUs: {
    hero: {
      title: "Poslanie",
      description: `Veríme, že jazyk tvorí most medzi kultúrami a našou misiou je prekonávať komunikačné bariéry a spájať ľudí prostredníctvom vzdelávania v oblasti jazykov.`,
    },
    why: {
      title: "Prečo My?",
      config: [
        {
          title: "Inovatívne Vzdelávacie Metódy",
          image: dlaczego1,
          description:
            "Náš sofistikovaný a v praxi overený algoritmus učenia prispôsobuje materiály vašej aktuálnej úrovni znalostí a rozširuje ju prostredníctvom efektívneho vyučovania.",
        },
        {
          title: "Široká Škála Jazykov",
          image: dlaczego2,
          description:
            "Ponúkame kurzy vo svetovo najpopulárnejších jazykoch. To vám umožňuje vybrať si jazyk podľa vašich potrieb.",
        },
        {
          title: "Vzdelávanie Zábavnou Formou",
          image: dlaczego3,
          description:
            "Naša platforma robí z učenia jazyka zábavný a príjemný zážitok, takže budete motivovaní učiť sa jazyky.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalizácia a Prístupnosť",
          description:
            "Náš efektívny algoritmus strojového učenia neustále pracuje na poskytovaní materiálov vhodných pre každého jednotlivca. eLingo umožňuje študentom objavovať vzory samostatne, bez potreby zameriavať sa na pravidlá jazyka - presne ako ste sa naučili svoj materinský jazyk v detstve.",
          image: zdjecie1,
        },
        {
          title: "Kombinácia Technológie a Vedy",
          description:
            "eLingo využíva odvážnu kombináciu technológie a vedy na stimuláciu mysle do akcie. Opakovanie nespočetných zoznamov slov a fráz je nudné a váš mozog to vie. Naša metóda je vzrušujúca a efektívna, núti vás používať získané vedomosti rôznymi spôsobmi v konkrétnych momentoch - váš mozog ocení toto riešenie!",
          image: zdjecie2,
        },
        {
          title: "Spoločenstvo a hodnoty",
          description:
            "Jazyky pomáhajú ľuďom zbližovať sa. Učenie sa jazykov uľahčuje porozumenie. Komunita eLingo spája ľudí z celého sveta. Sme ambiciózni a neustále posúvame hranice. Veríme, že učenie sa jazykov môže byť zábavné, a sme zvedaví na spoznávanie nových kultúr prostredníctvom jazyka.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Máte záujem o kurz?  ",
      title1: "Chcete získať viac informácií?",
      subtitle: "Zanechajte svoje telefónne číslo",
      subtitle1: "Zavoláme vám späť.",
      button1: "Meno a priezvisko",
      button2: "Telefónne číslo",
      button3: "Email",
      buttonSub: "Odoslať",
      checkOne:
        "Odoslaním tohto formulára súhlasím s podmienkami a vyhlasujem, že som si prečítal/a nasledujúce informácie",
      checkOneTwo: "the",
      checkTwo: "Súhlasím s kontaktovaním na marketingové účely",
      textOne: "Dovoľujeme si vás informovať, že",
      textTwo:
        "Správcom vašich osobných údajov je spoločnosť  EOU Sp. z o.o., so sídlom vo Varšave, Młynarska 42 / 115, 01-171, zapísaná v registri podnikateľov vedenom okresným súdom vo Varšave pod číslom KRS5273057993, REGON: 525396900, NIP5273057993. Vaše osobné údaje budú spracúvané v súvislosti s dopytom prostredníctvom kontaktného formulára alebo s plnením uzatvorenej zmluvy. Kontaktovaním nás dobrovoľne súhlasíte so spracovaním vašich osobných údajov na vyššie uvedené účely. Máte právo na prístup k svojim údajom, ich opravu, vymazanie alebo obmedzenie spracovania, právo namietať proti spracovaniu a právo na prenosnosť údajov. Máte tiež právo svoj súhlas kedykoľvek odvolať, ale odvolanie súhlasu nemá vplyv na zákonnosť spracovania založeného na súhlase pred jeho odvolaním. Máte tiež právo podať sťažnosť dozornému orgánu. Poskytnutie údajov je dobrovoľné, ale môže byť nevyhnutné na zodpovedanie žiadosti alebo na plnenie zmluvy. Osobné údaje sa budú uchovávať po dobu nevyhnutnú na vyššie uvedené účely, nie však dlhšie, ako je doba premlčania nárokov podľa všeobecných právnych predpisov. Pozorne si prečítajte zásady ochrany osobných údajov",
      buttonMore: "Prečítajte si viac",
      buttonLess: "Čítajte menej",
      subOne: "podmienky",
      subTwo: "a pravidlá ochrany osobných údajov.",
      buttonTXT: "Ďakujem",
      rulesLink: "/Regulations_SK.pdf",
      privacyLink: "/Privacy_SK.pdf",
      product: "es",
      market: "sk",
      send_to_url: "https://crm.europa-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_eLingo",
      product_variant: "sk-en",
      server_link: "https://cms.europa-assets.eu/integration/lead-from-landing",
      currency: "eur",
      price: 87,
    },
  },
  footer: {
    privacy: "Ochrana osobných údajov",
    privacyLink: "/Privacy_SK.pdf",
    rules: "Podmienky služby",
    rulesLink: "/Regulations_SK.pdf",
  },
};
