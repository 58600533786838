import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";
import new_sweden from "../images/newProductPage/languages/SE.png";
import new_norway from "../images/newProductPage/languages/NO.png";
import new_grece from "../images/newProductPage/languages/GR.png";

import screen1 from "../images/newProductPage/romanian/1.png";
import screen2 from "../images/newProductPage/romanian/2.png";
import screen3 from "../images/newProductPage/romanian/3.png";
import screen4 from "../images/newProductPage/romanian/4.png";
import screen5 from "../images/newProductPage/romanian/5.png";
import screen6 from "../images/newProductPage/romanian/6.png";

import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const romanian = {
  navbar: {
    data: [
      { title: "Comandă un Curs", path: "/course" },
      { title: "Autentificare", path: "/start" },
      { title: "eLingo", path: "/" },
      { title: "Despre Noi", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      hero: {
        languages: [
          "în Engleză",
          "în Spaniolă",
          "în Germană",
          "în Olandeză",
          "în Italiană",
          "în Rusă",
          "în Poloneză",
          "în Franceză",
          "în Portugheză",
          // "in Suedeză",
          // "in Norvegiană",
          // "in Greacă",
        ],
        title: "Vorbește mai",
        prefix: "cu încredere",
        subtitle: `Câștigă abilități lingvistice prin cursul nostru bazat pe situații din viața reală!`,
        buttonText: "Comandă Acum!",
      },
      labelInfo: {
        number: "9",
        numberDescription: "limbi",
        itemTwo: "14 000 recenzii",
        itemThree: {
          top: "Mai mult de 200 de mii",
          bottom: "de cursanți",
        },
      },
      learning: {
        title: "Cum arată cursul?",
        config: [
          {
            category: "Învață limba în cel mai ușor mod",
            description:
              "Sute de lecții cu exemple de limbaj folosite în conversații realeExpresii practice predate în cursuri concepute de vorbitori nativiExerciții care predau eficient abilitățile de citire, ascultare și vorbire",
          },
          {
            category: "Lecții practice",
            description:
              "Învățarea la toate nivelurile constă din zeci de lecții. Dacă nu știi nivelul tău - începe cu noțiunile de bază. Învață saluturile, zilele săptămânii, cum să comanzi mâncare într-un restaurant. Fiecare lecție conține exemple din viața de zi cu zi.",
          },
          {
            category: "Sarcini",
            description:
              "Indiferent dacă îți place să rezolvi cuvinte încrucișate zilnic sau, dimpotrivă, preferi să eviți astfel de activități, este incontestabil că aceste puzzle-uri sunt excelente pentru dezvoltarea memoriei și permit repetarea materialului învățat.",
          },
          {
            category: "Cuvinte, zicale și expresii",
            description:
              "Fiecare lecție este însoțită de imagini atent selectate care fac procesul de învățare intuitiv și contextual. Cele mai frecvent utilizate subiecte acoperă o gamă largă de vocabular din viața de zi cu zi și călătorii, permițându-vă să comunicați fără bariere lingvistice în aceste domenii.",
          },
        ],
        photoData: [screen1, screen2, screen3, screen4, screen5, screen6],
      },
      languages: {
        languages: [
          { image: new_england, lang: "Engleză" },
          { image: new_french, lang: "Franceză" },
          { image: new_spanish, lang: "Spaniolă" },
          { image: new_niderland, lang: "Olandeză" },
          { image: new_germany, lang: "Germană" },
          { image: new_italy, lang: "Italiană" },
          { image: new_russia, lang: "Rusă" },
          { image: new_portugal, lang: "Portugheză" },
          { image: new_polish, lang: "Poloneză" },
          // { image: new_sweden, lang: "Suedeză" },
          // { image: new_norway, lang: "Norvegiană" },
          // { image: new_grece, lang: "Greacă" },
        ],
        title: "Limbi disponibile:",
        description: ` Pregătește-te pentru următoarea ta călătorie, adaugă competențe lingvistice în CV-ul tău sau cufundă-te într-o nouă cultură: descoperă colecția largă de lecții eLingo și învață ce ai nevoie pentru a începe o conversație, indiferent de nivelul tău de experiență.`,
      },
      success: {
        title: "Povești de Succes",
        subtitle: ` Vedeți cum alții vorbesc fluent folosind eLingo`,
        config: [
          {
            title: "Revelație!",
            name: "Sebastian",
            comment: `Tocmai am început să învăț spaniola și cursul eLingo este o revelație pentru mine. Lecțiile m-au ajutat să încep să vorbesc cu încredere rapid. O recomand cu căldură!`,
          },
          {
            title: "Cea mai bună decizie!",
            name: "Ana Maria",
            comment: `Cursul de italiană de la eLingo este cea mai bună decizie pe care am luat-o în acest an. Îmi place cum cursul integrează învățarea cu situații zilnice!`,
          },
          {
            title: "Amestec excelent de lecții!",
            name: "Dorian",
            comment: `Nu am întâlnit niciodată o metodă atât de intuitivă de a învăța o limbă. eLingo oferă o combinație excelentă de lecții și exerciții care ajută cu adevărat la stăpânirea limbii germane.`,
          },
          {
            title: "Este uimitor!",
            name: "Elena",
            comment: `eLingo a schimbat modul meu de a aborda învățarea limbilor. Engleza a devenit naturală pentru mine datorită imaginilor contextuale și expresiilor zilnice. Este uimitor cât de repede poți simți progresul!`,
          },
          {
            title: "Curs fantastic!",
            name: "Mădălin",
            comment: `Datorită eLingo, călătoria mea în Franța a fost o plăcere. Lecțiile au fost atât de practice încât, la sosire, m-am simțit încrezător comandând mâncare în restaurante și vorbind cu localnicii. Un curs fantastic!`,
          },
          {
            title: "Recomand tuturor!",
            name: "Roxana",
            comment: `Rusa mi s-a părut întotdeauna dificilă, dar cursul eLingo a făcut ca învățarea să devină simplă și intuitivă. Sunt impresionată și o recomand oricui vrea să învețe cu plăcere!`,
          },
        ],
      },
    },
    course: {
      title: "Cum Arată un Curs?",
      right:
        "Pe platforma eLingo, nu vei găsi reguli gramaticale plictisitoare și uscate pe care trebuie să le înveți într-o zi. Înveți propoziții și expresii folosite în viața de zi cu zi, apoi le consolidezi în memorie cu exerciții simple și repetiții. Scopul cursului este de a face învățarea distractivă.",
      right2Title: "Lecții Reale",
      right2Bottom:
        "Învățarea la fiecare nivel constă din zeci de lecții. Dacă nu știi nivelul tău - începe cu noțiunile de bază. Învață saluturile, zilele săptămânii, sentimentele tale. Învață cum să comanzi mâncare într-un restaurant și cum să chemi un taxi. Fiecare lecție include exemple din viața de zi cu zi. Fiecare lecție este puțin diferită, constând din diverse sarcini și exerciții, dar fiecare formează un întreg – astfel încât să poți stăpâni ușor limba. Poți începe lecția oricând.",
      flexDown: [
        {
          title: "În timpul învățării, vei întâlni elemente precum:",
          text: `
      Înregistrări audio care predau pronunția corectă,,
    `,
          text1: "Expresii și propoziții din viața reală,",
          text2: "Imagini care te ajută să asociezi vizual ",
          text3: "și să îți amintești cuvinte și expresii,",
          text4: "...și multe altele.",
        },
        {
          title: "Sarcini",
          text: `Indiferent dacă îți place să rezolvi puzzle-uri zilnic sau, dimpotrivă, preferi să eviți astfel de activități, este incontestabil că aceste puzzle-uri sunt excelente pentru dezvoltarea memoriei și permit repetarea materialului învățat.`,
        },
        {
          title: "Repetiții",
          text: `Cei mai avansați elevi pot repeta vocabularul în modul de învățare continuă și își pot testa cunoștințele în fiecare domeniu tematic. Include tot vocabularul disponibil, din fiecare categorie. Un algoritm de învățare verifică constant nivelul de cunoștințe și ajustează lecțiile următoare în consecință.`,
        },
      ],
    },
    learningWithVerbalia: {
      title: "Învățarea pe Platforma de Limbă eLingo:",
      cards: [
        {
          icon: learning1,
          headline: `Calitate Înaltă`,
          text: `În timpul dezvoltării eLingo, o echipă de experți și-a stabilit unul dintre cele mai importante obiective: să facă învățarea unei limbi străine cât mai rapidă posibil cu metoda noastră (indiferent de vârsta elevului!).`,
        },
        {
          icon: learning2,
          headline: `Lecții Fără a Părăsi Casa`,
          text: `Pentru a folosi aplicația de învățare eLingo, tot ce ai nevoie este telefonul, computerul sau tableta. Cu eLingo, poți învăța oriunde: la coadă, în autobuz și cel mai important, acasă fără a fi nevoie să mergi undeva. Învățarea în timp ce faci baie sau plimbi câinele? Cu eLingo, este posibil!`,
          text2:
            "Învățarea cu eLingo economisește mult timp de deplasare și poți integra ușor învățarea în programul tău!",
        },
        {
          icon: learning3,
          headline: `Lecții Scurte, dar Sistematice`,
          text: `Cel mai important lucru în învățarea limbilor este regularitatea. În același timp, este greu pentru noi toți să găsim timpul necesar. Având acest lucru în minte, eLingo este conceput pentru a preda în lecții scurte, dar sistematice.`,
        },
      ],
    },
    whyVerbalia: {
      title: "Cum Arată un Curs?",
      textUp:
        "Aceasta este o platformă cu o abordare multifacetică a învățării, care te ajută să stăpânești o nouă limbă la fel de natural ca limba maternă.",
      textBottom:
        "Învățarea unei limbi străine îți poate schimba viața. Nu doar deschide o fereastră către o lume plină de oportunități profesionale și personale, dar și dezvoltă mintea și îți sporește încrederea. Pe măsură ce tehnologiile precum internetul reduc distanțele și devenim membri ai unei comunități globale, capacitatea de a învăța limbi devine o parte esențială a interacțiunii noastre cu lumea și a succesului nostru.",
      cards: [
        {
          headline: `Învață expresii și fraze din viața de zi cu zi cu tehnologia noastră avansată de învățare.`,
          text: `Lecțiile sunt ajustate continuu la nivelul tău, astfel încât nu rămâi niciodată fără plictiseală sau motivație.`,
        },
        {
          headline: `Scufundă-te într-o limbă străină. Încearcă să înțelegi pe deplin o nouă limbă bazându-te pe cunoștințele tale contextuale.`,
          text: `Cu mii de grafice și înregistrări audio, vei vedea și auzi ca un vorbitor nativ.`,
        },
        {
          headline: `GCâștigă încredere în vorbire. Scopul real al învățării unei limbi este, de asemenea, să înțelegi.`,
          text: `Cu eLingo, tot ce trebuie să faci este să repeți vocabularul pe care îl auzi - nu-ți fie frică să vorbești! Sistemul nostru te va corecta.`,
        },
        {
          headline: `Nu lăsa cuvintele să-ți scape din memorie. Învață prin diverse exerciții.`,
          text: `Am proiectat cu atenție elementele aplicației astfel încât mintea ta să nu se plictisească niciodată. Suntem în continuă pregătire și extindere a eLingo cu noi provocări pentru a face învățarea distractivă.`,
        },
      ],
    },
  },
  aboutUs: {
    hero: {
      title: "Misiune",
      description: `Credem că limba formează un pod între culturi, iar misiunea noastră este să depășim barierele de comunicare și să aducem oamenii împreună prin educație lingvistică.`,
    },
    why: {
      title: "De Ce Noi?",
      config: [
        {
          title: "Metode Educaționale Inovatoare",
          image: dlaczego1,
          description:
            "Algoritmul nostru de învățare sofisticat și testat în practică ajustează materialele la nivelul actual de cunoștințe și îl extinde prin predare eficientă.",
        },
        {
          title: "O Gamă Largă de Limbi",
          image: dlaczego2,
          description:
            "Oferim cursuri în cele mai populare limbi ale lumii. Acest lucru îți permite să alegi limba care se potrivește nevoilor tale.",
        },
        {
          title: "Educație într-un Mod Distractiv",
          image: dlaczego3,
          description:
            "Platforma noastră face învățarea limbilor o experiență distractivă și plăcută, astfel încât vei fi motivat să înveți limbi.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalizare și Accesibilitate",
          description:
            "Algoritmul nostru eficient de învățare automată lucrează constant pentru a oferi materiale de nivel adecvat pentru fiecare individ. eLingo permite cursanților să descopere modele pe cont propriu fără a trebui să se concentreze pe regulile limbii - exact așa cum ai învățat limba maternă în copilărie.",
          image: zdjecie1,
        },
        {
          title: "Combinarea Tehnologiei și Științei",
          description:
            "eLingo folosește o combinație îndrăzneață de tehnologie și știință pentru a stimula mintea la acțiune. Repetarea nenumăratelor liste de cuvinte și expresii este plictisitoare, iar creierul tău știe asta. Metoda noastră este captivantă și eficientă, forțându-te să folosești cunoștințele dobândite în diferite moduri în momente specifice - creierul tău va aprecia această soluție!",
          image: zdjecie2,
        },
        {
          title: "Comunitate și Valori",
          description:
            "Limbile ajută oamenii să se angajeze. Învățarea limbilor facilitează înțelegerea. Comunitatea eLingo aduce împreună oameni din întreaga lume. Suntem ambițioși și mereu împingem limitele. Credem că învățarea limbilor poate fi distractivă și suntem curioși să explorăm noi culturi prin limbă.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Interesat de curs? ",
      title1: "Doriți mai multe informații?",
      subtitle: "Lăsați-vă numărul de telefon - vă vom suna înapoi.",
      subtitle1:
        "Dacă aveți deja un cont, vă rugăm să completați următoarele câmpuri:",
      button1: "Nume și Prenume",
      button2: "Număr de Telefon",
      button3: "Email",
      buttonSub: "Trimite",
      checkOne:
        "Prin trimiterea acestui formular, accept termenii și declar că am citit următorul",
      checkOneTwo: "documentul",
      checkTwo: "Consimt să fiu contactat în scopuri de marketing",
      textOne: "Vă informăm că",
      textTwo:
        "Operatorul datelor dvs. personale este EOU Sp. z o.o., cu sediul în Varșovia, Młynarska 42 / 115, 01-171, înregistrată în registrul antreprenorilor ținut de tribunalul districtual din Varșovia sub KRS5273057993, REGON: 525396900, NIP5273057993. Datele dvs. personale vor fi prelucrate în legătură cu solicitarea formulată prin formularul de contact sau executarea contractului încheiat. Contactându-ne, consimțiți voluntar la prelucrarea datelor dvs. personale în scopurile menționate anterior. Aveți dreptul de a accesa, corecta, șterge sau restricționa prelucrarea datelor dvs., de a vă opune prelucrării și de a portabilității datelor. De asemenea, aveți dreptul de a vă retrage consimțământul în orice moment, dar retragerea consimțământului nu afectează legalitatea prelucrării bazate pe consimțământ înainte de retragerea acestuia. De asemenea, aveți dreptul de a depune o plângere la autoritatea de supraveghere. Furnizarea datelor este voluntară, dar poate fi necesară pentru a răspunde la o solicitare sau pentru executarea unui contract. Datele personale vor fi stocate pentru perioada necesară scopurilor de mai sus, dar nu mai mult decât perioada de prescripție a revendicărilor conform dreptului comun. Vă rugăm să citiți cu atenție politica de confidențialitate",
      buttonMore: "Citește mai mult",
      buttonLess: "Citește mai puțin",
      subOne: "termeni",
      subTwo: "și reguli de confidențialitate.",
      buttonTXT: "Mulțumesc",
      rulesLink: "/Regulations_RO.pdf",
      privacyLink: "/Privacy_RO.pdf",
      product: "er",
      market: "ro",
      send_to_url: "https://crm.europa-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_eLingo",
      product_variant: "ro-en",
      server_link: "https://cms.europa-assets.eu/integration/lead-from-landing",
      currency: "ron",
      price: 390,
    },
  },
  footer: {
    privacy: "Privacy",
    privacyLink: "/Privacy_RO.pdf",
    rules: "Termeni de serviciu",
    rulesLink: "/Regulations_RO.pdf",
  },
};
