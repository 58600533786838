export const Colors = {
  mainColor: "#1e2d6a",
  lightBlue: "#899ed1",
  extraLightBlue: "#D0E5FF",
  yellow: "#E7CF49",
  darkDarkBlue: "rgb(48, 90, 166)",
  newYellow: "#E7CF48",
  sea: "#025072",
  newLightBlue: "#0384bc",
  black: "#000",
  white: "#fff",
  verbaliaLight: "#A47AB5",
  lightGreen: "#97ad45",
  gray: "#f5f5f5",
  lighterMainColor: "#dfe1ea",
};
