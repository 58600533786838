import de from "./language-flags/de.png";
import ru from "./language-flags/ru.png";
import es from "./language-flags/es.png";
import fr from "./language-flags/fr.png";
import it from "./language-flags/it.png";
import nl from "./language-flags/nl.png";
import pl from "./language-flags/pl.png";
import en from "./language-flags/en.png";
import pt from "./language-flags/pt.png";
import el from "./language-flags/el.png";
import no from "./language-flags/no.png";
import sv from "./language-flags/sv.png";

const languageFlags = {
  de,
  ru,
  es,
  fr,
  it,
  nl,
  pl,
  en,
  pt,
  el,
  no,
  sv
};

const getLanguageFlag = (short) => {
  return languageFlags[short] ? languageFlags[short] : languageFlags.en;
};

export default getLanguageFlag;
