import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";
import new_sweden from "../images/newProductPage/languages/SE.png";
import new_norway from "../images/newProductPage/languages/NO.png";
import new_grece from "../images/newProductPage/languages/GR.png";

import screen1 from "../images/newProductPage/english/1.png";

import Ildiko from "../images/newProductPage/hungarian/people/Ildiko.png";
import Katalin from "../images/newProductPage/hungarian/people/Katalin.png";
import Istvan from "../images/newProductPage/hungarian/people/Istvan.png";
import Zoltan from "../images/newProductPage/hungarian/people/Zoltan.png";
import Gabor from "../images/newProductPage/hungarian/people/Gabor.png";
import Maria from "../images/newProductPage/hungarian/people/Maria.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const english = {
  navbar: {
    data: [
      { title: "Order a Course", path: "/course" },
      { title: "Login", path: "/start" },
      { title: "eLingo", path: "/" },
      { title: "About Us", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      hero: {
        languages: [
          "in English",
          "in Spanish",
          "in German",
          "in Dutch",
          "in Italian",
          "in Russian",
          "in Polish",
          "in French",
          "in Portuguese",
          // "in Greek",
          // "in Swedish",
          // "in Norwegian",
        ],
        title: "Speak more boldly,",
        prefix: "confidently",
        subtitle: `Gain language skills through our course based on real-life situations!`,
        buttonText: "Order Now!",
      },
      labelInfo: {
        number: "9",
        numberDescription: "languages",
        itemTwo: "14 000 reviews",
        itemThree: {
          top: "More than 200 thousand",
          bottom: "learners",
        },
      },
      learning: {
        title: "What does the course look like?",
        config: [
          {
            category: "Learn language in the easiest way",
            description:
              "Hundreds of lessons with language examples used in real conversationsPractical expressions taught in courses designed by native speakersExercises effectively teach reading, listening, and speaking skills",
          },
          {
            category: "Practical lessons",
            description:
              "Learning at all levels consists of dozens of lessons. If you don't know your level – start with the basics. Learn greetings, days of the week, how to order food in a restaurant. Each lesson contains examples from everyday life.",
          },
          {
            category: "Tasks",
            description:
              "Regardless of whether you like to solve crosswords daily or, on the contrary, prefer to avoid such activities, it is undeniable that these puzzles are excellent for developing memory and allow for the repetition of learned material.",
          },
          {
            category: "Words, sayings, and expressions",
            description:
              "Each lesson is accompanied by carefully selected images that make the learning process intuitive and contextual. The most commonly used topics cover a wide range of vocabulary from everyday life and travel, allowing you to communicate without language barriers in these areas.",
          },
        ],
        photoData: [screen1],
      },
      languages: {
        languages: [
          { image: new_england, lang: "English" },
          { image: new_french, lang: "French" },
          { image: new_spanish, lang: "Spanish" },
          { image: new_niderland, lang: "Dutch" },
          { image: new_germany, lang: "German" },
          { image: new_italy, lang: "Italian" },
          { image: new_russia, lang: "Russian" },
          { image: new_portugal, lang: "Portuguese" },
          { image: new_polish, lang: "Polish" },
          // { image: new_grece, lang: "Greek" },
          // { image: new_sweden, lang: "Swedish" },
          // { image: new_norway, lang: "Norwegian" },
        ],
        title: "Available languages:",
        description: ` Get ready for your next trip, add language skills to your resume, or immerse yourself in a new culture: discover the wide collection of eLingo lessons and learn what you need to start a conversation, regardless of your experience level.`,
      },
      success: {
        title: "Success Stories",
        subtitle: ` See how others speak fluently using eLingo`,
        config: [
          {
            title: "Practical lessons",
            name: "Ildikó",
            comment: `Whether you're a beginner starting with the basics or an advanced learner looking to expand your vocabulary, eLingo offers something for everyone. One thing I love about this platform is the variety of lessons, from dining in a restaurant to calling a taxi, making the learning experience realistic and practical. Additionally, the practical expressions and recordings demonstrating correct pronunciation are a great benefit.`,
            photo: Ildiko,
          },
          {
            title: "Practical Lessons",
            name: "Katalin",
            comment: `I love how the eLingo lessons are tailored to real situations, making it easy to apply what I've learned in reality. After 3 months of study, I was offered a new job!`,
            photo: Katalin,
          },
          {
            title: "Interesting Platform",
            name: "István",
            comment: `eLingo is an excellent language learning platform. The variety of lessons and exercises makes the learning process interesting and appealing. Thanks to language learning, I met a German girl online, and we are getting married soon. I recommend it.`,
            photo: Istvan,
          },
          {
            title: "I Use It at My Workplace!",
            name: "Zoltán",
            comment: `In my opinion, eLingo is a wonderful platform for anyone looking to improve their language skills. The platform is designed to make learning a new language an enjoyable experience.`,
            photo: Zoltan,
          },
          {
            title: "Pleasant Learning",
            name: "Gábor",
            comment: `I've been using eLingo for several months now, and I can confidently say it has changed my life. I was promoted at my workplace! I've always been interested in mastering a new language, but never had the time or resources. With eLingo, I can learn at my own pace and on my own schedule.`,
            photo: Gabor,
          },
          {
            title: "Using It for a Few Months.",
            name: "Mária",
            comment: `I've been using eLingo for several months now, and I must say, it has brought a big change for me. I've always been interested in mastering a new language, but never had the opportunity, but eLingo made learning so simple and comfortable for me. Today, I understand and communicate with people of different cultures, which has broadened my horizons and opened new opportunities for me.`,
            photo: Maria,
          },
        ],
      },
    },
    course: {
      title: "What Does a Course Look Like?",
      right:
        "On the eLingo platform, you won't find boring, dry grammatical rules that you have to learn in a day. You learn sentences and expressions used in everyday life, then consolidate them in memory with simple exercises and repetitions. The goal of the course is to make learning fun.",
      right2Title: "Real Lessons",
      right2Bottom:
        "Learning at every level consists of dozens of lessons. If you don't know your level - start with the basics. Learn greetings, days of the week, your feelings. Learn how to order food in a restaurant, and how to order a taxi. Every lesson includes examples from everyday life. Each lesson is a little different, consisting of various tasks and exercises, but each forms a whole – so you can easily master the language. You can start the lesson anytime.",
      flexDown: [
        {
          title: "During learning, you will encounter elements such as:",
          text: `
      Audio recordings teaching correct pronunciation,
    `,
          text1: "Expressions and sentences from real life,",
          text2: "Images that help you visually associate ",
          text3: "and remember words and expressions,",
          text4: "...and many others.",
        },
        {
          title: "Tasks",
          text: `Regardless of whether you like solving puzzles daily or, on the contrary, prefer to avoid such activities, it is undeniable that these puzzles are excellent for developing memory and allow for the repetition of learned material.`,
        },
        {
          title: "Repetitions",
          text: `More advanced learners can repeat vocabulary in continuous learning mode and test their knowledge in every subject area. It includes all available vocabulary, from every category. A learning algorithm constantly checks the level of knowledge and adjusts the following lessons accordingly.`,
        },
      ],
    },
    learningWithVerbalia: {
      title: "Learning on the eLingo Language Platform:",
      cards: [
        {
          icon: learning1,
          headline: `High Quality`,
          text: `
      During the development of eLingo, an expert team set one of the most important goals: to make learning a foreign language as fast as possible with our method (regardless of the learner's age!).
        `,
        },
        {
          icon: learning2,
          headline: `Lessons Without Leaving Home`,
          text: `To use the eLingo learning app, all you need is your phone, computer, or tablet. With Eureka, you can learn anywhere: in line, on the bus, and most importantly, at home without having to go anywhere. Learning while bathing or walking the dog? With Eureka, it's possible!
      `,
          text2:
            "Learning with eLingo saves a lot of commuting time and you can easily integrate learning into your schedule!",
        },
        {
          icon: learning3,
          headline: `Short, but Systematic Lessons`,
          text: `The most important thing in language learning is regularity. At the same time, it's hard for all of us to find the necessary time. With this in mind, Eureka is designed to teach in short but systematic lessons.`,
        },
      ],
    },
    whyVerbalia: {
      title: "What Does a Course Look Like?",
      textUp:
        "This is a multifaceted learning approach platform that helps you master a new language as naturally as your mother tongue.",
      textBottom:
        "Learning a foreign language can change your life. It not only opens a window to a world full of professional and personal opportunities but also develops your mind and boosts your confidence. As technologies like the internet reduce distances and we become members of a global community, the ability to learn languages becomes an essential part of our interaction with the world and our success.",
      cards: [
        {
          headline: `Learn expressions and phrases from everyday life with our advanced learning technology.`,
          text: `The lessons are continuously adjusted to your level, so you never run out of boredom or motivation.`,
        },
        {
          headline: `Immerse yourself in a foreign language. Try to fully understand a new language based on your contextual knowledge.`,
          text: `With thousands of graphics and audio recordings, you will see and hear like a native speaker.`,
        },
        {
          headline: `Gain confidence in speaking. The real goal of language learning is also to understand.`,
          text: `With Eureka, all you have to do is repeat the vocabulary you hear - don't be afraid to speak! Our system will correct you.`,
        },
        {
          headline: `Don't let words slip out of your memory. Learn through various exercises.`,
          text: `We have carefully designed the app's elements so that your mind never gets bored. We are constantly preparing and expanding Eureka with new challenges to make learning fun.`,
        },
      ],
    },
  },
  aboutUs: {
    hero: {
      title: "Mission",
      description: `We believe that language forms a bridge between cultures, and our mission is to overcome communication barriers and bring people together through language education.`,
    },
    why: {
      title: "Why Us?",
      config: [
        {
          title: "Innovative Educational Methods",
          image: dlaczego1,
          description:
            "Our sophisticated and practice-tested learning algorithm adjusts the materials to your current level of knowledge and expands it through effective teaching.",
        },
        {
          title: "Wide Range of Languages",
          image: dlaczego2,
          description:
            "We offer courses in the world's most popular languages. This allows you to choose the language that suits your needs.",
        },
        {
          title: "Education in a Fun Way",
          image: dlaczego3,
          description:
            "Our platform makes language learning a fun and enjoyable experience, so you will be motivated to learn languages.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalization and Accessibility",
          description:
            "Our efficient machine learning algorithm constantly works to provide appropriate level materials for every individual. eLingo enables learners to discover patterns on their own without having to focus on the rules of language – just like you learned your mother tongue in childhood.",
          image: zdjecie1,
        },
        {
          title: "Combination of Technology and Science",
          description:
            "eLingo uses a bold combination of technology and science to stimulate the mind into action. Repeating countless lists of words and expressions is boring, and your brain knows it. Our method is exciting and effective, forcing you to use acquired knowledge in different ways at specific moments - your brain will appreciate this solution!",
          image: zdjecie2,
        },
        {
          title: "Community and Values",
          description:
            "Languages help people commit. Learning languages facilitates understanding. The eLingo community brings people together from all over the world. We are ambitious and always pushing boundaries. We believe that learning languages can be fun and are curious to explore new cultures through language.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Interested in the course? ",
      title1: "Would you like more information?",
      subtitle: "Leave your phone number - we will call you back.",
      subtitle1:
        "If you already have an account, please fill in the following fields:",
      button1: "Name and Surname",
      button2: "Phone Number",
      button3: "Email",
      buttonSub: "Send",
      checkOne:
        "By submitting this form, I accept the terms and declare that I have read the following",
      checkOneTwo: "the",
      checkTwo: "I consent to being contacted for marketing purposes",
      textOne: "Please be informed that",
      textTwo:
        "The controller of your personal data is EOU Sp. z o.o., headquartered in Warsaw, Młynarska 42 / 115, 01-171, registered in the entrepreneurs' register maintained by the district court of Warsaw under KRS5273057993, REGON: 525396900, NIP5273057993. Your personal data will be processed in relation to the contact form inquiry or the execution of the concluded contract. By contacting us, you voluntarily consent to the processing of your personal data for the aforementioned purposes. You have the right to access, correct, delete, or restrict processing of your data, to object to processing, and to data portability. You also have the right to withdraw your consent at any time, but the withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal. You also have the right to file a complaint with the supervisory authority. Providing data is voluntary but may be necessary to respond to an inquiry or execute a contract. Personal data will be stored for the period necessary for the above purposes, but not longer than the period of limitation of claims under general law. Please read the privacy policy carefully",
      buttonMore: "Read more",
      buttonLess: "Read less",
      subOne: "terms",
      subTwo: "and privacy rules.",
      buttonTXT: "Thank you",
      rulesLink: "/Regulations_EN.pdf",
      privacyLink: "/Privacy_EN.pdf",
      product: "es",
      market: "sk",
      send_to_url: "https://crm.europa-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_eLingo",
      product_variant: "sk-en",
      server_link: "https://cms.europa-assets.eu/integration/lead-from-landing",
      currency: "eur",
      price: 87,
    },
  },
  footer: {
    privacy: "Privacy",
    privacyLink: "/Privacy_EN.pdf",
    rules: "Terms of service",
    rulesLink: "/Regulations_EN.pdf",
  },
};
