import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";

const LanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  gap: 20px;

  @media (max-width: 1200px) {
    max-width: 500px;
  }

  @media (max-width: 600px) {
    max-width: 350px;
  }
`;

const SectionThree = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const PhotoWraper = styled.div`
  width: 110px;
  overflow: hidden;

  @media (max-width: 600px) {
    width: 90px;
  }
`;

const Title = styled.h1`
  color: ${Colors.mainColor};
  font-size: 40px;
  margin-bottom: 20px;
  font-weight: 900;
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  padding: 40px;
`;

const Text = styled.div`
  text-align: center;
  color: ${Colors.black};
  font-size: 16px;
  max-width: 800px;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const Languages = ({ data }) => {
  return (
    <>
      <SectionThree>
        <Title>{data.title}</Title>
        <LanguageWrapper>
          {data.languages.map(({ image, lang }) => {
            return (
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PhotoWraper>
                  <img
                    src={image}
                    alt="logo"
                    style={{ width: "100%", height: "auto" }}
                  />
                </PhotoWraper>
              </div>
            );
          })}
        </LanguageWrapper>
      </SectionThree>
      <TextWrapper>
        <Text>{data.description}</Text>
      </TextWrapper>
    </>
  );
};

export default Languages;
