import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";
import new_sweden from "../images/newProductPage/languages/SE.png";
import new_norway from "../images/newProductPage/languages/NO.png";
import new_grece from "../images/newProductPage/languages/GR.png";

import screen1 from "../images/newProductPage/czech/1.png";
import screen2 from "../images/newProductPage/czech/2.png";
import screen3 from "../images/newProductPage/czech/3.png";
import screen4 from "../images/newProductPage/czech/4.png";
import screen5 from "../images/newProductPage/czech/5.png";
import screen6 from "../images/newProductPage/czech/6.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const czech = {
  navbar: {
    data: [
      { title: "Objednat kurz", path: "/course" },
      { title: "Přihlásit se", path: "/start" },
      { title: "eLingo", path: "/" },
      { title: "O nás", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      hero: {
        languages: [
          "v angličtině",
          "v španělštině",
          "v němčině",
          "v holandštině",
          "v italštině",
          "v ruštině",
          "v polštině",
          "v francouzštině",
          "v portugalštině",
          // "v řečtině",
          // "ve švédštině",
          // "v norštině",
        ],
        title: "Mluvte odvážněji,",
        prefix: "sebejistě",
        subtitle: `Získejte rychle jistotu díky lekcím zaměřeným na konverzaci v reálném životě.`,
        buttonText: "Objednat nyní!",
      },
      labelInfo: {
        number: "9",
        numberDescription: "jazyků",
        itemTwo: "14 000 hodnocení",
        itemThree: {
          top: "Více než 200 tisíc",
          bottom: "studentů",
        },
      },
      learning: {
        title: "Jak vypadá kurz?",
        config: [
          {
            category: "Učte se jazyk nejjednodušším způsobem",
            description:
              "Stovky lekcí s příklady jazyka používaného v reálných konverzacích. Praktické výrazy vyučované v kurzech navržených rodilými mluvčími. Cvičení efektivně učí čtení, poslechu a mluvení",
          },
          {
            category: "Praktické lekce",
            description:
              "Učení na všech úrovních se skládá z desítek lekcí. Pokud neznáte svou úroveň – začněte od základů. Naučte se pozdravy, dny v týdnu, jak objednat jídlo v restauraci. Každá lekce obsahuje příklady z každodenního života.",
          },
          {
            category: "Úkoly",
            description:
              "Ať už rádi řešíte křížovky každý den, nebo naopak takovým aktivitám raději vyhýbáte, nelze popřít, že tyto hlavolamy jsou vynikající pro rozvoj paměti a umožňují opakování naučené látky.",
          },
          {
            category: "Slova, rčení a výrazy",
            description:
              "Každá lekce je doprovázena pečlivě vybranými obrázky, které činí učební proces intuitivním a kontextuálním. Nejčastěji používaná témata pokrývají širokou škálu slovní zásoby z každodenního života a cestování, což vám umožní komunikovat bez jazykových bariér v těchto oblastech.",
          },
        ],
        photoData: [screen1, screen2, screen3, screen4, screen5, screen6],
      },
      languages: {
        languages: [
          { image: new_england, lang: "Angličtina" },
          { image: new_french, lang: "Francouzština" },
          { image: new_spanish, lang: "Španělština" },
          { image: new_niderland, lang: "Holandština" },
          { image: new_germany, lang: "Němčina" },
          { image: new_italy, lang: "Italština" },
          { image: new_russia, lang: "Ruština" },
          { image: new_portugal, lang: "Portugalština" },
          { image: new_polish, lang: "Polština" },
          // { image: new_grece, lang: "Řecký" },
          // { image: new_sweden, lang: "Švédština" },
          // { image: new_norway, lang: "Norština" },
        ],
        title: "Dostupné jazyky:",
        description: `Připravte se na svou příští cestu, přidejte si jazykové dovednosti do životopisu nebo se ponořte do nové kultury: objevte širokou kolekci lekcí eLingo a naučte se, co potřebujete ke zahájení konverzace, bez ohledu na vaši úroveň zkušeností.`,
      },
      success: {
        title: "Příběhy úspěchu",
        subtitle: `Podívejte se, jak ostatní mluví plynule s využitím eLingo`,
        config: [
          {
            title: "Osobní Rozvoj",
            name: "Jiřina",
            comment: `S eLingo jsem začala jako úplný začátečník a nyní mohu hrdě říci, že jsem pokročilý mluvčí. To, co mě na této platformě nejvíce zaujalo, je její přístup zaměřený na studenta - materiály jsou přizpůsobené mým potřebám a tempo učení si mohu určovat sama. Díky eLingo jsem získala důvěru v používání nového jazyka ve svém profesním i osobním životě.`,
            // photo: Ildiko,
          },
          {
            title: "Komunikace bez Hranic",
            name: "Bohumil",
            comment: `Díky eLingo jsem překonal jazykovou bariéru, která mi dříve bránila v komunikaci s klienty z různých zemí. Lekce zaměřené na obchodní terminologii a kulturu komunikace mi otevřely dveře k mezinárodním příležitostem. Velký důraz na praktické cvičení a reálné konverzace mi umožnil rychle se zlepšovat.`,
            // photo: Katalin,
          },
          {
            title: "Jazyk Otevírá Dveře",
            name: "Magdalena",
            comment: `eLingo mi ukázalo, že učení se jazyka může být zábavné a motivující. Interaktivní hry, videa a příběhy mi pomohly nejen zlepšit moji gramatiku a slovní zásobu, ale také porozumět kultuře a zvykům mluvčích. Díky eLingo jsem si našla nové přátele z celého světa a rozšířila své kulturní obzory.`,
            // photo: Istvan,
          },
          {
            title: "Učení na Cestách",
            name: "Dalibor",
            comment: `Jako člověk, který hodně cestuje, jsem ocenil možnost učit se s eLingo kdekoli a kdykoli. Aplikace je tak pohodlná a přizpůsobivá, že jsem mohl procvičovat lekce během letů, v čekárnách nebo během relaxace v parku. eLingo mi umožnilo zlepšit svůj jazyk ve velmi krátkém čase bez narušení mého hektického rozvrhu.`,
            // // photo: Zoltan,
          },
          {
            title: "Obohacení Života",
            name: "Eva",
            comment: `eLingo změnilo můj pohled na učení se jazykům. Dříve jsem se cítila frustrovaně z pomalého pokroku ve školních kurzech, ale s eLingo jsem začala vidět rychlé výsledky. Způsob, jakým platforma kombinuje audio, video a textové materiály, mi pomohl lépe porozumět jazyku a zároveň si užívat každou lekci. Nyní jazyk používám nejen v práci, ale také při cestování a poznávání nových kultur.`,
            // photo: Gabor,
          },
          {
            title: "Nové Příležitosti",
            name: "Radek",
            comment: `S eLingo jsem objevil nový svět možností. Program je tak flexibilní, že jsem mohl začlenit učení se do svého nabitího denního harmonogramu bez problémů. Lekce jsou strukturované tak, aby poskytovaly jasné a uchopitelné cíle, což mi dalo pocit úspěchu a motivace k dalšímu pokračování. Nejvíce mě zaujala interaktivní příroda kurzu - skrze hry a simulace reálných situací jsem se naučil nejen jazyk, ale také, jak ho používat v praxi. Díky eLingo jsem zlepšil své jazykové dovednosti natolik, že jsem byl schopen získat práci v mezinárodní firmě, o které jsem vždy snil.`,
            // photo: Maria,
          },
        ],
      },
    },
    course: {
      title: "Jak vypadá kurz?",
      right:
        "Na platformě eLingo nenajdete nudná, suchá gramatická pravidla, která se musíte naučit za den. Učíte se věty a výrazy používané v každodenním životě, pak je upevníte v paměti pomocí jednoduchých cvičení a opakování. Cílem kurzu je zábavné učení.",
      right2Title: "Skutečné lekce",
      right2Bottom:
        "Učení na každé úrovni se skládá z desítek lekcí. Pokud neznáte svou úroveň - začněte od základů. Naučte se pozdravy, dny v týdnu, vaše pocity. Naučte se, jak objednat jídlo v restauraci a jak si objednat taxi. Každá lekce zahrnuje příklady z každodenního života. Každá lekce je trochu jiná, skládá se z různých úkolů a cvičení, ale každá tvoří celek – takže můžete jazyk snadno ovládnout. Lekci můžete zahájit kdykoli.",
      flexDown: [
        {
          title: "Během učení se setkáte s prvky, jako jsou:",
          text: `
    Audio nahrávky vyučující správnou výslovnost,
  `,
          text1: "Výrazy a věty z reálného života,",
          text2: "Obrázky, které vám pomohou vizuálně spojit ",
          text3: "a pamatovat si slova a výrazy,",
          text4: "...a mnoho dalších.",
        },
        {
          title: "Úkoly",
          text: `Ať už rádi řešíte hlavolamy denně nebo, naopak, se takovým aktivitám raději vyhýbáte, nelze popřít, že tyto hlavolamy jsou vynikající pro rozvoj paměti a umožňují opakování naučeného materiálu.`,
        },
        {
          title: "Opakování",
          text: `Pokročilejší studenti mohou opakovat slovní zásobu v režimu nepřetržitého učení a testovat své znalosti v každé oblasti tématu. Zahrnuje veškerou dostupnou slovní zásobu ze všech kategorií. Učební algoritmus neustále kontroluje úroveň znalostí a přizpůsobuje následující lekce podle toho.`,
        },
      ],
    },
    learningWithVerbalia: {
      title: "Učení na jazykové platformě eLingo:",
      cards: [
        {
          icon: learning1,
          headline: `Vysoká kvalita`,
          text: `
      Během vývoje eLingo si expertní tým stanovil jeden z nejdůležitějších cílů: umožnit co nejrychlejší učení cizího jazyka naší metodou (bez ohledu na věk studenta!).
        `,
        },
        {
          icon: learning2,
          headline: `Lekce bez odchodu z domova`,
          text: `Abyste mohli využívat učební aplikaci eLingo, stačí mít telefon, počítač nebo tablet. S Eurekou se můžete učit kdekoliv: ve frontě, v autobuse a co je nejdůležitější, doma, aniž byste museli někam chodit. Učení při koupeli nebo při venčení psa? S Eurekou je to možné!
      `,
          text2:
            "Učení s eLingo ušetří spoustu času stráveného cestováním a snadno se integruje do vašeho rozvrhu!",
        },
        {
          icon: learning3,
          headline: `Krátké, ale systematické lekce`,
          text: `Nejdůležitějším aspektem učení jazyka je pravidelnost. Zároveň je pro nás všechny těžké najít potřebný čas. S tímto na mysli je Eureka navržena tak, aby učila v krátkých, ale systematických lekcích.`,
        },
      ],
    },
    whyVerbalia: {
      title: "Jak vypadá kurz?",
      textUp:
        "Jedná se o mnohostranný přístup k učení, který vám pomůže ovládnout nový jazyk stejně přirozeně jako vaši mateřštinu.",
      textBottom:
        "Učení cizího jazyka může změnit váš život. Nejenže otevírá okno do světa plného profesních a osobních příležitostí, ale také rozvíjí váš rozum a posiluje vaši sebedůvěru. Jak technologie jako internet snižují vzdálenosti a stáváme se členy globální komunity, schopnost učit se jazyky se stává nezbytnou součástí naší interakce se světem a našeho úspěchu.",
      cards: [
        {
          headline: `Učte se výrazy a fráze z každodenního života s naší pokročilou učební technologií.`,
          text: `Lekce se neustále přizpůsobují vaší úrovni, takže se vám nikdy nevyčerpá nuda nebo motivace.`,
        },
        {
          headline: `Ponořte se do cizího jazyka. Pokuste se plně pochopit nový jazyk na základě vašich kontextových znalostí.`,
          text: `S tisíci grafik a audio nahrávek uvidíte a uslyšíte jako rodilý mluvčí.`,
        },
        {
          headline: `Získejte jistotu v mluvení. Skutečným cílem učení jazyka je také porozumění.`,
          text: `S Eurekou stačí opakovat slovní zásobu, kterou slyšíte - nebojte se mluvit! Náš systém vás opraví.`,
        },
        {
          headline: `Nenechte slova uniknout z vaší paměti. Učte se prostřednictvím různých cvičení.`,
          text: `Prvky aplikace jsme pečlivě navrhli tak, aby váš mozek nikdy nenudil. Neustále připravujeme a rozšiřujeme Eureku o nové výzvy, aby bylo učení zábavné.`,
        },
      ],
    },
  },
  aboutUs: {
    hero: {
      title: "Poslání",
      description: `Věříme, že jazyk tvoří most mezi kulturami, a naším posláním je překonávat komunikační bariéry a spojovat lidi prostřednictvím vzdělávání v oblasti jazyků.`,
    },
    why: {
      title: "Proč my?",
      config: [
        {
          title: "Inovativní vzdělávací metody",
          image: dlaczego1,
          description:
            "Náš sofistikovaný a v praxi ověřený učební algoritmus přizpůsobuje materiály vaší aktuální úrovni znalostí a rozšiřuje je prostřednictvím efektivního vyučování.",
        },
        {
          title: "Široká škála jazyků",
          image: dlaczego2,
          description:
            "Nabízíme kurzy ve světově nejpopulárnějších jazycích. To vám umožní vybrat si jazyk, který vyhovuje vašim potřebám.",
        },
        {
          title: "Vzdělávání zábavnou formou",
          image: dlaczego3,
          description:
            "Naše platforma činí učení jazyků zábavným a příjemným zážitkem, takže budete motivováni k učení jazyků.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalizace a dostupnost",
          description:
            "Náš efektivní algoritmus strojového učení neustále pracuje na poskytování odpovídajícího materiálu pro každého jednotlivce. eLingo umožňuje studentům objevovat vzory sami, aniž by se museli soustředit na pravidla jazyka – stejně jako jste se naučili svůj mateřský jazyk v dětství.",
          image: zdjecie1,
        },
        {
          title: "Kombinace technologie a vědy",
          description:
            "eLingo využívá odvážnou kombinaci technologie a vědy k stimulaci mysli k akci. Opakování nesčetných seznamů slov a výrazů je nudné a váš mozek to ví. Naše metoda je vzrušující a efektivní, nutí vás používat získané znalosti různými způsoby ve specifických okamžicích - váš mozek ocení toto řešení!",
          image: zdjecie2,
        },
        {
          title: "Komunita a hodnoty",
          description:
            "Jazyky pomáhají lidem seznamovat se. Učení jazyků usnadňuje porozumění. Komunita eLingo spojuje lidi z celého světa. Jsme ambiciózní a neustále posouváme hranice. Věříme, že učení jazyků může být zábavné a jsme zvědaví na prozkoumávání nových kultur prostřednictvím jazyka.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Máte zájem o kurz? ",
      title1: "Chtěli byste více informací?",
      subtitle: "Zanechte své telefonní číslo - zavoláme vám zpět.",
      subtitle1: "Pokud již máte účet, vyplňte následující pole:",
      button1: "Jméno a příjmení",
      button2: "Telefonní číslo",
      button3: "Email",
      buttonSub: "Odeslat",
      checkOne:
        "Odesláním tohoto formuláře souhlasím s podmínkami a prohlašuji, že jsem si přečetl následující",
      checkOneTwo: "to",
      checkTwo:
        "Souhlasím s tím, aby se mnou bylo kontaktováno za účelem marketingu",
      textOne: "Upozorňujeme, že",
      textTwo:
        "Správcem vašich osobních údajů je společnost EOU Sp. z o.o., se sídlem ve Varšavě, Młynarska 42 / 115, 01-171, zapsaná v rejstříku podnikatelů vedeném okresním soudem ve Varšavě pod KRS5273057993, REGON: 525396900, NIP5273057993. Vaše osobní údaje budou zpracovávány v souvislosti s dotazem na kontaktní formulář nebo s provedením uzavřené smlouvy. Kontaktováním nás dobrovolně souhlasíte se zpracováním vašich osobních údajů pro výše uvedené účely. Máte právo na přístup, opravu, smazání nebo omezení zpracování vašich údajů, právo vznést námitku proti zpracování a právo na přenositelnost údajů. Máte také právo kdykoli odvolat svůj souhlas, avšak odvolání souhlasu neovlivní zákonnost zpracování na základě souhlasu před jeho odvoláním. Máte také právo podat stížnost dozorovému orgánu. Poskytnutí údajů je dobrovolné, ale může být nezbytné pro odpověď na dotaz nebo provedení smlouvy. Osobní údaje budou uchovávány po dobu nezbytnou pro výše uvedené účely, avšak ne déle než dobu promlčení nároků podle obecného práva. Přečtěte si prosím pečlivě zásady ochrany osobních údajů",
      buttonMore: "Číst více",
      buttonLess: "Číst méně",
      subOne: "podmínky",
      subTwo: "a pravidla ochrany soukromí.",
      buttonTXT: "Děkuji",
      rulesLink: "/Regulations_EN.pdf",
      privacyLink: "/Privacy_EN.pdf",
      product: "ec",
      market: "cz",
      send_to_url: "https://crm.europa-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_eLingo",
      product_variant: "cs-en",
      server_link: "https://cms.europa-assets.eu/integration/lead-from-landing",
      currency: "czk",
      price: 1960,
    },
  },
  footer: {
    privacy: "Ochrana soukromí",
    privacyLink: "/Privacy_EN.pdf",
    rules: "Podmínky služby",
    rulesLink: "/Regulations_EN.pdf",
  },
};
