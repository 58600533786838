import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";
import new_sweden from "../images/newProductPage/languages/SE.png";
import new_norway from "../images/newProductPage/languages/NO.png";
import new_grece from "../images/newProductPage/languages/GR.png";

import screen1 from "../images/newProductPage/lithuanian/1.png";
import screen2 from "../images/newProductPage/lithuanian/2.png";
import screen3 from "../images/newProductPage/lithuanian/3.png";
import screen4 from "../images/newProductPage/lithuanian/4.png";
import screen5 from "../images/newProductPage/lithuanian/5.png";
import screen6 from "../images/newProductPage/lithuanian/6.png";

import Ildiko from "../images/newProductPage/hungarian/people/Ildiko.png";
import Katalin from "../images/newProductPage/hungarian/people/Katalin.png";
import Istvan from "../images/newProductPage/hungarian/people/Istvan.png";
import Zoltan from "../images/newProductPage/hungarian/people/Zoltan.png";
import Gabor from "../images/newProductPage/hungarian/people/Gabor.png";
import Maria from "../images/newProductPage/hungarian/people/Maria.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const lithuanian = {
  navbar: {
    data: [
      { title: "Užsisakyti kursą", path: "/course" },
      { title: "Prisijungti", path: "/start" },
      { title: "eLingo", path: "/" },
      { title: "Apie mus", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      hero: {
        languages: [
          "angliškai",
          "vokiškai",
          "itališkai",
          "prancūziškai",
          "ispaniškai",
          "olandiškai",
          "rusiškai",
          "lenkiškai",
          "portugališkai",
          // "švediškai",
          // "norvegiškai",
          // "graikiškai",
        ],
        title: "Kalbėkite drąsiau ir užtikrinčiau ",
        prefix: "",
        subtitle: `Greitai įgaukite pasitikėjimo savimi pamokose, kuriose dėmesys skiriamas pokalbiams iš realaus gyvenimo.`,
        buttonText: "Užsisakyti kursą!",
      },
      labelInfo: {
        number: "9",
        numberDescription: "kalbos",
        itemTwo: "14 000 atsiliepimų",
        itemThree: {
          top: "Daugiau nei 200 ",
          bottom: "besimokančiųjų",
        },
      },
      learning: {
        title: "Kaip atrodo kursas?        ",
        config: [
          {
            category: "Išmokite kalbą lengvai            ",
            description: `Šimtai pamokų su realiame gyvenime vartojamos kalbos pavyzdžiais.
              Praktiškos frazės, kurių mokoma gimtakalbių parengtuose kursuose.
              Užduotys, veiksmingai mokančios skaitymo, klausymo ir kalbėjimo įgūdžių.`,
          },
          {
            category: "Pamokos iš realaus gyvenimo",
            description:
              "Kiekvieną mokymosi lygį sudaro dešimtys pamokų. Jei nežinote, kokio esate lygio, pradėkite nuo pagrindų. Mokykitės pasisveikinti, savaitės dienų, kaip užsisakyti maisto restorane. Į kiekvieną pamoką įeina kasdienio gyvenimo pavyzdžiai.",
          },
          {
            category: "Užduotys",
            description:
              "Nesvarbu, ar mėgstate kasdien spręsti kryžiažodžius, ar, priešingai, tokios veiklos vengiate, niekas nepaneigs, kad šie galvosūkiai puikiai lavina atmintį ir padeda pakartoti išmoktą medžiagą.              ",
          },
          {
            category: "Žodžiai, frazės ir posakiai",
            description:
              "Kiekvieną pamoką papildo kruopščiai atrinkti paveikslėliai, dėl kurių mokymosi procesas tampa intuityvus ir kontekstinis. Dažniausiai gvildenamos temos apima platų kasdienio gyvenimo ir kelionių žodyną, todėl šiais klausimais galėsite bendrauti be vadinamojo kalbos bloko.",
          },
        ],
        photoData: [screen1, screen2, screen3, screen4, screen5, screen6],
      },
      languages: {
        languages: [
          { image: new_england, lang: "Anglų" },
          { image: new_french, lang: "Prancūzų" },
          { image: new_spanish, lang: "Ispanų" },
          { image: new_niderland, lang: "Olandų" },
          { image: new_germany, lang: "Vokiečių" },
          { image: new_italy, lang: "Italų" },
          { image: new_russia, lang: "Russian" },
          { image: new_portugal, lang: "Rusų" },
          { image: new_polish, lang: "Lenkų" },
          // { image: new_sweden, lang: "Švedų" },
          // { image: new_norway, lang: "Norvegų" },
          // { image: new_grece, lang: "Graikų" },
        ],
        title: "Galimos kalbos:",
        description: `Pasiruoškite kitai kelionei, papildykite savo CV kalbos įgūdžiais arba įsigilinkite į naują kultūrą: pasižvalgykite po gausią „eLingo“ pamokų kolekciją ir sužinokite, ko reikia, kad pradėtumėte kalbėti, nepriklausomai nuo jūsų patirties lygio.`,
      },
      success: {
        title: "Sėkmės istorijos",
        subtitle: `Sužinokite, kaip kiti įvaldo kalbas su „eLingo“`,
        config: [
          {
            title: "",
            name: "Andrij",
            comment: `Nesvarbu, esate pradedantysis, tik bepradedantis mokytis pagrindų, ar pažengęs besimokantysis, siekiantis praturtinti savo žodyną, „eLingo“ turi ką pasiūlyti kiekvienam. Vienas iš dalykų, dėl kurių man patinka ši platforma, yra pamokų įvairovė – nuo maisto užsakymo restorane iki taksi iškvietimo – todėl mokymosi patirtis atrodo tikroviška ir praktiška. Be to, didžiulis privalumas yra įtrauktos frazės iš realaus gyvenimo ir įrašai, skirti taisyklingai tarčiai.`,
            photo: Ildiko,
          },
          {
            title: "",
            name: "Diana",
            comment: `Man patinka, kad „eLingo“ pamokos yra pritaikytos prie gyvenimiškų situacijų, todėl tai, ką išmokau, galiu lengvai panaudoti tikrame pasaulyje. Po 3 mėnesių mokymosi man pasiūlė naują darbą!`,
            photo: Katalin,
          },
          {
            title: " ",
            name: "Kajus",
            comment: `„eLingo“ yra puiki platforma kalbų mokymuisi. Dėl pamokų ir užduočių įvairovės mokymosi procesas išlieka įdomus ir įtraukiantis. Mokydamasis kalbos internetu susipažinau su mergina iš Vokietijos, su kuria netrukus susituoksime. Rekomenduoju. 😊`,
            photo: Istvan,
          },
          {
            title: "",
            name: "Taras",
            comment: `Mano manymu, „eLingo“ yra nuostabi platforma visiems, norintiems pagerinti savo kalbos įgūdžius. Platforma sukurta taip, kad naujos kalbos mokymasis taptų malonia patirtimi.`,
            photo: Zoltan,
          },
          {
            title: " ",
            name: "Lukas",
            comment: `Naudoju „eLingo“ jau kelis mėnesius ir galiu drąsiai teigti, kad tai pakeitė mano gyvenimą. Mane paaukštino darbe! Visada norėjau išmokti naują kalbą, bet niekada tam neturėjau laiko ar išteklių. Su „eLingo“ galiu mokytis savo tempu ir pagal savo tvarkaraštį.`,
            photo: Gabor,
          },
          {
            title: "",
            name: "Vilma",
            comment: `„eLingo“ naudoju jau kelis mėnesius ir turiu pasakyti, kad tai viską pakeitė iš esmės. Kaip tai, kuri visada norėjo išmokti naują kalbą, bet niekada neturėjo tam galimybės, „eLingo“ padėjo mokytis taip paprastai ir patogiai. Dabar galiu suprasti ir bendrauti su skirtingų kultūrų žmonėmis, o tai praplėtė mano akiratį ir atvėrė naujas galimybes.`,
            photo: Maria,
          },
        ],
      },
    },
    course: {
      title: "What Does a Course Look Like?",
      right:
        "On the eLingo platform, you won't find boring, dry grammatical rules that you have to learn in a day. You learn sentences and expressions used in everyday life, then consolidate them in memory with simple exercises and repetitions. The goal of the course is to make learning fun.",
      right2Title: "Real Lessons",
      right2Bottom:
        "Learning at every level consists of dozens of lessons. If you don't know your level - start with the basics. Learn greetings, days of the week, your feelings. Learn how to order food in a restaurant, and how to order a taxi. Every lesson includes examples from everyday life. Each lesson is a little different, consisting of various tasks and exercises, but each forms a whole – so you can easily master the language. You can start the lesson anytime.",
      flexDown: [
        {
          title: "During learning, you will encounter elements such as:",
          text: `
      Audio recordings teaching correct pronunciation,
    `,
          text1: "Expressions and sentences from real life,",
          text2: "Images that help you visually associate ",
          text3: "and remember words and expressions,",
          text4: "...and many others.",
        },
        {
          title: "Tasks",
          text: `Regardless of whether you like solving puzzles daily or, on the contrary, prefer to avoid such activities, it is undeniable that these puzzles are excellent for developing memory and allow for the repetition of learned material.`,
        },
        {
          title: "Repetitions",
          text: `More advanced learners can repeat vocabulary in continuous learning mode and test their knowledge in every subject area. It includes all available vocabulary, from every category. A learning algorithm constantly checks the level of knowledge and adjusts the following lessons accordingly.`,
        },
      ],
    },
    learningWithVerbalia: {
      title: "Learning on the eLingo Language Platform:",
      cards: [
        {
          icon: learning1,
          headline: `High Quality`,
          text: `
      During the development of eLingo, an expert team set one of the most important goals: to make learning a foreign language as fast as possible with our method (regardless of the learner's age!).
        `,
        },
        {
          icon: learning2,
          headline: `Lessons Without Leaving Home`,
          text: `To use the eLingo learning app, all you need is your phone, computer, or tablet. With Eureka, you can learn anywhere: in line, on the bus, and most importantly, at home without having to go anywhere. Learning while bathing or walking the dog? With Eureka, it's possible!
      `,
          text2:
            "Learning with eLingo saves a lot of commuting time and you can easily integrate learning into your schedule!",
        },
        {
          icon: learning3,
          headline: `Short, but Systematic Lessons`,
          text: `The most important thing in language learning is regularity. At the same time, it's hard for all of us to find the necessary time. With this in mind, Eureka is designed to teach in short but systematic lessons.`,
        },
      ],
    },
    whyVerbalia: {
      title: "Kaip atrodo kursas?",
      textUp:
        "This is a multifaceted learning approach platform that helps you master a new language as naturally as your mother tongue.",
      textBottom:
        "Learning a foreign language can change your life. It not only opens a window to a world full of professional and personal opportunities but also develops your mind and boosts your confidence. As technologies like the internet reduce distances and we become members of a global community, the ability to learn languages becomes an essential part of our interaction with the world and our success.",
      cards: [
        {
          headline: `Learn expressions and phrases from everyday life with our advanced learning technology.`,
          text: `The lessons are continuously adjusted to your level, so you never run out of boredom or motivation.`,
        },
        {
          headline: `Immerse yourself in a foreign language. Try to fully understand a new language based on your contextual knowledge.`,
          text: `With thousands of graphics and audio recordings, you will see and hear like a native speaker.`,
        },
        {
          headline: `Gain confidence in speaking. The real goal of language learning is also to understand.`,
          text: `With Eureka, all you have to do is repeat the vocabulary you hear - don't be afraid to speak! Our system will correct you.`,
        },
        {
          headline: `Don't let words slip out of your memory. Learn through various exercises.`,
          text: `We have carefully designed the app's elements so that your mind never gets bored. We are constantly preparing and expanding Eureka with new challenges to make learning fun.`,
        },
      ],
    },
  },
  aboutUs: {
    hero: {
      title: "Misija",
      description: `Mūsų pagrindinis tikslas „eLingo“ yra kiekvienam žmogui suteikti galimybę turėti asmeninį mokytoją naudojantis technologijomis. Tikime, kad kalba – tiltas tarp kultūrų, ir mūsų misija yra įveikti bendravimo kliūtis bei suburti žmones pasitelkus kalbų mokymąsi.`,
    },
    why: {
      title: "Kodėl mes?",
      config: [
        {
          title: "Pažangūs mokymo metodai",
          image: dlaczego1,
          description:
            "Mūsų modernus ir praktika patikrintas mokymosi algoritmas mokymo medžiagą pritaiko prie jūsų esamo žinių lygio ir jį didina teikiant efektyvų mokymą.",
        },
        {
          title: "Didelis kalbų pasirinkimas",
          image: dlaczego2,
          description:
            "Siūlome pasaulyje daugiausiai vartojamų kalbų kursus, tad galite pasirinkti kalbą, atitinkančią jūsų poreikius.",
        },
        {
          title: "Mokymasis smagiai",
          image: dlaczego3,
          description:
            "Mūsų platforma kalbos mokymąsi paverčia smagia ir malonia patirtimi, tad būsite motyvuoti jį tęsti.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Pritaikymas prie individualių poreikių ir prieiga",
          description:
            "Mūsų galingas mašininio mokymosi algoritmas nuolat pluša, kad pateiktų kiekvienam asmeniui tinkamo lygio mokymosi medžiagą. „eLingo“ besimokantiesiems leidžia patiems rasti kalbos modelius, dėmesio nesutelkiant į  taisykles – tuo pačiu būdu, kaip vaikystėje išmokote savo gimtosios kalbos.",
          image: zdjecie1,
        },
        {
          title: "Drąsus technologijų ir mokslo derinys",
          description:
            "„eLingo“ drąsiai derinamos technologijos ir mokslas, taip siekiant sužadinti jūsų protą veiklai. Kartoti nesuskaičiuojamą daugybę žodžių ir frazių sąrašų yra nuobodu ir jūsų smegenys tai žino. Mūsų metodas, kuriuo esate verčiami įvairiais būdais įgytas žinias panaudoti konkrečiu metu, yra įdomus ir veiksmingas – jūsų smegenys tai įvertins!",
          image: zdjecie2,
        },
        {
          title: "Bendruomenė ir vertybės",
          description:
            "Kalbos padeda žmonėms užmegzti ryšius. Kalbų mokymasis skatina supratimą. „eLingo“ bendruomenė suburia žmones iš viso pasaulio. Esame ambicingi ir visad plečiame ribas. Manome, kad mokytis kalbų turėtų būti smagu, ir mus domina pažinti naujas kultūras pasitelkus kalbas.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Susidomėjote kursu?",
      title1: "Norėtumėte daugiau informacijos?",
      subtitle: "Palikite savo telefono numerį ir mes jums paskambinsime.",
      subtitle1:
        "If you already have an account, please fill in the following fields:",
      button1: "Vardas ir pavardė",
      button2: "Telefono numeris",
      button3: "El. paštas",
      buttonSub: "Pateikti užklausą skambučiui",
      checkOne:
        "Pateikdamas šią formą sutinku su sąlygomis ir pareiškiu, kad susipažinau su sąlygomis ir duomenų apsaugos taisyklėmis.",
      checkOneTwo: "",
      checkTwo: "Sutinku, kad su manimi susisiektų rinkodaros reikmėms",
      textOne: "Pranešame, kad jūsų asmens duomenų valdytojas yra",
      textTwo:
        "EOU Sp. z o.o., kurio būstinė yra Varšuvoje, ul. Młynarska 42 / 115, 01-171, įregistruotas Varšuvos apygardos teismo tvarkomame verslininkų registre numeriais KRS 0001038089, REGON 525396900, NIP 5273057993. Jūsų asmens duomenys bus tvarkomi dėl kontaktinės formos užklausos arba sudarytos sutarties vykdymo. Susisiekdami su mumis savanoriškai sutinkate, kad jūsų asmens duomenys būtų tvarkomi minėtais tikslais. Turite teisę su savo duomenimis susipažinti, juos ištaisyti, ištrinti ar apriboti jų tvarkymą, nesutikti, kad jie būtų tvarkomi, ir teisę į duomenų perkeliamumą. Taip pat turite teisę bet kada atšaukti savo sutikimą, tačiau sutikimo atšaukimas neturi įtakos duomenų tvarkymo, grindžiamo sutikimu iki atšaukiant, teisėtumui. Be to, turite teisę pateikti skundą priežiūros institucijai. Duomenų pateikimas yra savanoriškas, tačiau gali būti privalomas norint atsakyti į užklausą arba įvykdyti sutartį. Asmens duomenys bus saugomi tol, kol tai bus būtina siekiant pirmiau nurodytų tikslų, bet ne ilgiau nei pagal bendrąją teisę nustatytą reikalavimų senaties terminą. Prašome atidžiai perskaityti privatumo politiką.",
      buttonMore: "Skaityti daugiau",
      buttonLess: "Skaityti mažiau",
      subOne: "Paslaugų teikimo sąlygos",
      subTwo: "ir privatumo taisykles.",
      buttonTXT: "Thank you",
      rulesLink: "/Regulations_EN.pdf",
      privacyLink: "/Privacy_EN.pdf",
      product: "el",
      market: "lt",
      send_to_url: "https://crm.europa-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_eLingo",
      product_variant: "lt-en",
      server_link: "https://cms.europa-assets.eu/integration/lead-from-landing",
      currency: "eur",
      price: 87,
    },
  },
  footer: {
    privacy: "Privatumas",
    privacyLink: "/Privacy_EN.pdf",
    rules: "Paslaugų teikimo sąlygos",
    rulesLink: "/Regulations_EN.pdf",
  },
};
