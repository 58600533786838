import moment from "moment";
import { useEffect, useState } from "react";
import api from "../../api/api";
import { Chart as ChartJS } from "chart.js/auto";

import { Bar, Line } from "react-chartjs-2";
import "./YourActivityBox.css";
import Store from "../../../Store";

const queryString = require("query-string");

function YourActivityBox({ pairId, ...props }) {
  const [progressData, setProgressData] = useState({});
  const [chartData, setChartData] = useState();

  async function loadYourActivityData(pair) {
    const qs = queryString.stringify({
      languagePairId: pair,
      from: moment().subtract(6, "day").startOf("day").toISOString(),
    });
    const res = await api.get(`/user-lesson-activity?${qs}`);
    const words = res.data.filter((activity) => activity.type === "word");
    const dayCompletedObj = {};

    const weekAgo = moment().startOf("day").subtract(6, "day");
    const now = moment();
    while (weekAgo.isBefore(now)) {
      const dayString = weekAgo.format("YYYY-MM-DD");
      dayCompletedObj[dayString] = 0;
      weekAgo.add(1, "day");
    }

    words.forEach((completed) => {
      const dayString = moment(completed.timestamps.createdAt).format(
        "YYYY-MM-DD"
      );
      dayCompletedObj[dayString]++;
    });

    const dayArr = Object.keys(dayCompletedObj).sort().reverse();
    const todayWords = dayCompletedObj[dayArr[0]];
    let daysInRow =todayWords > 0 ?  1 : 0;

    for (let i = 1; dayCompletedObj[dayArr[i]]; i++) {
      daysInRow++;
    }
    const dataObj = {
      daysInRow,
      todayWords,
      dayCompletedObj,
    };

    setProgressData(dataObj);
    computeChartsData(dayCompletedObj);
  }

  const computeChartsData = (dataObj) => {
    const dayNames = [
      Store.getText("progressModalMondayShort"),
      Store.getText("progressModalTuesdayShort"),
      Store.getText("progressModalWednesdayShort"),
      Store.getText("progressModalThursdayShort"),
      Store.getText("progressModalFridayShort"),
      Store.getText("progressModalSaturdayShort"),
      Store.getText("progressModalSundayShort"),
    ];
    const labels = [];
    const data = [];
    Object.keys(dataObj).forEach((day) => {
      labels.push(dayNames[moment(day).isoWeekday() - 1]);
      data.push(dataObj[day]);
    });

    setChartData({
      labels,
      datasets: [
        {
          label: Store.getText("progressModalChartTitle"),
          backgroundColor: ["#97AD45"],
          borderColor: "#97AD45",
          fill: true,
          data,
          borderWidth: 2,
          borderRadius: 9999,
          barThickness: 15,
        },
      ],
    });
  };

  useEffect(() => {
    if (pairId) {
      loadYourActivityData(pairId);
    }
  }, [pairId]);

  return (
    <div {...props} className={"your-activity__content-div " + props.className}>
      <h3
        className="your-activity__content-title"
      >
        {Store.getText("yourActivityBoxHeader")}
      </h3>
      <div className="your-activity__inner-content-wrapper">
        <div className="your-activity__chart">
          {chartData && (
            <Bar
              data={chartData}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      display: false,
                      stepSize: 1,
                    },
                    grid: {
                      display: false,
                      drawBorder: false,
                    },
                  },
                  x: {
                    grid: {
                      display: false,
                      drawBorder: false,
                    },
                  },
                },
              }}
            ></Bar>
          )}
        </div>
        <div className="your-activity__activity-data-wrapper">
          <div className="your-activity__activity-data-div">
            <p className="your-activity__activity-data">
              {progressData.daysInRow}
            </p>
            <p className="your-activity__activity-data-desc">
              {Store.getText("yourActivityBoxDaysOfLearning")}
            </p>
          </div>
          <div className="your-activity__activity-data-div">
            <p className="your-activity__activity-data">
              {progressData.todayWords}
            </p>
            <p className="your-activity__activity-data-desc">
              {Store.getText("yourActivityBoxLearnedWords")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YourActivityBox;
