import React, { useEffect, useRef, useState } from 'react';
import UserProfile from './UserProfile';
import api from '../../../api/api';
import moment from 'moment';
import Store from '../../../../Store';
import { getExpertName } from '../helpers/getExpertName';
import { ArrowIcon } from '../icons/Arrow';

const MailBoxElement = ({ conversation, setConversation }) => {
    const [showMoreMessage, setShowMoreMessage] = useState();
    const [showSendBox, setShowSendBox] = useState();
    const [messages, setMessages] = useState([]);
    const messageRef = useRef();
    const messageRef2 = useRef();

    const fetchMessages = async () => {
        api.get(`/messages/conversation/${conversation.id}`).then(
            (response) => {
                setMessages(response.data);
            }
        );
    };

    const handleSendMessage = (e) => {
        e && e.preventDefault();

        const message = messageRef.current.value || messageRef2.current.value;
        api.post(`/messages/conversation/${conversation.id}`, {
            content: message,
        }).then(() => {
            messageRef.current.value = '';
            fetchMessages();
        });
    };

    useEffect(() => {
        fetchMessages();
    }, [conversation]);

    const getName = (message) => {
        return Store.getUsername() === message.user.username
            ? message.user.username
            : getExpertName();
    };

    const formatText = (text) =>
        text.split('\n').map(function (item, idx) {
            return (
                <span key={idx}>
                    {handleBold(item)}
                    <br />
                </span>
            );
        });

    const handleBold = (text) => {
        const arr = text.split('**');

        const newTextArr = [];

        arr.forEach((element, index) => {
            if (index % 2 !== 0) {
                const newElement = <b>{element}</b>;
                newTextArr.push(newElement);
            } else {
                const newElement = <>{element}</>;
                newTextArr.push(newElement);
            }
        });

        return newTextArr;
    };

    return (
        <div className="malibox-user-selected__element__wrapper">
            <div className="mailbox-user-selected__element__top">
                <div
                    className="mailbox-button__back"
                    onClick={() => setConversation(null)}
                >
                    <ArrowIcon scale={0.7} />
                </div>
                <p className="mailbox-user-top__text_text">
                    {conversation.name}
                </p>
                <div className="mailbox-user-helper"></div>
            </div>
            <div
                className="mailbox-user-wrapper__messages"
                style={{
                    height: `${
                        showSendBox
                            ? window.innerWidth > 600
                                ? '63.5vh'
                                : '50vh'
                            : '70vh'
                    }`,
                }}
            >
                {messages.map((element, i) => (
                    <div
                        className="mailbox-user-wrapper-element_message"
                        onClick={() =>
                            setShowMoreMessage((prev) => (prev ? null : i))
                        }
                        style={{
                            height: 'fit-content',
                            padding: '10px 20px',
                        }}
                    >
                        <UserProfile />
                        <div className="mailbox-user-wrapper__name__message">
                            <p className="mailbox-user-name">
                                {getName(element)}
                            </p>
                            <p
                                className="mailbox-user-name-message"
                                style={
                                    {
                                        // whiteSpace: `${
                                        //     showMoreMessage == i ? '' : 'nowrap'
                                        // }`,
                                        // overflow: `${
                                        //     showMoreMessage == i ? 'auto' : 'hidden'
                                        // }`,
                                        // textOverflow: `${
                                        //     showMoreMessage == i ? '' : 'ellipsis'
                                        // }`,
                                    }
                                }
                            >
                                {formatText(element.content)}
                            </p>
                        </div>
                        <div style={{ paddingRight: '5px' }}>
                            {moment(element.timestamps.updatedAt).format(
                                'DD-MM-YYYY'
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {!showSendBox && (
                <div className="mailbox-user-answer-button__wrapper">
                    <button
                        className="mailbox-user-answer-button"
                        onClick={() => setShowSendBox(true)}
                    >
                        {Store.getText('inboxResponse')}
                    </button>
                </div>
            )}
            {showSendBox && (
                <form
                    className="mailbox-user-answer-button__wrapper__answer-mobile"
                    onSubmit={handleSendMessage}
                >
                    <textarea
                        ref={messageRef}
                        rows={9}
                        placeholder={Store.getText('inboxTypeMessage')}
                        required
                    ></textarea>
                    <button
                        className="mailbox-user-answer-button"
                        type="submit"
                    >
                        {Store.getText('inboxSend')}
                    </button>
                </form>
            )}
            {showSendBox && (
                <form
                    className="mailbox-user-answer-button__wrapper__answer-desktop"
                    onSubmit={handleSendMessage}
                >
                    <textarea
                        ref={messageRef2}
                        rows={3}
                        placeholder={Store.getText('inboxTypeMessage')}
                        required
                    ></textarea>
                    <button
                        className="mailbox-user-answer-button"
                        type="submit"
                        style={{ marginRight: '30px' }}
                    >
                        {Store.getText('inboxSend')}
                    </button>
                </form>
            )}
        </div>
    );
};

export default MailBoxElement;
