import ProgressBar from "@ramonak/react-progress-bar";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import api from "../../../api/api";
import "./MainPageComponent.css";
import logo from "../../../..//common/assets/logo-white.png";
import Store from "../../../../Store";
import flag from "../dymek_angielski.png";
import getLanguageFlag from "../../../common/functions/getLanguageFlag";
import levelOne from "../dymek-lvl1.png";
import levelTwo from "../dymek-lvl2.png";
import levelThree from "../dymek-lvl3.png";
import ColouredSpeechBubble from "../../../components/SpeechBubbles/ColouredSpeechBubble/ColouredSpeechBubble";
import YourActivityBox from "../../../components/YourActivityBox/YourActivityBox";
import MainModal from "../../../components/Modals/MainModal/MainModal";
import getLanguageCapitalWeatherCodes from "../../../common/functions/getLanguageCapitalWeatherCodes";
import getLevelColor from "../../../common/functions/getLevelColor";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal/ConfirmationModal";
import getLanguageFunFact from "../../../common/functions/getLanguageFunFact";
import Sidebar from "../../../components/Sidebar/Sidebar";
import getLanguageMap from "../../../common/functions/getLanguageMap";
import SecondaryButton from "../../../components/Buttons/SecondaryButton/SecondaryButton";
import CoursesModal from "../../../components/Modals/CoursesModal/CoursesModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import StatsContext from "../../../stats/context/statsContext";

function MainPageComponent(props) {
  const [currentLanguagePair, setCurrentLanguagePair] = useState();
  const [currentLevel, setCurrentLevel] = useState();

  const [currentLanguageShort, setCurrentLanguageShort] = useState();

  const [languagesAvailable, setLanguagesAvailable] = useState([]);
  const [notAvailableLanguages, setNotAvailableLanguages] = useState([]);
  const [accessKey, setAccessKey] = useState("");

  const { setLanguagePairId } = useContext(StatsContext);
  const [currentProgress, setCurrentProgress] = useState(0);
  const slider = useRef(null);

  const pageRef = useRef();

  const params = useParams();

  const navigate = useNavigate();

  async function addKey(ev) {
    try {
      ev.preventDefault();
      const res = await api.post(`/user/current/add-key`, { accessKey });
      navigate("/platform");
    } catch (err) {
      props.setError(err.message);
    }
  }

  function selectLanguage(id, level) {
    const language = languagesAvailable.find((lang) => lang.id === id);
    setCurrentLanguageShort(language.languageTo.languageShort);
    setCurrentLanguagePair(language);
    setLanguagePairId(language.id);
    setCurrentLevel(level);
  }

  function handleLogOut() {
    Store.removeToken();
    navigate("/"); // whichever component you want it to route to
  }

  async function loadData() {
    const lastLesson = await api.get("/lesson/last-lesson");
    const langAvailableRes = await api.get(
      `/language-pair/language-from/${params.languageShort}/available`
    );
    const langAllRes = await api.get(
      `/language-pair/language-from/${params.languageShort}`
    );

    setLanguagesAvailable(langAvailableRes.data);
    const availableIds = langAvailableRes.data.map((lang) => lang.id);
    setNotAvailableLanguages(
      langAllRes.data.filter((lang) => availableIds.indexOf(lang.id) === -1)
    );

    if (
      lastLesson.data &&
      lastLesson.data.languagePair.languageFrom.languageShort.includes(
        params.languageShort
      )
    ) {
      setCurrentLanguageShort(
        lastLesson.data.languagePair.languageTo.languageShort
      );
      setCurrentLanguagePair(lastLesson.data.languagePair);
      setCurrentLevel(lastLesson.data.level);
    } else {
      setCurrentLanguageShort(
        langAvailableRes.data[0].languageTo.languageShort
      );
      setCurrentLanguagePair(langAvailableRes.data[0]);
      setCurrentLevel(1);
    }
  }

  async function loadProgress(pairId, level) {
    const resLessons = await api.get(
      `/lesson/language-pair/${pairId}/level/${level}`
    );
    let sumProgress = 0;
    resLessons.data.forEach((lesson) => {
      sumProgress += lesson.progress;
    });
    setCurrentProgress((sumProgress * 100) / resLessons.data.length);
  }

  function getBackgroundImageClass(languageShort) {
    let short = languageShort;
    return `platform-main-page__background--${short}`;
  }

  useEffect(() => {
    Store.setUserPlatformLanguage(params.languageShort);
    loadData();
  }, [params.languageShort]);

  useEffect(() => {
    if (currentLevel && currentLanguagePair) {
      loadProgress(currentLanguagePair.id, currentLevel);
    }
  }, [currentLevel, currentLanguagePair]);

  return (
    currentLanguageShort &&
    currentLanguagePair && (
      <div className="platform-main-page" ref={pageRef}>
        <Sidebar
          isSidebarExpandable
          darkBg
          setError={props.setError}
          pairId={currentLanguagePair.id}
        ></Sidebar>
        <div
          className={`platform-main-page__background ${getBackgroundImageClass(
            currentLanguageShort
          )}`}
        >
          <div className="platform-main-page__nav-wrapper">
            <img src={logo} className="platform-main-page__logo" />
          </div>
          <div className="platform-main-page__main-wrapper">
            <div className="platform-main-page__grid-element platform-main-page__grid-element--learn">
              <div>
                <p className="platform-main-page__welcome-title">
                  {Store.getText("mainPageWelcomeBoxHeaderOne")}{" "}
                  {Store.getUsername()}!
                </p>
                <p className="platform-main-page__congratulations">
                  {" "}
                  {Store.getText(
                    "mainPageWelcomeBoxHeaderTwo" +
                      ((new Date().getDate() % 3) + 1)
                  )}
                </p>
              </div>
              <Link
                className="platform-main-page__learn-button"
                style={{ backgroundColor: getLevelColor(currentLevel) }}
                to={`/platform/activity/${currentLanguagePair.id}/${currentLevel}`}
              >
                {Store.getText("mainPageContinueLearningButtonText")}
              </Link>
            </div>
            <div className="platform-main-page__grid-element platform-main-page__grid-element--progress">
              <ColouredSpeechBubble className="platform-main-page__grid-bubble platform-main-page__grid-bubble--progress">
                <p className="platform-main-page__progress-title">
                  {Store.getText("yourProgress")}
                </p>
                <div>
                  <ProgressBar
                    completed={currentProgress}
                    height="30px"
                    bgColor="#95C34B"
                    width="300px"
                    customLabel=" "
                    className="progress-bar"
                  />
                  <p className="platform-main-page__progress-description">
                    <b>{currentProgress.toFixed(0)}%</b>{" "}
                    {Store.getText("progressDescription")}{" "}
                    <b>
                      {Store.getText("level")} {currentLevel}
                    </b>
                  </p>
                  <p className="platform-main-page__progress-slogan">
                    {Store.getText("progressMotivationText")}
                  </p>
                </div>
              </ColouredSpeechBubble>
            </div>
            <div className="platform-main-page__grid-element platform-main-page__grid-element--your-courses">
              <ColouredSpeechBubble className="platform-main-page__grid-bubble">
                <p className="platform-main-page__courses-title">
                  {Store.getText("languageChangeYourCourses")}
                </p>
                <div className="platform-main-page__courses-slider-wrapper">
                  <button
                    onClick={() => slider?.current?.slickPrev()}
                    className="platform-main-page__courses-slider-arrow platform-main-page__courses-slider-arrow-left"
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </button>

                  <Slider
                    infinite
                    speed={500}
                    slidesToShow={3}
                    slidesToScroll={1}
                    ref={slider}
                    arrows={false}
                  >
                    {languagesAvailable.map((language) => (
                      <div onClick={() => selectLanguage(language.id, 1)}>
                        <div className="platform-main-page__courses-slider-element platform-main-page__courses-slider-element--clickable">
                          <img
                            src={getLanguageFlag(
                              language.languageTo.languageShort
                            )}
                            className={`platform-main-page__course-flag-img ${
                              language.id === currentLanguagePair.id
                                ? "platform-main-page__course-flag-img--active"
                                : ""
                            }`}
                          />
                          <p className="platform-main-page__course-flag-description">
                            {Store.getLanguageNameByShort(
                              language.languageTo.languageShort
                            )}
                          </p>
                        </div>
                      </div>
                    ))}
                    {notAvailableLanguages.map((language) => (
                      <div>
                        <div className="platform-main-page__courses-slider-element">
                          <img
                            src={getLanguageFlag(
                              language.languageTo.languageShort
                            )}
                            className={`platform-main-page__course-flag-img platform-main-page__course-flag-img--not-owned`}
                          />
                          <p className="platform-main-page__course-flag-description">
                            {Store.getLanguageNameByShort(
                              language.languageTo.languageShort
                            )}
                          </p>
                        </div>
                      </div>
                    ))}
                  </Slider>
                  <button
                    onClick={() => slider?.current?.slickNext()}
                    className="platform-main-page__courses-slider-arrow platform-main-page__courses-slider-arrow-right"
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </button>
                </div>
              </ColouredSpeechBubble>
            </div>
            <div className="platform-main-page__grid-element platform-main-page__grid-element--chnage-level">
              <p className="platform-main-page__change-level-title">
                {Store.getText("chooseLanguageLevel")}
              </p>
              <div className="platform-main-page__levels-wrapper">
                <div className="platform-main-page__level-buttons">
                  <div
                    className="platform-main-page__level-button-wrapper"
                    onClick={() => setCurrentLevel(1)}
                  >
                    <img
                      src={levelOne}
                      className={`platform-main-page__level-button-img ${
                        currentLevel === 1
                          ? "platform-main-page__level-button-img--active"
                          : ""
                      }`}
                    />
                    <button
                      className={`platform-main-page__level-button ${
                        currentLevel === 1
                          ? "platform-main-page__level-button--active"
                          : ""
                      }`}
                    >
                      {Store.getText("level")} 1
                    </button>
                  </div>
                  <div
                    className="platform-main-page__level-button-wrapper"
                    onClick={() => setCurrentLevel(2)}
                  >
                    <img
                      src={levelTwo}
                      className={`platform-main-page__level-button-img ${
                        currentLevel === 2
                          ? "platform-main-page__level-button-img--active"
                          : ""
                      }`}
                    />
                    <button
                      className={`platform-main-page__level-button ${
                        currentLevel === 2
                          ? "platform-main-page__level-button--active"
                          : ""
                      }`}
                    >
                      {Store.getText("level")} 2
                    </button>
                  </div>
                  <div
                    className="platform-main-page__level-button-wrapper"
                    onClick={() => setCurrentLevel(3)}
                  >
                    <img
                      src={levelThree}
                      className={`platform-main-page__level-button-img ${
                        currentLevel === 3
                          ? "platform-main-page__level-button-img--active"
                          : ""
                      }`}
                    />
                    <button
                      className={`platform-main-page__level-button ${
                        currentLevel === 3
                          ? "platform-main-page__level-button--active"
                          : ""
                      }`}
                    >
                      {Store.getText("level")} 3
                    </button>
                  </div>
                </div>
                <Link
                  className="platform-main-page__all-lessons-button"
                  to={`/platform/lessons/${currentLanguagePair.id}/${currentLevel}`}
                >
                  {Store.getText("mainPageAllLessonsgButtontext")}
                </Link>
              </div>
            </div>
            <div className="platform-main-page__grid-element platform-main-page__grid-element--activity">
              <YourActivityBox
                level={currentLevel}
                pairId={currentLanguagePair.id}
              />
            </div>
            <div className="platform-main-page__grid-element platform-main-page__grid-element--activate">
              <ColouredSpeechBubble className="platform-main-page__grid-bubble platform-main-page__grid-bubble--activate">
                <p className="platform-main-page__activate-title">
                  {Store.getText("activateAnotherCourse")}
                </p>
                <form
                  className="platform-main-page__activate-form"
                  onSubmit={addKey}
                >
                  <div className="platform-main-page__activate-input-wrapper">
                    <label className="platform-main-page__activate-input-label">
                      {Store.getText("activationCodeLabel")}
                    </label>
                    <input
                      className="platform-main-page__activate-input"
                      value={accessKey}
                      onChange={(e) => setAccessKey(e.target.value)}
                    />
                  </div>
                  <button
                    className="platform-main-page__activate-button"
                    disabled={!Boolean(accessKey)}
                  >
                    {Store.getText("languageActivationButtonText")}
                  </button>
                </form>
                <div>
                  <p className="platform-main-page__order-text">
                    {Store.getText("orderCourseText")}
                  </p>
                  <Link
                    target="_blank"
                    className="platform-main-page__order-link"
                    to={`/${currentLanguagePair.languageFrom.languageShort.substring(
                      0,
                      2
                    )}/course`}
                  >
                    {Store.getText("orderCourseActionButton")}
                    <FontAwesomeIcon icon={faPencil} />
                  </Link>
                </div>
              </ColouredSpeechBubble>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default MainPageComponent;
