import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, useLocation, useSearchParams } from "react-router-dom";
import FlashMessageError from "./platform/components/FlashMessageError/FlashMessageError";
import LoadingScreen from "./platform/components/LoadingScreen/LoadingScreen";
import LoginChecker from "./LoginChecker";
import ActivityPage from "./platform/pages/ActivityPage/ActivityPage";
import TestPage from "./platform/pages/TestPage/TestPage";
import ChooseLanguageFromPage from "./platform/pages/ChooseLanguageFromPage/ChooseLanguageFromPage";
import HomePage from "./platform/pages/HomePage/HomePage";
import LessonsPage from "./platform/pages/LessonsPage/LessonsPage";
import MainPage from "./platform/pages/MainPage/MainPage";
import Store from "./Store";

import ProductPage from "./product/pages/ProductPage";
import AboutPage from "./product/pages/AboutPage";
import OrderPage from "./product/pages/OrderPage";

import { english } from "./product/data/englishLanguage";
import { hungary } from "./product/data/hungaryLanguage";
import { LanguageContext } from "./common/contexts/LanguageContext";
import { ProductWrapper } from "./common/productWrapper/ProductWrapper";
import StatsWrapper from "./platform/stats/StatsWrapper";
import ConsultantPages from "./consultant/ConsultantPages";
import AdminPages from "./admin/pages";
import { DownloadCertificatePage } from "./platform/pages/DownloadCertificatePage/DownloadCertificatePage";
import MailBoxPage from "./platform/pages/MailBoxPage/MailBoxPage";
import { slovak } from "./product/data/slovakLanguage";
import { croatian } from "./product/data/croatianLanguage";
import { lithuanian } from "./product/data/lithuanianLanguage";
import { romanian } from "./product/data/romanianLanguage";
import { pageDescription } from "./product/constants/pageDescription";
import { UTM } from "./product/constants/utm";
import { czech } from "./product/data/czechLanguage";
import { ConsultantHintContextWrapper } from "./platform/contexts/ConsultantHintContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { bulgarian } from "./product/data/bulgarianLanguage";
import { latvian } from "./product/data/lotvianLanguage";

function App() {
  const [errors, setErrors] = useState([]);
  const { lang } = useContext(LanguageContext);
  const [dataByLanguage, setDataByLanguage] = useState(hungary);
  const location = useLocation().pathname;
  const [searchParams] = useSearchParams();

  useLayoutEffect(() => {
    if (lang) {
      const data =
        lang === "hu"
          ? hungary
          : lang === "sk"
          ? slovak
          : lang === "hr"
          ? croatian
          : lang === "lt"
          ? lithuanian
          : lang === "ro"
          ? romanian
          : lang === "bg"
          ? bulgarian
          : lang === "lv"
          ? latvian
          : ["cs", "cz"].includes(lang)
          ? czech
          : english;

      setDataByLanguage(data);
      Store.setUserLanguage(lang);
      Store.setUserPlatformLanguage(lang);
    }
  }, [lang]);

  useEffect(() => {
    Store.setItem(UTM.SOURCE, searchParams.get(UTM.SOURCE));
    Store.setItem(UTM.MEDIUM, searchParams.get(UTM.MEDIUM));
  }, []);

  async function handleError(errors, exceptionMessage = null) {
    if (exceptionMessage) return setErrors([exceptionMessage]);
    const errCodesArray = errors.split(",");
    const errorMessagesArray = [];
    for (let i = 0; i < errCodesArray.length; i++) {
      errorMessagesArray.push(
        await Store.getExceptionMessage(errCodesArray[i])
      );
    }
    setErrors(errorMessagesArray);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      <ConsultantHintContextWrapper>
        <LoginChecker />
        <StatsWrapper>
          {errors.length > 0 && (
            <FlashMessageError setErrors={setErrors} messages={errors} />
          )}

          <LoadingScreen />
          <Routes>
            <Route
              exact
              path="/consultant/*"
              element={<ConsultantPages setError={handleError} />}
            ></Route>
            <Route
              exact
              path="/platform"
              element={<ChooseLanguageFromPage setError={handleError} />}
            ></Route>
            <Route
              exact
              path="/platform/admin/*"
              element={<AdminPages setError={handleError} />}
            ></Route>
            <Route
              exact
              path="/platform/:languageShort"
              element={<MainPage setError={handleError} />}
            ></Route>
            <Route
              exact
              path="/platform/lessons/:pairId/:level"
              element={<LessonsPage setError={handleError} />}
            ></Route>
            <Route
              exact
              path="/platform/activity/:pairId/:level"
              element={<ActivityPage setError={handleError} />}
            ></Route>
            <Route
              exact
              path="/platform/activity/:pairId/:level/:lessonId"
              element={<ActivityPage setError={handleError} />}
            ></Route>
            <Route
              exact
              path="/platform/test/:pairId/:level"
              element={<TestPage setError={handleError} />}
            ></Route>
            <Route
              exact
              path="/platform/mail-box"
              element={<MailBoxPage setError={handleError} />}
            ></Route>
            <Route
              exact
              path="/certificate"
              element={<DownloadCertificatePage setError={handleError} />}
            ></Route>
            <Route
              exact
              path="/:lang"
              element={
                <ProductWrapper
                  footerData={dataByLanguage.footer}
                  navbarData={dataByLanguage.navbar.data}
                >
                  <HomePage setError={handleError} />
                </ProductWrapper>
              }
            ></Route>
            <Route
              exact
              path="/:lang/password-recovery"
              element={
                <ProductWrapper
                  footerData={dataByLanguage.footer}
                  navbarData={dataByLanguage.navbar.data}
                >
                  <HomePage
                    isInitPasswordRecoveryModalOpen={true}
                    setError={handleError}
                  />
                </ProductWrapper>
              }
            ></Route>
            <Route
              exact
              path="/:lang/change-password"
              element={
                <ProductWrapper
                  footerData={dataByLanguage.footer}
                  navbarData={dataByLanguage.navbar.data}
                >
                  <HomePage
                    isInitChangePasswordModalOpen={true}
                    setError={handleError}
                  />
                </ProductWrapper>
              }
            ></Route>
            <Route
              exact
              path="/:lang/product"
              element={
                <ProductWrapper
                  footerData={dataByLanguage.footer}
                  navbarData={dataByLanguage.navbar.data}
                >
                  <ProductPage data={dataByLanguage.mainPage} />
                </ProductWrapper>
              }
            />
            <Route
              exact
              path="/:lang/about"
              element={
                <ProductWrapper
                  footerData={dataByLanguage.footer}
                  navbarData={dataByLanguage.navbar.data}
                >
                  <AboutPage data={dataByLanguage.aboutUs} />
                </ProductWrapper>
              }
            />
            <Route
              exact
              path="/:lang/course"
              element={
                <ProductWrapper
                  footerData={dataByLanguage.footer}
                  navbarData={dataByLanguage.navbar.data}
                >
                  <OrderPage data={dataByLanguage.formSender} />
                </ProductWrapper>
              }
            />
            <Route
              path="/"
              element={
                <ProductWrapper
                  footerData={dataByLanguage.footer}
                  navbarData={dataByLanguage.navbar.data}
                ></ProductWrapper>
              }
            />
          </Routes>
        </StatsWrapper>
      </ConsultantHintContextWrapper>
    </div>
  );
}

export default App;
