export const dictionary = {
    login: {
        pl: 'Logowanie',
        cs: 'Přihlášení',
        sk: 'Prihlásenie',
        en: 'Log in',
        hu: 'BEJELENTKEZÉS',
        hr: 'Prijava',
        lt: 'Prisijungti',
        ro: 'Logare',
        bg: 'Вход',
        lv: 'Pieteikties',
    },
    register: {
        pl: 'Rejestracja',
        cs: 'Registrace',
        sk: 'Registrácia',
        en: 'Register',
        hu: 'REGISZTRÁCIÓ',
        hr: 'Registracija',
        lt: 'Registruotis',
        ro: 'Înregistrare',
        bg: 'Регистрация',
        lv: 'Reģistrēties',
    },
    username: {
        pl: 'nazwa użytkownika',
        cs: 'uživatelské jméno',
        sk: 'uživatelské jméno',
        en: 'user name',
        hu: 'felhasználónév',
        hr: 'korisničko ime',
        lt: 'naudotojo vardas',
        ro: 'nume de utilizator',
        bg: 'потребителско име',
        lv: 'lietotājvārds',
    },
    orderCourse: {
        pl: 'Zamów kurs',
        cs: 'Order a course',
        sk: 'Order a course',
        en: 'Order a course',
        hu: 'Rendeljen egy tanfolyamot',
        hr: 'Naruči tečaj',
        lt: 'Užsisakykite kursą',
        ro: 'Comandați un curs',
        bg: 'Поръчайте курс',
        lv: 'Pasūtiet kursu',
        bg: 'Поръчайте курс',
        lv: 'Pasūtiet kursu',
    },
    aboutElingo: {
        pl: 'O eLingo',
        cs: 'O eLingo',
        sk: 'O eLingo',
        en: 'About eLingo',
        hu: 'Az eLingoról',
        hr: 'O eLingo',
        lt: 'Apie eLingo',
        ro: 'Despre eLingo',
        bg: 'За eLingo',
        lv: 'Par eLingo',
    },
    aboutUs: {
        pl: 'O nas',
        cs: 'About us',
        sk: 'About us',
        en: 'About us',
        hu: 'About us',
        hr: 'O nama',
        lt: 'Apie mus',
        ro: 'Despre noi',
        bg: 'За нас',
        lv: 'Par mums',
    },
    check: {
        pl: 'Sprawdź',
        cs: 'Podívejte se',
        sk: 'Skontrolujte',
        en: 'Check',
        hu: 'Jelölje be',
        hr: 'Provjeri',
        lt: 'Patikrinkite',
        ro: 'Verifica',
        bg: 'Проверете',
        lv: 'Pārbaudīt',
    },
    homePageSloganFirstLine: {
        pl: 'Nauka nowego języka?',
        cs: 'Učíte se cizí jazyk?',
        sk: 'Objavujete nový jazyk?',
        en: 'Discovering a new language?',
        hu: 'Új nyelvet fedez fel?',
        hr: 'Učenje novog jezika?',
        lt: 'Atrandate naują kalbą?',
        ro: 'Descoperiți o nouă limbă?',
        bg: 'Откриване на нов език?',
        lv: 'Atklājat jaunu valodu?',
    },
    homePageSloganSecondLine: {
        pl: 'To tylko 2 kroki!',
        cs: 'Stačí jen 2 kroky!',
        sk: 'Stačia na to len 2 kroky!',
        en: 'It only takes 2 steps!',
        hu: 'Mindössze 2 lépés!',
        hr: 'Samo su 2 koraka!',
        lt: 'Tereikia tik 2 žingsnių!',
        ro: 'Este nevoie doar de 2 pași!',
        bg: 'Това отнема само 2 стъпки!',
        lv: 'Tas prasa tikai 2 soļus!',
    },
    loginBoxDescription: {
        pl: 'Masz już konto na platformie i chcesz rozpocząć naukę? Twoja przygoda z językiem zaczyna się właśnie tutaj!',
        cs: 'Již máte na platformě účet a chcete se začít učit?  Vaše jazyková dobrodružství právě začínají!',
        sk: 'Už máte konto na platforme a chcete sa začať učiť?  Vaše jazykové dobrodružstvo sa začína práve tu!',
        en: 'Already have an account on the platform and want to begin learning?  Your language adventure starts right here!',
        hu: 'Már van fiókod a platformon, és szeretnéd elkezdeni a tanulást? Nyelvi kalandod itt és most kezdődik!',
        hr: 'Imate račun na platformi i želite započeti učenje? Vaša avantura s jezikom počinje ovdje!',
        lt: 'Jau turite paskyrą platformoje ir norite pradėti mokytis?  Jūsų kalbų nuotykis prasideda čia!',
        ro: 'Aveți deja un cont pe platformă și doriți să începeți să învățați?  Aventura ta lingvistică începe chiar aici!',
        bg: 'Вече имате акаунт на платформата и искате да започнете да учите? Вашето езиково приключение започва точно тук!',
        lv: 'Jau ir konts platformā un vēlaties sākt mācīties? Jūsu valodas piedzīvojums sākas tieši šeit!',
    },
    registerBoxDescription: {
        pl: 'Masz już kod do rejestracji? Świetnie! W tym miejscu możesz założyć konto!',
        cs: 'Máte již registrační kód? Skvělé! Nyní si zde můžete vytvořit účet!',
        sk: 'Máte už registračný kód? Skvelé! Tu si môžete vytvoriť účet!',
        en: 'Do you already have a registration code? Great! Here you can create an account!',
        hu: 'Van már regisztrációs kódod? Szuper! Itt tudsz fiókot létrehozni!',
        hr: 'Imate kod za registraciju? Odlično! Ovdje možete stvoriti svoj račun!',
        lt: 'Ar jau turite registracijos kodą? Puiku! Čia galite susikurti paskyrą!',
        ro: 'Ați vorbit deja cu unul dintre consultanții noștri lingvistici și ați primit pachetul cu un cod? Minunat! Aici vă puteți crea un cont!',
        bg: 'Вече имате регистрационен код? Страхотно! Тук можете да създадете акаунт!',
        lv: 'Jau ir reģistrācijas kods? Lieliski! Šeit jūs varat izveidot kontu!',
    },
    learnMoreHeader: {
        pl: 'Chcesz dowiedzieć się więcej?',
        cs: 'Chtěli byste se naučit více?',
        sk: 'Chcete sa naučiť viac?',
        en: 'Would you like to learn more?',
        hu: 'Szeretne többet megtudni?',
        hr: 'Želite saznati više?',
        lt: 'Norite išmokti daugiau?',
        ro: 'Doriți să învățați mai mult?',
        bg: 'Искате ли да научите повече?',
        lv: 'Vai vēlaties uzzināt vairāk?',
    },
    learnMoreDescription: {
        pl: 'Czym jest eLingo? Co myślą o nim kursanci? Dlaczego warto?',
        cs: 'Co je eLingo? Jaké jsou názory studentů? Proč to stojí za to?',
        sk: 'Čo je to eLingo? Čo si o ňom myslia používatelia kurzov? Prečo sa oplatí?',
        en: 'What is eLingo? What do course users think about it? Why is it worth it?',
        hu: 'Mi az eLingo? Mit gondolnak róla a kurzus felhasználói? Miért éri meg?',
        hr: 'Što je eLingo? Što misle o njemu polaznici? Zašto vrijedi?',
        lt: 'Kas yra eLingo? Ką apie jį mano kursų naudotojai? Kodėl verta jį išbandyti?',
        ro: 'Ce înseamnă eLingo? Ce părere au utilizatorii de cursuri despre el? De ce merită?',
        bg: 'Какво е eLingo? Какво мислят потребителите на курсове за него? Защо си струва?',
        lv: 'Kas ir eLingo? Ko par to domā kursu lietotāji? Kāpēc ir vērts?',
    },
    learnMoreButton: {
        pl: 'więcej',
        cs: 'více',
        sk: 'viac',
        en: 'more',
        hu: 'több',
        hr: 'više',
        lt: 'daugiau',
        ro: 'mai multe',
        bg: 'повече',
        lv: 'vairāk',
    },
    mainPageWelcomeBoxHeaderOne: {
        pl: 'Cześć',
        cs: 'Ahoj',
        sk: 'Ahoj',
        en: 'Hi',
        hu: 'Szia',
        hr: 'Bok',
        lt: 'Sveiki',
        ro: 'Bună ziua',
        bg: 'Здравейте',
        lv: 'Sveiki',
    },
    mainPageWelcomeBoxHeaderTwo1: {
        pl: 'Świetnie Ci idzie!',
        cs: 'Vedeš si skvěle!',
        sk: 'Darí sa ti skvele',
        en: "You're doing great",
        hu: 'Jól csinálod',
        hr: 'Odlično vam ide!',
        lt: 'Jums puikiai sekasi',
        ro: 'Te descurci foarte bine',
        bg: 'Справяш се страхотно',
        lv: 'Jums labi izdodas',
    },
    mainPageWelcomeBoxHeaderTwo2: {
        pl: 'Niezła robota!',
        cs: 'Dobrá práce',
        sk: 'Dobrá práca',
        en: 'Good job',
        hu: 'Jó munka',
        hr: 'Dobar posao!',
        lt: 'Geras darbas',
        ro: 'Bună treabă',
        bg: 'Добра работа',
        lv: 'Labi padarīts',
    },
    mainPageWelcomeBoxHeaderTwo3: {
        pl: 'Rozwijasz się!',
        cs: 'Zlepšuješ se',
        sk: 'Zlepšujete sa',
        en: "You're improving",
        hu: 'Fejlődsz',
        hr: 'Napredujete!',
        lt: 'Jūs tobulėjate',
        ro: 'Te îmbunătățești.',
        bg: 'Подобряваш се',
        lv: 'Jūs progresējat',
    },
    mainPageWelcomeBoxTextFirstLine1: {
        pl: 'Znajomość języków obcych jest nie tylko przydatna, ale wręcz niezbędna do osiągnięcia sukcesu w życiu.',
        cs: 'Znalost cizích jazyků je nejen užitečná, ale také nezbytná k dosažení životního úspěchu.',
        sk: 'Znalosť cudzích jazykov je nielen užitočná, ale aj nevyhnutná pre úspech v živote.',
        en: 'Knowledge of foreign languages is not only useful, but actually necessary for success in life.',
        hu: 'A külföldi nyelvek ismerete nemcsak hasznos, hanem szükséges is az életben való sikerhez.',
        hr: 'Znanje stranih jezika nije samo korisno, već je neophodno za postizanje uspjeha u životu.',
        lt: 'Užsienio kalbų mokėjimas yra ne tik naudingas, bet ir būtinas norint sėkmingai gyventi.',
        ro: 'Cunoașterea limbilor străine nu este doar utilă, ci chiar necesară pentru a avea succes în viață.',
        bg: 'Знанието на чужди езици не само е полезно, но всъщност е необходимо за успех в живота.',
        lv: 'Svešvalodu zināšanas ne tikai noder, bet ir nepieciešamas, lai gūtu panākumus dzīvē.',
    },
    mainPageWelcomeBoxTextFirstLine2: {
        pl: 'Osoby, które uczą się języków obcych łatwiej zapamiętują i koncentrują się na zadaniach, a także są lepszymi słuchaczami.',
        cs: 'Lidé, kteří mluví více než jedním jazykem, mají lepší paměť, lepší soustředění, schopnost multitaskingu a lepší schopnost naslouchat.',
        sk: 'Ľudia, ktorí ovládajú viac ako jeden jazyk, majú lepšiu pamäť, lepšiu koncentráciu, schopnosť vykonávať viac úloh naraz a lepšie počúvajú.',
        en: 'People who speak more than one language have improved memory, enhanced concentration, ability to multitask, and better listening skills.',
        hu: 'Azok az emberek, akik több nyelvet beszélnek, javítják a memóriájukat, javítják a koncentrációjukat, képesek több feladatot egyszerre elvégezni, és jobb hallási képességeik vannak.',
        hr: 'Ljudi koji uče strane jezike lakše pamte i koncentriraju se na zadatke, a također su bolji slušatelji.',
        lt: 'Daugiau nei viena kalba kalbantys žmonės turi geresnę atmintį, geresnę koncentraciją, geba atlikti daug užduočių ir geriau klausosi.',
        ro: 'Persoanele care vorbesc mai multe limbi străine au o memorie îmbunătățită, o concentrare sporită, capacitatea de a face mai multe lucruri în același timp și abilități de ascultare mai bune.',
        bg: 'Хората, които говорят повече от един език, имат подобрена памет, повишена концентрация, способност за многозадачност и по-добри умения за слушане.',
        lv: 'Cilvēkiem, kas runā vairāk nekā vienā valodā, ir uzlabota atmiņa, uzlabota koncentrēšanās spēja, spēja strādāt ar vairākiem uzdevumiem vienlaikus un labākas klausīšanās prasmes.',
    },
    mainPageWelcomeBoxTextFirstLine3: {
        pl: 'Nauka nowego języka to niezwykłe doświadczenie. Poczuj radość, rozmawiając z kimś w jego ojczystym języku.',
        cs: 'Naučit se cizí jazyk si vyžaduje pevnou vůli. Výhodou je úžasný pocit úspěchu, který pocítíte při konverzaci s někým v jeho rodném jazyce.',
        sk: 'Učiť sa cudzí jazyk znamená vystúpiť zo svojej komfortnej zóny. Pozitívom je úžasný pocit úspechu, ktorý pocítite, keď sa s niekým rozprávate v jeho rodnom jazyku.',
        en: 'Learning a language means moving out of your comfort zone. The upside is the amazing sense of accomplishment you’ll feel when conversing with someone in their native language.',
        hu: 'A nyelvtanulás azt jelenti, hogy kilépsz a komfortzónádból. Az előny az, hogy elképesztő érzés lesz, amikor valakivel anyanyelvén beszélgetsz.',
        hr: 'Učenje novog jezika je nevjerojatno iskustvo. Osjetite radost razgovarajući s nekim na njegovom materinjem jeziku.',
        lt: 'Mokytis kalbos reiškia išeiti iš savo komforto zonos. Geroji pusė - nuostabus pasiekimo jausmas, kurį pajusite bendraudami su žmogumi jo gimtąja kalba.',
        ro: 'Învățarea unei limbi străine înseamnă să ieși din zona ta de confort. Partea bună este sentimentul uimitor de realizare pe care îl veți simți atunci când veți conversa cu cineva în limba sa maternă.',
        bg: 'Да научиш език означава да излезеш от зоната си на комфорт. Положителната страна е невероятното чувство на удовлетворение, което ще почувствате, когато разговаряте с някого на неговия роден език.',
        lv: 'Valodas apguve nozīmē izkļūt no savas komforta zonas. Labā puse ir neticamā sasniegumu sajūta, ko sajutīsiet, sarunājoties ar kādu viņa dzimtajā valodā.',
    },
    mainPageWelcomeBoxTextSecondLine: {
        pl: 'W następnej lekcji nauczysz się:',
        cs: 'V příští lekci se naučíte:',
        sk: 'V nasledujúcej lekcii sa naučíte:',
        en: 'In the next lesson you will learn:',
        hu: 'A következő leckében megtanulod:',
        hr: 'U sljedećoj lekciji naučit ćete:',
        lt: 'Kitoje pamokoje sužinosite:',
        ro: 'În următoarea lecție veți învăța:',
        bg: 'В следващия урок ще научите:',
        lv: 'Nākamajā nodarbībā jūs uzzināsiet:',
    },
    yourActivityBoxHeader: {
        pl: 'Twoja aktywność',
        cs: 'Tvoje aktivita',
        sk: 'Vaša aktivita',
        en: 'Your activity',
        hu: 'Az Ön tevékenysége',
        hr: 'Tvoja aktivnost',
        lt: 'Jūsų veikla',
        ro: 'Activitatea ta',
        bg: 'Вашата активност',
        lv: 'Jūsu darbība',
    },
    yourActivityBoxDaysOfLearning: {
        pl: 'dni nauki z rzędu',
        cs: 'Dny učení v řadě',
        sk: 'Dni učenia v rade',
        en: 'days of learning in a row',
        hu: 'napos tanulás',
        hr: 'dani učenja zaredom',
        lt: 'mokymosi dienos iš eilės',
        ro: 'zile de învățare la rând',
        bg: 'дни на учене подред',
        lv: 'mācību dienas pēc kārtas',
    },
    yourActivityBoxLearnedWords: {
        pl: 'poznanych dziś słów',
        cs: 'Nová slovíčka za dnešek',
        sk: 'slová, ktoré ste sa dnes naučili',
        en: 'the words you learned today',
        hu: 'a mai tanult szavak',
        hr: 'danas naučene riječi',
        lt: 'šiandien išmokti žodžiai',
        ro: 'cuvintele pe care le-ați învățat astăzi',
        bg: 'думите, които научихте днес',
        lv: 'šodien apgūtie vārdi',
    },
    level: {
        pl: 'Poziom',
        cs: 'Úroveň',
        sk: 'Úroveň',
        en: 'Level',
        hu: 'Szint',
        hr: 'Razina',
        lt: 'Lygis',
        ro: 'Nivelul',
        bg: 'Ниво',
        lv: 'Līmenis',
    },
    mainPageProgressBarDescription: {
        pl: 'Twój postęp na poziomie',
        cs: 'Váš pokrok na úrovni',
        sk: 'Váš pokrok na úrovni',
        en: 'Your progress on level',
        hu: 'A fejlődésed szintenként',
        hr: 'Tvoj napredak na razini',
        lt: 'Jūsų pažanga iki kito lygio',
        ro: 'Progresul tău la nivel',
        bg: 'Вашият напредък на ниво',
        lv: 'Jūsu progress līmenī',
    },
    mainPageContinueLearningButtonText: {
        pl: 'Kontynuuj naukę',
        cs: 'Pokračovat v učení',
        sk: 'Pokračujte v učení',
        en: 'Continue learning',
        hu: 'Tovább a tanulással',
        hr: 'Nastavi učiti',
        lt: 'Tęsti mokymąsi',
        ro: 'Continuați să învățați',
        bg: 'Продължете да учите',
        lv: 'Turpiniet mācīties',
    },
    mainPageAllLessonsgButtontext: {
        pl: 'Wszystkie lekcje',
        cs: 'Všechny lekce',
        sk: 'Všetky lekcie',
        en: 'All lessons',
        hu: 'Az összes lecke',
        hr: 'Sve lekcije',
        lt: 'Visos pamokos',
        ro: 'Toate lecțiile',
        bg: 'Всички уроци',
        lv: 'Visas nodarbības',
    },
    mainPageActiveCourseHeader: {
        pl: 'Twój aktualny kurs',
        cs: 'Tvůj aktuální kurz',
        sk: 'Tvoj aktuálny kurz',
        en: 'Active course',
        hu: 'Aktuális kurzusod',
        hr: 'Tvoj trenutni tečaj',
        lt: 'Aktyvus kursas',
        ro: 'Cursul tău actual',
        bg: 'Вашият текущ курс',
        lv: 'Jūsu aktīvais kurss',
    },
    mainPageOtherCoursesButtonTextPre: {
        pl: 'Chcesz nauczyć się więcej?',
        cs: 'Chceš se naučit více?',
        sk: 'Chceš sa naučiť viac?',
        en: 'Want to learn more?',
        hu: 'Szeretnél többet megtanulni?',
        hr: 'Želiš naučiti više?',
        lt: 'Norite išmokti daugiau?',
        ro: 'Vrei săînveți mai mult?',
        bg: 'Искате да научите повече?',
        lv: 'Vēlaties uzzināt vairāk?',
    },
    mainPageOtherCoursesButtonText: {
        pl: 'Aktywuj kolejny kurs >>',
        cs: 'Aktivuj další kurz >>',
        sk: 'Aktivuj ďalší kurz >>',
        en: 'Activate other Courses >>',
        hu: 'Aktiváld a következő kurzust >>',
        hr: 'Aktiviraj sljedeći tečaj >>',
        lt: 'Aktyvuokite kitus kursus >>',
        ro: 'Activează un alt curs >>',
        bg: 'Активирайте друг курс >>',
        lv: 'Aktivizējiet citus kursus >>',
    },
    mainPageFunFactsHeader: {
        pl: 'Ciekawostki',
        cs: 'Zajímavá fakta',
        sk: 'Zaujímavosti',
        en: 'Interesting facts',
        hu: 'Interesting facts',
        hr: 'Zanimljivosti',
        lt: 'Įdomūs faktai',
        ro: 'Date interesante',
        bg: 'Интересни факти',
        lv: 'Interesanti fakti',
    },
    showPassword: {
        pl: 'Pokaż hasło',
        cs: 'Zobrazit heslo',
        sk: 'Ukázať heslo',
        en: 'Show password',
        hu: 'Mutasd a jelszót',
        hr: 'Prikaži lozinku',
        lt: 'Rodyti slaptažodį',
        ro: 'Afișați parola',
        bg: 'Показване на паролата',
        lv: 'Rādīt paroli',
    },
    hidePassword: {
        pl: 'Ukryj hasło',
        cs: 'Skrýt heslo',
        sk: 'Skryť heslo',
        en: 'Hide password',
        hu: 'Jelszó elrejtése',
        hr: 'Sakrij lozinku',
        lt: 'Paslėpti slaptažodį',
        ro: 'Ascundeți parola',
        bg: 'Скрий паролата',
        lv: 'Slēpt paroli',
    },
    passwordsNotMatchAlert: {
        pl: 'Hasła nie są takie same',
        cs: 'Hesla nejsou stejná',
        sk: 'Heslá sa nezhodujú',
        en: 'Passwords do not match',
        hu: 'A jelszavak nem egyeznek',
        hr: 'Lozinke se ne podudaraju',
        lt: 'Slaptažodžiai nesutampa',
        ro: 'Parolele nu sunt la fel',
        bg: 'Паролите не съвпадат',
        lv: 'Paroles nesakrīt',
    },
    progressModalMondayShort: {
        pl: 'pn.',
        cs: 'Po.',
        sk: 'Po,',
        en: 'Mon.',
        hu: 'Hé',
        hr: 'pon.',
        lt: 'Pirm.',
        ro: 'Luni',
        bg: 'Пон.',
        lv: 'Pirmd.',
    },
    progressModalTuesdayShort: {
        pl: 'wt.',
        cs: 'Út.',
        sk: 'Ut.',
        en: 'Tue.',
        hu: 'Ke',
        hr: 'uto.',
        lt: 'Ant.',
        ro: 'Marți',
        bg: 'Вто.',
        lv: 'Otrd.',
    },
    progressModalWednesdayShort: {
        pl: 'śr.',
        cs: 'St.',
        sk: 'St.',
        en: 'Wed.',
        hu: 'Sze',
        hr: 'sri.',
        lt: 'Treč.',
        ro: 'Miercuri',
        bg: 'Сря.',
        lv: 'Trešd.',
    },
    progressModalThursdayShort: {
        pl: 'czw.',
        cs: 'Čt.',
        sk: 'Stv.',
        en: 'Thu.',
        hu: 'Cs',
        hr: 'čet.',
        lt: 'Ketv.',
        ro: 'Joi',
        bg: 'Чет.',
        lv: 'Cet.',
    },
    progressModalFridayShort: {
        pl: 'pt.',
        cs: 'Pá.',
        sk: 'Pi.',
        en: 'Fri.',
        hu: 'Pé',
        hr: 'pet.',
        lt: 'Penkt.',
        ro: 'Vineri',
        bg: 'Пет.',
        lv: 'Piek.',
    },
    progressModalSaturdayShort: {
        pl: 'sob.',
        cs: 'So.',
        sk: 'So.',
        en: 'Sat.',
        hu: 'Szo',
        hr: 'sub.',
        lt: 'Št.',
        ro: 'Sâmbătă',
        bg: 'Съб.',
        lv: 'Sestd.',
    },
    progressModalSundayShort: {
        pl: 'ndz.',
        cs: 'Ne.',
        sk: 'Ne.',
        en: 'Sun',
        hu: 'Vas',
        hr: 'ned.',
        lt: 'Sk.',
        ro: 'Duminică',
        bg: 'Нед.',
        lv: 'Sv.',
    },
    progressModalChartTitle: {
        pl: 'Wykres Twoich postępów',
        cs: 'Graf vašeho pokroku',
        sk: 'Graf vášho pokroku',
        en: 'Charting your progress',
        hu: 'A fejlődés feltérképezése',
        hr: 'Grafikon tvog napretka',
        lt: 'Pažangos grafikas',
        ro: 'Graficul tău de progres',
        bg: 'Проследяване на вашия напредък',
        lv: 'Jūsu progresa diagramma',
    },
    back: {
        pl: 'Wstecz',
        cs: 'Zpět',
        sk: 'Späť',
        en: 'Back',
        hu: 'Vissza',
        hr: 'Natrag',
        lt: 'Atgal',
        ro: 'Înapoi',
        bg: 'Назад',
        lv: 'Atpakaļ',
    },
    emailLabel: {
        pl: 'adres e-mail',
        cs: 'e-mailová adresa',
        sk: 'emailová adresa',
        en: 'email address',
        hu: 'email cím',
        hr: 'e-mail adresa',
        lt: 'el. pašto adresas',
        ro: 'adresa de e-mail',
        bg: 'електронна поща',
        lv: 'e-pasta adrese',
    },
    passwordLabel: {
        pl: 'hasło',
        cs: 'heslo',
        sk: 'heslo',
        en: 'password',
        hu: 'jelszó',
        hr: 'lozinka',
        lt: 'slaptažodis',
        ro: 'parola',
        bg: 'парола',
        lv: 'parole',
    },
    activationCodeLabel: {
        pl: 'kod',
        cs: 'kód',
        sk: 'kód',
        en: 'code',
        hu: 'kód',
        hr: 'kod',
        lt: 'kodas',
        ro: 'cod',
        bg: 'код',
        lv: 'kods',
    },
    repeatPasswordLabel: {
        pl: 'powtórz hasło',
        cs: 'zopakovat heslo',
        sk: 'zopakujte heslo',
        en: 'repeat password',
        hu: 'jelszó újra',
        hr: 'ponovi lozinku',
        lt: 'pakartoti slaptažodį',
        ro: 'repetați parola',
        bg: 'повтори паролата',
        lv: 'atkārtot paroli',
    },
    oldPasswordLabel: {
        pl: 'stare hasło',
        cs: 'staré heslo',
        sk: 'staré heslo',
        en: 'old password',
        hu: 'régi jelszó',
        hr: 'stara lozinka',
        lt: 'senasis slaptažodis',
        ro: 'parola veche',
        bg: 'стара парола',
        lv: 'vecā parole',
    },
    registerEmailDesc: {
        pl: 'Twój adres e-mail, którego będziesz używać do zalogowania się',
        cs: 'Vaše e-mailová adresa, kterou budete používat pro přihlášení',
        sk: 'Vaša e-mailová adresa, ktorú budete používať na prihlásenie',
        en: 'Your e-mail address, which you will use to log in',
        hu: 'az Ön e-mail címe, amelyet a bejelentkezéshez fog használni',
        hr: 'Tvoja e-mail adresa koju ćeš koristiti za prijavu',
        lt: 'Jūsų el. pašto adresas, kurį naudosite prisijungimui',
        ro: 'Adresa dvs. de e-mail pe care o veți folosi pentru a vă conecta',
        bg: 'Вашият имейл адрес, който ще използвате за влизане',
        lv: 'Jūsu e-pasta adrese, kuru izmantosiet, lai pieteiktos',
    },
    loginEmailDesc: {
        pl: 'Twój adres e-mail, który podawałeś/aś przy założeniu konta',
        cs: 'Vaše e-mailová adresa, kterou jste zadali při vytváření účtu',
        sk: 'Vaša e-mailová adresa, ktorú ste uviedli pri vytváraní účtu',
        en: 'Your email address you entered when you created account',
        hu: 'A fiók létrehozásakor megadott e-mail címed',
        hr: 'Tvoja e-mail adresa koju si dao/la prilikom stvaranja računa',
        lt: 'Jūsų el. pašto adresas, kurį įvedėte kurdami paskyrą',
        ro: 'Adresa dvs. de e-mail pe care ați furnizat-o la crearea contului dvs.',
        bg: 'Вашият имейл адрес, който сте въвели при създаването на акаунта',
        lv: 'Jūsu e-pasta adrese, kuru ievadījāt, izveidojot kontu',
    },
    activationCodeDesc: {
        pl: 'otrzymałeś/aś go w liście',
        cs: 'obdrželi jste ho v dopise',
        sk: 'dostali ste to v liste',
        en: 'you have received it in the letter',
        hu: 'evélben kaptad',
        hr: 'primio/la si ga u pismu',
        lt: 'jį gavote laiške',
        ro: 'ați primit-o prin poștă',
        bg: 'получили сте го в писмото',
        lv: 'jūs to esat saņēmis vēstulē',
    },
    registerPasswordDesc: {
        pl: 'Stwórz unikalne hasło, które łatwo zapamiętasz.',
        cs: 'vytvořte si vlastní jedinečné heslo, které si snadno zapamatujete',
        sk: 'vytvorte si svoje jedinečné heslo, ktoré si ľahko zapamätáte',
        en: 'create your own unique password that you can easily remember',
        hu: 'hozzon létre saját egyedi jelszót, amelyet könnyen megjegyezhet',
        hr: 'Stvori jedinstvenu lozinku koju ćeš lako zapamtiti.',
        lt: 'sukurkite savo unikalų slaptažodį, kurį galėtumėte lengvai įsiminti',
        ro: 'creați o parolă unică pe care să o puteți reține cu ușurință',
        bg: 'създайте своя уникална парола, която лесно можете да запомните',
        lv: 'izveidojiet savu unikālu paroli, kuru varat viegli atcerēties',
    },
    repeatPasswordDesc: {
        pl: 'aby upewnić się, że pamiętasz hasło, wpisz je jeszcze raz',
        cs: 'abyste se ujistili, že si heslo pamatujete, zadejte ho znovu',
        sk: 'aby ste sa uistili, že si zapamätáte svoje heslo, zadajte ho znova',
        en: 'to make sure you remember your password, type it again',
        hu: 'hogy biztosan emlékezzen a jelszavára, írja be újra',
        hr: 'kako bi se uvjerio/la da pamtiš lozinku, unesi je ponovno',
        lt: 'norėdami įsitikinti, kad atsimenate slaptažodį, įveskite jį dar kartą',
        ro: 'pentru a vă asigura că vă amintiți parola, introduceți-o din nou',
        bg: 'за да се уверите, че помните паролата си, въведете я отново',
        lv: 'lai pārliecinātos, ka atceraties savu paroli, ierakstiet to vēlreiz',
    },
    loginPasswordDesc: {
        pl: 'Twoje unikalne hasło, stworzone podczas zakładania konta',
        cs: 'Vaše jedinečné heslo, které jste si vytvořili při vytváření účtu',
        sk: 'Vaše jedinečné heslo, ktoré ste si vytvorili pri vytváraní účtu',
        en: 'Your unique password that you created when you created account',
        hu: 'Az Ön egyedi jelszava, amelyet a fiók létrehozásakor hozott létre',
        hr: 'Tvoja jedinstvena lozinka, stvorena prilikom stvaranja računa',
        lt: 'Jūsų unikalus slaptažodis, kurį sukūrėte kurdami paskyrą',
        ro: 'Parola unică pe care ați creat-o atunci când v-ați creat contul dvs.',
        bg: 'Вашата уникална парола, която сте създали при създаването на акаунт',
        lv: 'Jūsu unikālā parole, kuru izveidojāt, izveidojot kontu',
    },
    loginButtonText: {
        pl: 'Zaloguj się',
        cs: 'Přihlásit se',
        sk: 'Prihláste sa',
        en: 'Log in',
        hu: 'Belépés',
        hr: 'Prijavi se',
        lt: 'Prisijungti',
        ro: 'Conectați-vă',
        bg: 'Вход',
        lv: 'Pieteikties',
    },
    logoutButtonText: {
        pl: 'Wyloguj się',
        cs: 'Odhlásit se',
        sk: 'Odhlásiť sa',
        en: 'Log out',
        hu: 'Kijelentkezés',
        hr: 'Odjavi se',
        lt: 'Atsijungti',
        ro: 'Deconectați-vă',
        bg: 'Изход',
        lv: 'Izrakstīties',
    },
    logoutMessage: {
        pl: 'Czy na pewno chcesz się wylogować?',
        cs: 'Opravdu se chcete odhlásit?',
        sk: 'Naozaj sa chcete odhlásiť?',
        en: 'Are you sure you want to log out?',
        hu: 'Biztosan ki akar jelentkezni?',
        hr: 'Jeste li sigurni da se želite odjaviti?',
        lt: 'Ar tikrai norite atsijungti?',
        ro: 'Sunteți sigur că doriți să vă deconectați?',
        bg: 'Сигурни ли сте, че искате да излезете?',
        lv: 'Vai tiešām vēlaties izrakstīties?',
    },
    registerSlogan: {
        pl: 'Aby utworzyć konto i mieć dostęp do nauki wykupionego kursu językowego, wypełnij poniższe pola:',
        cs: 'Pro vytvoření účtu a přístup k zakoupenému jazykovému kurzu vyplňte níže uvedená pole:',
        sk: 'Ak chcete vytvoriť účet a získať prístup k štúdiu jazykového kurzu, ktorý ste si zakúpili, vyplňte polia nižšie:',
        en: 'Fill in the fields below to create an account and have access to your language course:',
        hu: 'Töltse ki az alábbi mezőket a fiók létrehozásához és a nyelvtanfolyamhoz való hozzáféréshez:',
        hr: 'Da biste stvorili račun i pristupili učenju kupljenog jezičnog tečaja, ispunite sljedeća polja:',
        lt: 'Užpildykite toliau esančius laukus, kad susikurtumėte paskyrą ir turėtumėte prieigą prie savo kalbos kurso:',
        ro: 'Pentru a vă crea un cont și a avea acces pentru a studia cursul de limbă achiziționat, completați câmpurile de mai jos:',
        bg: 'Попълнете полетата по-долу, за да създадете акаунт и да имате достъп до вашия езиков курс:',
        lv: 'Aizpildiet zemāk redzamos laukus, lai izveidotu kontu un piekļūtu savam valodas kursam:',
    },
    loginSlogan: {
        pl: 'Jeśli posiadasz już konto, wypełnij poniższe pola:',
        cs: 'Pokud již máte účet, vyplňte níže uvedená pole:',
        sk: 'Ak už máte účet, vyplňte polia nižšie:',
        en: 'If you already have an account, please fill out the fields below:',
        hu: 'Ha már rendelkezik fiókkal, kérjük, töltse ki az alábbi mezőket:',
        hr: 'Ako već imate račun, ispunite sljedeća polja:',
        lt: 'Jei jau turite paskyrą, užpildykite toliau esančius laukus:',
        ro: 'Dacă aveți deja un cont, completați câmpurile de mai jos:',
        bg: 'Ако вече имате акаунт, моля попълнете полетата по-долу:',
        lv: 'Ja jums jau ir konts, lūdzu, aizpildiet zemāk redzamos laukus:',
    },
    registerButtonText: {
        pl: 'Załóż konto',
        cs: 'Vytvořit účet',
        sk: 'Vytvoriť účet',
        en: 'Create an account',
        hu: 'Fiók létrehozása',
        hr: 'Kreiraj račun',
        lt: 'Sukurti paskyrą',
        ro: 'Creați un cont',
        bg: 'Създайте акаунт',
        lv: 'Izveidot kontu',
    },
    levelOneText: {
        pl: 'podstawowy',
        cs: 'základní',
        sk: 'ZÁKLADNÉ',
        en: 'Basic',
        hu: 'Alapvető',
        hr: 'osnovni',
        lt: 'Pagrindinis',
        ro: 'de bază',
        bg: 'Основен',
        lv: 'Pamata',
    },
    levelTwoText: {
        pl: 'średni',
        cs: 'střední',
        sk: 'STREDNE',
        en: 'Medium',
        hu: 'Közepes',
        hr: 'srednji',
        lt: 'Vidutinis',
        ro: 'intermediar',
        bg: 'Средно',
        lv: 'Vidējs',
    },
    levelThreeText: {
        pl: 'zaawansowany',
        cs: 'pokročilý',
        sk: 'POKROČILÉ',
        en: 'Advanced',
        hu: 'Fejlett',
        hr: 'napredni',
        lt: 'Pažengęs',
        ro: 'avansat',
        bg: 'Напреднал',
        lv: 'Uzlabots',
    },
    pageTitle: {
        pl: 'eLingo',
        cs: 'eLingo',
        sk: 'eLingo',
        en: 'eLingo',
        hu: 'eLingo',
        hr: 'eLingo',
        lt: 'eLingo',
        ro: 'eLingo',
        bg: 'eLingo',
        lv: 'eLingo',
    },
    activityExpressionLearning: {
        pl: 'Poznaj słowo',
        cs: 'Zkoumej slovo',
        sk: 'Objavte slovo',
        en: 'Discover the word',
        hu: 'Fedezze fel a szót',
        hr: 'Upoznaj riječ',
        lt: 'Atraskite žodį',
        ro: 'Descoperiți cuvântul',
        bg: 'Открийте думата',
        lv: 'Atklājiet vārdu',
    },
    activityExpressionListening: {
        pl: 'Co słyszysz?',
        cs: 'Vyber, co slyšíš',
        sk: 'Vyberte, čo počujete',
        en: 'Mark what you hear',
        hu: 'Jelölje meg, mit hall',
        hr: 'Što čuješ?',
        lt: 'Pažymėkite tai, ką girdite',
        ro: 'Marcați ceea ce auziți',
        bg: 'Маркирайте какво чувате',
        lv: 'Atzīmējiet to, ko dzirdat',
    },
    activityExpressionTranslation: {
        pl: 'Ułóż zdanie',
        cs: 'Použijte slova a správně přeložte',
        sk: 'Použite slová na vytvorenie správneho prekladu',
        en: 'Make the correct translation from the words',
        hu: 'Készítsd el a helyes fordítást a szavakból!',
        hr: 'Sastavi rečenicu',
        lt: 'Teisingai išverskite žodžius',
        ro: 'Faceți traducerea corectă din cuvinte',
        bg: 'Направете правилния превод от думите',
        lv: 'Izveidojiet pareizu tulkojumu no vārdiem',
    },
    activityUnscrambleExpressionLetters: {
        pl: 'Ułóż słowo',
        cs: 'Použijte písmena a správně přeložte',
        sk: 'Použite písmená na vytvorenie správneho prekladu',
        en: 'Use letters to make the correct translation',
        hu: 'Használd a betűket a helyes fordítás elkészítéséhez',
        hr: 'Složi riječ',
        lt: 'Naudokite raides teisingam vertimui atlikti',
        ro: 'Folosiți literele pentru a face traducerea corectă',
        bg: 'Използвайте букви, за да направите правилния превод',
        lv: 'Izmantojiet burtus, lai izveidotu pareizu tulkojumu',
    },
    activityLinkExpressions: {
        pl: 'Połącz w pary',
        cs: 'Párová slova',
        sk: 'Vytvorte dvojice slov',
        en: 'Put them together',
        hu: 'Tedd össze őket',
        hr: 'Poveži u parove',
        lt: 'Sudėkite juos kartu',
        ro: 'Puneți-le împreună',
        bg: 'Свържете ги заедно',
        lv: 'Savienojiet tos kopā',
    },
    exampleSentence: {
        pl: 'Przykładowe zdanie',
        cs: 'Příklad věty',
        sk: 'Príklad vety',
        en: 'Example sentence',
        hu: 'Példamondat',
        hr: 'Primjerni rečenica',
        lt: 'Sakinio pavyzdys',
        ro: 'Exemplu de propoziție',
        bg: 'Примерно изречение',
        lv: 'Piemēra teikums',
    },
    yourActivity: {
        pl: 'Twoja aktywność',
        cs: 'Tvoje aktivita',
        sk: 'Vaša aktivita',
        en: 'Your activity',
        hu: 'Az Ön tevékenysége',
        hr: 'Tvoja aktivnost',
        lt: 'Jūsų veikla',
        ro: 'Activitatea ta',
        bg: 'Вашата активност',
        lv: 'Jūsu darbība',
    },
    tutorial: {
        pl: 'Samouczek',
        cs: 'Výukový kurz',
        sk: 'Učebnica',
        en: 'Tutorial',
        hu: 'Oktatóanyag',
        hr: 'Upute',
        lt: 'Mokomoji priemonė',
        ro: 'Tutorial',
        bg: 'Ръководство',
        lv: 'Apmācība',
    },
    changePassword: {
        pl: 'Zmień hasło',
        cs: 'Změna hesla',
        sk: 'Zmena hesla',
        en: 'Change Password',
        hu: 'Jelszó megváltoztatása',
        hr: 'Promijeni lozinku',
        lt: 'Keisti slaptažodį',
        ro: 'Schimbați parola',
        bg: 'Смяна на паролата',
        lv: 'Mainīt paroli',
    },
    logOut: {
        pl: 'Wyloguj się',
        cs: 'Odhlásit se',
        sk: 'Odhlásiť sa',
        en: 'Log out',
        hu: 'Kijelentkezés',
        hr: 'Odjavi se',
        lt: 'Atsijungti',
        ro: 'Deconectați-vă',
        bg: 'Изход',
        lv: 'Izrakstīties',
    },
    help: {
        pl: 'Pomoc',
        cs: 'Nápověda',
        sk: 'Pomoc',
        en: 'Help',
        hu: 'Segítség',
        hr: 'Pomoć',
        lt: 'Pagalba',
        ro: 'Ajutor',
        bg: 'Помощ',
        lv: 'Palīdzība',
    },
    goodAnswer: {
        pl: 'Dobrze',
        cs: 'Dobře',
        sk: 'Dobrý',
        en: 'Good',
        hu: 'Jó',
        hr: 'Dobro',
        lt: 'Gerai',
        ro: 'Bun',
        bg: 'Добре',
        lv: 'Labi',
    },
    badAnswer: {
        pl: 'Źle',
        cs: 'Špatně',
        sk: 'Zlé',
        en: 'Bad',
        hu: 'Rossz',
        hr: 'Loše',
        lt: 'Blogai',
        ro: 'Rău',
        bg: 'Зле',
        lv: 'Slikti',
    },
    continueTasks: {
        pl: 'Dalej',
        cs: 'Další',
        sk: 'Pokračovať',
        en: 'Continue',
        hu: 'Folytatás',
        hr: 'Nastavi',
        lt: 'Tęsti',
        ro: 'Continuați',
        bg: 'Продължи',
        lv: 'Turpināt',
    },
    lessonCompleted: {
        pl: 'ukończone',
        cs: 'Dokončeno',
        sk: 'Dokončené',
        en: 'Completed',
        hu: 'Befejezett',
        hr: 'završeno',
        lt: 'Užbaigta',
        ro: 'Completat',
        bg: 'Завършен',
        lv: 'Pabeigts',
    },
    lessonInProgress: {
        pl: 'w trakcie',
        cs: 'Probíhá',
        sk: 'prebieha',
        en: 'in progress',
        hu: 'folyamatban',
        hr: 'u tijeku',
        lt: 'Vykdoma',
        ro: 'în timp ce',
        bg: 'В процес на изпълнение',
        lv: 'Notiek',
    },
    lessonNotActive: {
        pl: 'niekatywne',
        cs: 'Není aktivní',
        sk: 'nie je aktívny',
        en: 'not active',
        hu: 'nem aktív',
        hr: 'neaktivno',
        lt: 'neaktyvus',
        ro: 'neactiv',
        bg: 'неактивен',
        lv: 'neaktīvs',
    },
    minutes: {
        pl: 'minut',
        cs: 'minut',
        sk: 'minút',
        en: 'minutes',
        hu: 'perc',
        hr: 'minuta',
        lt: 'minučių',
        ro: 'minute',
        bg: 'минути',
        lv: 'minūtes',
    },
    languageChangeYourCourses: {
        pl: 'Twoje kursy',
        cs: 'Vaše kurzy',
        sk: 'Vaše kurzy',
        en: 'Your courses',
        hu: 'A tanfolyamaid',
        hr: 'Tvoji tečajevi',
        lt: 'Jūsų kursai',
        ro: 'Cursurile dumneavoastră',
        bg: 'Вашите курсове',
        lv: 'Jūsu kursi',
    },
    languageChangeAvailable: {
        pl: 'Rozszerz swoje umiejętności językowe:',
        cs: 'Rozšiř své jazykové dovednosti:',
        sk: 'Rozšír svoje jazykové schopnosti:',
        en: 'Also available:',
        hu: 'Fejleszd nyelvi készségeidet:',
        hr: 'Proširi svoje jezične vještine:',
        lt: 'Taip pat galite rasti:',
        ro: 'Îmbunătățește-țiabilitățile lingvistice:',
        bg: 'Разширете своите езикови умения:',
        lv: 'Paplašiniet savas valodu prasmes:',
    },
    languageActivationInputLabel: {
        pl: 'Wprowadź kod, aby aktywować nowy język:',
        cs: 'Zadejte kód pro aktivaci nového jazyka:',
        sk: 'Zadaním kódu aktivujete nový jazyk:',
        en: 'Enter the code to activate the new language:',
        hu: 'Írd be a kódot az új nyelv aktiválásához:',
        hr: 'Unesi kod za aktivaciju novog jezika:',
        lt: 'Įveskite kodą, kad aktyvuotumėte naują kalbą:',
        ro: 'Introduceți codul pentru a activa noua limbă:',
        bg: 'Въведете кода, за да активирате новия език:',
        lv: 'Ievadiet kodu, lai aktivizētu jauno valodu:',
    },
    languageActivationButtonText: {
        pl: 'Aktywuj',
        cs: 'Aktivace',
        sk: 'Aktivácia',
        en: 'Activation',
        hu: 'Aktiválás',
        hr: 'Aktiviraj',
        lt: 'Aktyvavimas',
        ro: 'Activați',
        bg: 'Активиране',
        lv: 'Aktivizācija',
    },
    languageActivationInfoFirst: {
        pl: 'Nie masz kodu?',
        cs: 'Nemáte kód?',
        sk: 'Nemáte kód?',
        en: "Don't have a code?",
        hu: 'Nincs kódod?',
        hr: 'Nemaš kod?',
        lt: 'Neturite kodo?',
        ro: 'Nu aveți un cod?',
        bg: 'Нямате код?',
        lv: 'Nav koda?',
    },
    languageActivationInfoSecond: {
        pl: 'Napisz do nas na maila: kontakt@elingo-edu.com',
        cs: 'Napište nám na adresu: kzs@elingo-edu.com',
        sk: 'Napíšte nám na: ksz@elingo-edu.com',
        en: 'Write to us at:',
        hu: 'Írj nekünk: kapcsolat@elingo-edu.com',
        hr: 'Pišite nam na e-mail: kontakt@elingo-edu.com',
        lt: 'Parašykite mums adresu:',
        ro: 'Scrieți-ne pe e-mail: bsc@elingo-edu.com',
        bg: 'Свържете се с вашия съветник, който ще ви изпрати кода за активиране',
        lv: 'Sazinieties ar savu padomdevēju, kurš jums nosūtīs aktivizācijas kodu',
    },
    contactText: {
        pl: 'W razie problemów prosimy o kontakt:',
        cs: 'V případě jakýchkoli problémů se prosím obraťte na:',
        sk: 'V prípade akýchkoľvek problémov nás prosím kontaktujte:',
        en: 'If you have any problems please contact us:',
        hu: 'Ha bármilyen problémája van, forduljon hozzánk:',
        hr: 'U slučaju problema, kontaktirajte nas:',
        lt: 'Jei kyla problemų, susisiekite su mumis:',
        ro: 'Dacă aveți probleme, vă rugăm să ne contactați:',
        bg: 'Ако имате проблеми, моля свържете се с нас:',
        lv: 'Ja jums ir kādas problēmas, lūdzu, sazinieties ar mums:',
    },
    contactEmail: {
        pl: 'ksz@elingo-edu.com',
        cs: 'kzs@elingo-edu.com',
        sk: 'ksz@elingo-edu.com',
        en: 'ksz@elingo-edu.com',
        hu: 'kapcsolat@elingo-edu.com',
        hr: 'ks@elingo-edu.com',
        lt: 'kas@elingo-edu.com',
        ro: 'bsc@elingo-edu.com',
        bg: 'support.bg@elingo-edu.com',
        lv: 'klientuinfo@elingo-edu.com',
    },
    certButtonText: {
        pl: 'Certyfikat',
        cs: 'Certifikát',
        sk: 'Certifikát',
        en: 'Certificate',
        hu: 'Certificate',
        hr: 'Certifikat',
        lt: 'Sertifikatas',
        ro: 'Certificat',
        bg: 'Сертификат',
        lv: 'Sertifikāts',
    },
    certText: {
        pl: 'Ukończyłeś cały kurs i chcesz uzyskać certyfikat? Napisz do nas:',
        cs: 'Absolvovali jste celý kurz a chcete získat certifikát? Napište nám na adresu:',
        sk: 'Absolvovali ste celý kurz a chcete získať certifikát? Napíšte nám na:',
        en: 'Have you completed the entire course and want to get a certificate? Write to us at:',
        hu: 'Végigcsináltad a teljes tanfolyamot és szeretnél egy tanúsítványt? Írj nekünk:',
        hr: 'Završio/la si cijeli tečaj i želiš certifikat? Piši nam:',
        lt: 'Baigėte visą kursą ir norite gauti sertifikatą? Rašykite mums adresu:',
        ro: 'Ați finalizat întregul curs și doriți să obțineți un certificat? Scrieți-ne la:',
        bg: 'Завършихте целия курс и искате да получите сертификат? Пишете ни на:',
        lv: 'Vai esat pabeidzis visu kursu un vēlaties saņemt sertifikātu? Rakstiet mums uz:',
    },
    certEmail: {
        pl: 'bok@elingo-edu.com',
        cs: 'kzs@elingo-edu.com',
        sk: 'ksz@elingo-edu.com',
        en: 'csc@elingo-edu.com',
        hu: 'kapcsolat@elingo-edu.com',
        hr: 'bok@elingo-edu.com',
        lt: 'bok@elingo-edu.com',
        ro: 'bsc@elingo-edu.com',
        bg: 'certificate@elingo-edu.com',
        lv: 'sertifikats@elingo-edu.com',
    },
    properAnswer: {
        pl: 'Poprawna odpowiedź:',
        cs: 'Správná odpověď:',
        sk: 'Správna odpoveď:',
        en: 'Correct answer:',
        hu: 'Helyes válasz:',
        hr: 'Točan odgovor:',
        lt: 'Teisingas atsakymas:',
        ro: 'Răspuns corect:',
        bg: 'Правилен отговор:',
        lv: 'Pareizā atbilde:',
    },
    lessonModalHeader: {
        pl: 'Świetnie!',
        cs: 'Skvělé!',
        sk: 'Skvelé!',
        en: 'Great!',
        hu: 'Nagyszerű!',
        hr: 'Odlično!',
        lt: 'Puiku!',
        ro: 'Foarte bine!',
        bg: 'Чудесно!',
        lv: 'Lieliski!',
    },
    lessonModalText: {
        pl: 'Koniec lekcji',
        cs: 'Lekci jste dokončili',
        sk: 'Ukončili ste lekciu',
        en: 'You have completed the lesson',
        hu: 'Befejezted a leckét',
        hr: 'Kraj lekcije',
        lt: 'Baigėte pamoką',
        ro: 'Ați finalizat lecția',
        bg: 'Завършихте урока',
        lv: 'Jūs esat pabeidzis nodarbību',
    },
    lessonModalNextLessonText: {
        pl: 'Dalej',
        cs: 'Další',
        sk: 'Ďalej',
        en: 'Next',
        hu: 'Következő',
        hr: 'Dalje',
        lt: 'Kitas',
        ro: 'Următorul',
        bg: 'Напред',
        lv: 'Nākamais',
    },
    lessonModalToAllLessonText: {
        pl: 'Wszystkie lekcje',
        cs: 'Všechny lekce',
        sk: 'Všetky lekcie',
        en: 'All lessons',
        hu: 'Összes lecke',
        hr: 'Sve lekcije',
        lt: 'Visos pamokos',
        ro: 'Toate lecțiile',
        bg: 'Всички уроци',
        lv: 'Visas nodarbības',
    },
    tutorialFirstPointText: {
        pl: 'Ucz się, klikając w duży przycisk "Kontynuuj naukę". To takie proste!',
        cs: 'Učení vždy začněte kliknutím na velké tlačítko "Pokracovat v uceni". Je to tak jednoduché!',
        sk: 'Učenie vždy začnite kliknutím na veľké tlačidlo "Pokračovať v učení". Je to tak jednoduché!',
        en: 'Always start learning by clicking on the big "Pokracovat v uceni" button. It\'s that simple!',
        hu: 'A tanulást mindig a „Pokracovat v uceni” nagy gombra kattintva kezd el. Ennyire egyszerű!',
        hr: 'Uči klikom na veliki gumb "Nastavi učiti". To je tako jednostavno!',
        lt: 'Visada pradėkite mokytis paspaudę didelį mygtuką "Pokracovat v uceni". Tai taip paprasta!',
        ro: 'Întotdeauna începe să înveți apasând pe butonul mare „Continuați să învățați  ”. Acest lucru e atât de simplu!',
        bg: 'Винаги започвайте да учите, като щракнете върху големия бутон "Покračovat v uceni". Толкова е просто!',
        lv: 'Vienmēr sāciet mācīties, noklikšķinot uz lielās pogas "Pokracovat v uceni". Tas ir tik vienkārši!',
    },
    tutorialSecondPointText: {
        pl: 'Chcesz zapoznać się z innymi lekcjami? Po klinknięciu w przycisk "Wszystkie lekcje" zapoznasz się ze szczegółowym planem.',
        cs: 'Chcete se seznámit s dalšími lekcemi? Po kliknutí na "Vsechny lekce" si můžete prohlédnout podrobný jízdní řád.',
        sk: 'Chcete sa naučiť ďalšie lekcie? Kliknutím na "Všetky lekcie" zobrazíte podrobný rozvrh.',
        en: 'Do you want to get to know other lessons? After clicking on "Vsechny lekce" you can see the detailed schedule.',
        hu: 'Szeretnél megismerkedni más leckékkel? A „Vsechny lekce” gombra kattintva megtekintheted a részletes menetrendet.',
        hr: 'Želiš se upoznati s drugim lekcijama? Klikni na gumb "Sve lekcije" za detaljni plan.',
        lt: 'Ar norite susipažinti su kitomis pamokomis? Paspaudę "Vsechny lekce" galite pamatyti išsamų tvarkaraštį.',
        ro: 'Dorești să cunoști alte lecții? După ce ai apăsat pe buton „Toate lecțiile ” poți vedea planul detaliat.',
        bg: 'Искате ли да се запознаете с други уроци? След като щракнете върху "Vsechny lekce", можете да видите подробния график.',
        lv: 'Vai vēlaties iepazīties ar citām nodarbībām? Pēc noklikšķināšanas uz "Vsechny lekce" varat redzēt detalizētu grafiku.',
    },
    tutorialThirdPointText: {
        pl: 'W tym miejscu poznasz dokładny plan lekcji. Jeśli chcesz, możesz tu zmienić poziom nauki, a także zapoznać się z dodatkowymi materiałami.',
        cs: 'Zde najdete přesný jízdní řád. Pokud si přejete, můžete zde změnit úroveň lekcí a také si prohlédnout další materiály.',
        sk: 'Tu nájdete presný rozvrh. Ak chcete, môžete si tu zmeniť úroveň lekcií a prezrieť si ďalšie materiály.',
        en: 'Here you will find the exact schedule of lessons. If you wish, you can change the level of lessons here, and also view additional materials.',
        hu: 'Itt találod a leckék pontos menetrendjét. Ha szeretnéd, itt megváltoztathatod a leckék szintjét, és megtekintheted a további anyagokat.',
        hr: 'Ovdje ćeš saznati točan plan lekcija. Ako želiš, ovdje možeš promijeniti razinu učenja, kao i upoznati se s dodatnim materijalima.',
        lt: 'Čia rasite tikslų pamokų tvarkaraštį. Jei norite, čia galite keisti pamokų lygį, taip pat peržiūrėti papildomą medžiagą.',
        ro: 'În acest loc vei afla orarul exact lecțiilor. Dacă dorești, poți modifica aici nivelul de învățământ, totodată vei putea observa materiale suplimentare.',
        bg: 'Тук ще намерите точния график на уроците. Ако желаете, можете да промените нивото на уроците тук и да прегледате допълнителни материали.',
        lv: 'Šeit jūs atradīsiet precīzu nodarbību grafiku. Ja vēlaties, šeit varat mainīt nodarbību līmeni, kā arī apskatīt papildu materiālus.',
    },
    tutorialFourthPointText: {
        pl: 'Chcesz zmienić poziom nauki? Zadania są dla Ciebie zbyt proste, albo zbyt trudne? Wystarczy, że klikniesz w wybrany przez siebie poziom w tym miejscu.',
        cs: 'Chcete změnit úroveň učení? Jsou pro vás úkoly příliš snadné nebo příliš obtížné? Stačí kliknout na vybranou úroveň.',
        sk: 'Chcete zmeniť úroveň vzdelávania? Sú pre vás úlohy príliš ľahké alebo príliš ťažké? Stačí kliknúť na vybranú úroveň.',
        en: 'Do you want to change the level of learning? Are the tasks too easy or too difficult for you? Just click on the desired level here.',
        hu: 'Szeretnéd megváltoztatni a leckék szintjét? A feladatok túl könnyűek vagy túl nehézek számodra? Csak kattints a kívánt szintre.',
        hr: 'Želiš promijeniti razinu učenja? Zadaci su ti prelagani ili preteški? Samo klikni na razinu koju odabereš ovdje.',
        lt: 'Ar norite pakeisti mokymosi lygį? Ar užduotys jums per lengvos, ar per sunkios? Tiesiog spustelėkite norimą lygį čia.',
        ro: 'Ai dori să schimbi nivelul de învățământ? Sarcinile sunt prea simple sau prea dificile pentru tine? Trebuie numai să apesi butonul pe nivelul dorit în acest loc.',
        bg: 'Искате ли да промените нивото на обучение? Задачите са твърде лесни или твърде трудни за вас? Просто кликнете върху желаното ниво тук.',
        lv: 'Vai vēlaties mainīt mācību līmeni? Uzdevumi ir pārāk viegli vai pārāk grūti? Vienkārši noklikšķiniet šeit uz vēlamā līmeņa.',
    },
    tutorialFifthPointText: {
        pl: 'Okno Twojej aktywności pokazuje, jak przebiega Twój proces nauki. Systematyczna nauka sprawi że osiągniesz wymarzone rezultaty jeszcze szybciej.',
        cs: 'Okno s aktivitami vám ukazuje, jak probíhá vaše učení. Pokud budete studovat systematicky, dosáhnete vysněných výsledků ještě rychleji.',
        sk: 'V okne aktivít sa zobrazuje priebeh učenia. Ak budete študovať systematicky, dosiahnete svoje vysnívané výsledky ešte rýchlejšie.',
        en: 'Your activity window shows how your learning process is going. Systematic learning will make you achieve your dream results even faster.',
        hu: 'Az aktivitásablak mutatja, hogyan halad a tanulási folyamatod. A rendszeres tanulásnak köszönhetően még gyorsabban elérheted a kívánt eredményeket.',
        hr: 'Prozor tvoje aktivnosti pokazuje kako napreduje tvoj proces učenja. Sustavno učenje će ti pomoći da postigneš željene rezultate još brže.',
        lt: 'Jūsų veiklos lange rodoma, kaip vyksta mokymosi procesas. Sistemingai mokydamiesi galėsite dar greičiau pasiekti svajonių rezultatų.',
        ro: 'Fereastra ta de activitate arată cum decurge procesul tău de învățământ. Cu ajutorul învăţării sistematice vei obține rezultatele visate cât mai repede.',
        bg: 'Прозорецът с вашите дейности показва как протича процесът на обучение. Систематичното учене ще ви помогне да постигнете мечтаните резултати още по-бързо.',
        lv: 'Jūsu aktivitāšu logs parāda, kā norit jūsu mācību process. Sistemātiska mācīšanās palīdzēs jums ātrāk sasniegt vēlamo rezultātu.',
    },
    tutorialSixthPointText: {
        pl: 'W tym miejscu widzisz, jakiego języka aktualnie się uczysz. Jeśli posiadasz dostęp do innych kursów, po kliknięciu w “Aktywuj kolejny kurs >>” możesz wybrać inny język do nauki.',
        cs: 'Zde se můžete podívat, který jazyk se právě učíte. Pokud máte přístup k dalším kurzům, můžete si vybrat další jazyk, který se chcete naučit, kliknutím na "další kurzy >>".',
        sk: 'Tu môžete vidieť, ktorý jazyk sa práve učíte. Ak máte prístup k iným kurzom, môžete si vybrať ďalší jazyk, ktorý sa chcete naučiť, kliknutím na "ďalšie kurzy >>".',
        en: 'Here you can see what language you are currently learning. If you have access to other courses, you can choose another language to learn by clicking on "other courses >>".',
        hu: 'Itt láthatod, milyen nyelvet tanulsz éppen. Ha hozzáférésed van más kurzusokhoz, akkor a „további kurzusok >>” gombra kattintva választhatsz másik nyelvet, amit szeretnél megtanulni.',
        hr: 'Ovdje vidiš koji jezik trenutno učiš. Ako imaš pristup drugim tečajevima, klikom na “Aktiviraj sljedeći tečaj >>” možeš odabrati drugi jezik za učenje.',
        lt: 'Čia galite matyti, kokios kalbos šiuo metu mokotės. Jei turite prieigą prie kitų kursų, galite pasirinkti kitą kalbą, kurios norite mokytis, spustelėję "kiti kursai >>".',
        ro: 'În acest loc vezi ce limbă înveți în prezent. Dacă ai acces la alte cursuri, după apăsarea pe butonul “ alte cursuri >>” poți alege altă limbă care ai să înveți.',
        bg: 'Тук можете да видите кой език в момента учите. Ако имате достъп до други курсове, можете да изберете друг език, който да научите, като щракнете върху „други курсове >>”.',
        lv: 'Šeit jūs varat redzēt, kādu valodu jūs pašlaik mācāties. Ja jums ir piekļuve citiem kursiem, varat izvēlēties citu valodu, kuru vēlaties mācīties, noklikšķinot uz "citi kursi >>".',
    },
    tutorialSeventhPointText: {
        pl: 'Chcesz nauczyć się kolejnego języka? Wpisz tutaj kod aktywacyjny nowego języka.',
        cs: 'Chcete se naučit další jazyk? Zde zadejte aktivační kód pro nový jazyk.',
        sk: 'Chcete sa naučiť ďalší jazyk? Tu zadajte aktivačný kód pre nový jazyk.',
        en: 'Do you want to learn another language? Enter the activation code for the new language here.',
        hu: 'Szeretnél megtanulni egy újabb nyelvet? Írd be ide az új nyelv aktiválókódját.',
        hr: 'Želiš naučiti još jedan jezik? Ovdje unesi aktivacijski kod novog jezika.',
        lt: 'Ar norite išmokti kitą kalbą? Čia įveskite naujos kalbos aktyvavimo kodą.',
        ro: 'Dorești să înveți mai o limbă? Introduce aici codul de activare pentru noua limbă.',
        bg: 'Искате ли да научите друг език? Въведете кода за активиране на новия език тук.',
        lv: 'Vai vēlaties iemācīties citu valodu? Šeit ievadiet jaunās valodas aktivizācijas kodu.',
    },
    tutorialEighthPointText: {
        pl: 'Każdego dnia, dowiedz się czegoś nowego! Aby nauka nie stała się nudną rutyną, zebraliśmy dla Ciebie ciekawostki o krajach, których kursy są dostępne na eLingo.',
        cs: 'Každý den se naučte něco nového! Aby se učení nestalo nudnou rutinou, shromáždili jsme pro vás zajímavosti o zemích, jejichž kurzy jsou na eLingou k dispozici.',
        sk: 'Každý deň sa naučte niečo nové! Aby sa učenie nestalo nudnou rutinou, zozbierali sme zaujímavé fakty o krajinách, ktorých kurzy sú dostupné na portáli eLingo.',
        en: 'Learn something new every day! To make learning not become a boring routine, we have collected interesting facts about the countries whose courses are available on eLingo.',
        hu: 'Minden nap tanulj meg valami újat! Hogy a tanulás ne váljon unalmas rutinná, összegyűjtöttünk neked néhány érdekességet azokról az országokról, amelyek nyelvtanfolyamai elérhetőek az eLingo.',
        hr: 'Svaki dan nauči nešto novo! Da učenje ne postane dosadna rutina, prikupili smo za tebe zanimljivosti o zemljama čiji su tečajevi dostupni na eLingo.',
        lt: 'Kasdien išmokite ką nors naujo! Kad mokymasis netaptų nuobodžia rutina, surinkome įdomių faktų apie šalis, kurių kursai prieinami "eLingo".',
        ro: 'În fiecare zi, află ceva nou! Pentru ca învățământul să nu devină o rutină plictisitoare, am adunat pentru tine informații interesante despre țările ale căror cursuri sunt disponibile pe eLingo.',
        bg: 'Научавайте нещо ново всеки ден! За да не стане ученето скучна рутина, събрахме интересни факти за страните, чиито курсове са налични в eLingo.',
        lv: 'Katru dienu iemācieties kaut ko jaunu! Lai mācīšanās nekļūtu par garlaicīgu rutīnu, mēs esam savākuši interesantus faktus par valstīm, kuru kursi ir pieejami vietnē eLingo.',
    },
    tutorialNinthPointText: {
        pl: 'Po kliknięciu na symbol 3 poziomych kresek w prawym górnym rogu ekranu, rozwinie się panel użytkownika. Jest to miejsce, w którym uzyskasz dostęp do najważniejszych funkcji, takich jak zmiana hasła, czy wylogowanie. Możesz tu także sprawdzić swoją aktywność, albo uzyskać informacje o kontakcie lub certyfikacie.',
        cs: 'Pokud kliknete na symbol 3 vodorovných čárek v pravém horním rohu obrazovky, rozbalí se uživatelský panel. Zde máte přístup k nejdůležitějším funkcím, jako je změna hesla nebo odhlášení. Zde můžete také zkontrolovat svou aktivitu nebo získat kontaktní informace či certifikát.',
        sk: 'Ak kliknete na symbol 3 vodorovných čiar v pravom hornom rohu obrazovky, používateľský panel sa rozbalí. Tu máte prístup k najdôležitejším funkciám, ako je zmena hesla alebo odhlásenie. Tu si tiež môžete skontrolovať svoju aktivitu alebo získať svoje kontaktné informácie či certifikát.',
        en: 'After clicking on the button on the symbol of 3 horizontal lines in the upper right corner of the screen, the navigation panel will open. Here you will get access to the most important functions such as changing the password or logging out. You can also check your activity, or get contact information or a certificate.',
        hu: 'Ha rákattintasz a képernyő jobb felső sarkában található 3 vízszintes vonalra, akkor megnyílik a felhasználói panel. Itt éred el a legfontosabb funkciókat, mint például a jelszó megváltoztatása vagy a kijelentkezés. Itt ellenőrizheted a tevékenységedet, vagy megnézheted a kapcsolatfelvételi információkat vagy a tanúsítványt.',
        hr: 'Klikom na simbol 3 vodoravne crte u gornjem desnom kutu ekrana, otvorit će se korisnički panel. To je mjesto gdje možeš pristupiti najvažnijim funkcijama, kao što su promjena lozinke ili odjava. Ovdje možeš također provjeriti svoju aktivnost ili dobiti informacije o kontaktu ili certifikatu.',
        lt: 'Paspaudus mygtuką, esantį ant 3 horizontalių linijų simbolio viršutiniame dešiniajame ekrano kampe, atsidarys naršymo skydelis. Čia galėsite naudotis svarbiausiomis funkcijomis, tokiomis kaip slaptažodžio keitimas ar atsijungimas. Taip pat galite patikrinti savo veiklą, gauti kontaktinę informaciją arba sertifikatą.',
        ro: 'După ce vei apăsa buton pe simbolul a 3 linii orizontale din colțul din dreapta sus al ecranului, se va deschide panoul de navigare. Aici vei obține acces la cele mai importante funcții precum schimbarea parolei sau deconectarea. Poți verifica activitatea personal, sau obține informații despre un contact sau un certificat.',
        bg: 'След като щракнете върху символа с 3 хоризонтални линии в горния десен ъгъл на екрана, ще се отвори навигационният панел. Тук ще получите достъп до най-важните функции като смяна на паролата или излизане. Можете също да проверите дейността си или да получите информация за контакт или сертификат.',
        lv: 'Pēc noklikšķināšanas uz pogas ar 3 horizontālo līniju simbolu ekrāna augšējā labajā stūrī atvērsies navigācijas panelis. Šeit jūs iegūsit piekļuvi vissvarīgākajām funkcijām, piemēram, paroles maiņai vai atteikšanās. Varat arī pārbaudīt savu darbību vai iegūt kontaktinformāciju vai sertifikātu.',
    },
    tutorialPointTitleLesson: {
        pl: 'Lekcja',
        cs: 'Lekce',
        sk: 'Lekcia',
        en: 'Lesson',
        hu: 'Lecke',
        hr: 'Lekcija',
        lt: 'Pamokos',
        ro: 'Lecția',
        bg: 'Урок',
        lv: 'Nodarbība',
    },
    tutorialLessonFirstPointText: {
        pl: 'Na samej górze widzisz poziom nauki, tytuł lekcji oraz jej numer',
        cs: 'Úplně nahoře vidíte úroveň učení, název lekce a číslo lekce.',
        sk: 'Úplne hore vidíte úroveň učenia, názov lekcie a číslo lekcie.',
        en: 'At the very top you can see the learning level, lesson title and lesson number',
        hu: 'Legfelül láthatja a tanulási szintet, a lecke címét és az óra számát',
        hr: 'Na vrhu vidiš razinu učenja, naslov lekcije i njezin broj',
        lt: 'Pačiame viršuje matote mokymosi lygį, pamokos pavadinimą ir pamokos numerį.',
        ro: 'Pe partea care este mai sus poți vedea nivelul de învățământ, titlul și numărul lecției',
        bg: 'Най-отгоре можете да видите нивото на обучение, заглавието на урока и номера на урока',
        lv: 'Pašā augšā varat redzēt mācību līmeni, nodarbības nosaukumu un nodarbības numuru',
    },
    tutorialLessonSecondPointText: {
        pl: 'Każde z zadań zawiera krótkie polecenie, dzięki któremu wiesz, co robić.',
        cs: 'Každý úkol obsahuje krátký příkaz, abyste věděli, co máte udělat.',
        sk: 'Každá úloha obsahuje krátky príkaz, aby ste vedeli, čo máte urobiť.',
        en: 'Each task has a short command so you know what to do.',
        hu: 'Minden feladathoz tartozik egy rövid parancs, így tudja, mit kell tennie.',
        hr: 'Svaki zadatak sadrži kratke upute, tako da znaš što trebaš raditi.',
        lt: 'Kiekviena užduotis turi trumpą komandą, kad žinotumėte, ką daryti.',
        ro: 'Fiecare sarcină are o scurtă comandă care  spune ce trebuie de făcut.',
        bg: 'Всяка задача има кратка команда, за да знаете какво да правите.',
        lv: 'Katram uzdevumam ir īsa komanda, lai jūs zinātu, kas jādara.',
    },
    tutorialLessonThirdPointText: {
        pl: 'Na samym dole znajduje się Twój pasek postępu. Zauważ, że każdy z jego kolorów oznacza coś innego:',
        cs: 'Úplně dole se nachází ukazatel průběhu. Všimněte si, že každá z jeho barev znamená něco jiného:',
        sk: 'Úplne dole sa nachádza ukazovateľ priebehu. Všimnite si, že každá z jeho farieb znamená niečo iné:',
        en: 'At the very bottom is your progress bar. Notice that each of its colors means something else:',
        hu: 'Lent láthatja a haladási sávját. Vegye észre, hogy mindegyik színe mást jelent:',
        hr: 'Na dnu se nalazi tvoja traka napretka. Primijeti da svaka njena boja označava nešto drugo:',
        lt: 'Pačioje apačioje yra jūsų pažangos juosta. Atkreipkite dėmesį, kad kiekviena jos spalva reiškia ką nors kita:',
        ro: 'Pe partea care este mai jos se află bara ta de progres. Reține, că fiecare dintre culorile  înseamnă altceva:',
        bg: 'Най-отдолу е лентата за напредък. Обърнете внимание, че всеки от нейните цветове означава нещо различно:',
        lv: 'Pašā apakšā ir jūsu progresa josla. Pamaniet, ka katra no tās krāsām nozīmē kaut ko citu:',
    },
    tutorialLessonNestedListFirstPointText: {
        pl: 'zielony - dobrze rozwiązane zadanie',
        cs: 'zelený - dobře vyřešený úkol',
        sk: 'zelený - dobre vyriešená úloha',
        en: 'green - well solved task',
        hu: 'zöld - jól megoldott feladat',
        hr: 'zeleno - zadatak riješen ispravno',
        lt: 'žalia - gerai išspręsta užduotis',
        ro: 'verde - o sarcină bine rezolvată',
        bg: 'зелено - добре решена задача',
        lv: 'zaļa - labi atrisināts uzdevums',
    },
    tutorialLessonNestedListSecondPointText: {
        pl: 'czerwony - źle rozwiązane zadanie (wrócisz do niego pod koniec lekcji)',
        cs: 'červený - špatně vyřešená úloha (vrátíte se k ní na konci lekce).',
        sk: 'červený - zle vyriešená úloha (vrátite sa k nej na konci hodiny)',
        en: 'red - incorrectly solved task (you will return to it at the end of the lesson)',
        hu: 'piros - rosszul megoldott feladat (visszatér hozzá a lecke végén)',
        hr: 'crveno - zadatak riješen neispravno (vratit ćeš se na njega na kraju lekcije)',
        lt: 'raudona - neteisingai išspręsta užduotis (prie jos grįšite pamokos pabaigoje)',
        ro: 'roșu - o sarcină incorect rezolvată (vei reveni la ea la sfârșitul lecției)',
        bg: 'червено - неправилно решена задача (ще се върнете към нея в края на урока)',
        lv: 'sarkans - nepareizi atrisināts uzdevums (jūs pie tā atgriezīsieties nodarbības beigās)',
    },
    tutorialLessonNestedListThirdPointText: {
        pl: 'żółty - miejsce, w którym aktualnie się znajdujesz',
        cs: 'žlutý - kde se právě nacházíte',
        sk: 'žltý - kde sa práve nachádzate',
        en: 'yellow - where you are now',
        hu: 'sárga - hol tart most',
        hr: 'žuto - mjesto na kojem se trenutno nalaziš',
        lt: 'geltona - kur esate dabar',
        ro: 'galben – unde te afli în acest moment',
        bg: 'жълто - къде се намирате сега',
        lv: 'dzeltens - kur jūs tagad esat',
    },
    tutorialTenthPointText: {
        pl: 'Na końcu każdego poziomu czeka na Ciebie test, który sprawdzi Twoją wiedzę z zakresu danego poziomu. Aby do niego przystąpić musisz mieć zaliczone wszystkie lekcje z tego poziomu.',
        cs: 'Na konci každé úrovně vás čeká test, který prověří vaše znalosti dané úrovně. Abyste to mohli začít, musíte absolvovat všechny lekce z této úrovně.',
        sk: 'Na konci každej úrovne vás čaká test, ktorý preverí vaše znalosti danej úrovne. Aby ste to mohli začať, musíte absolvovať všetky lekcie z tejto úrovne.e',
        en: 'At the end of each level, a test awaits you to test your knowledge of that level. To start it, you must have completed all the lessons from this level.',
        hu: 'Minden szint végén egy teszt vár Önre, amely ellenőrzi a tudását az adott szintről. Hogy hozzájuthasson, teljesítenie kell az összes leckét ezen a szinten.',
        hr: 'Na kraju svake razine čeka te test koji će provjeriti tvoje znanje iz te razine. Da bi pristupio testu, moraš završiti sve lekcije te razine.',
        lt: 'Kiekvieno lygio pabaigoje jūsų laukia testas, kuriuo patikrinsite savo žinias apie tą lygį. Norėdami pradėti, turite būti baigę visas šio lygio pamokas.',
        ro: 'La sfârșitul fiecărui nivel, te așteaptă un test pentru a-ți testa cunoștințele despre acel nivel. Pentru a participa la el, trebuie să fi finalizat toate lecțiile de la acest nivel.',
        bg: 'В края на всяко ниво ви очаква тест, който ще провери знанията ви за това ниво. За да започнете, трябва да сте завършили всички уроци от това ниво.',
        lv: 'Katra līmeņa beigās jūs gaida tests, lai pārbaudītu jūsu zināšanas par šo līmeni. Lai to sāktu, ir jāpabeidz visas šī līmeņa nodarbības.',
    },
    testLessonScreenText: {
        pl: 'Sprawdź swoją wiedzę z poziomu',
        cs: 'Otestujte si své znalosti od úrovně',
        sk: 'Otestujte si svoje znalosti od úrovne',
        en: 'Test your knowledge from level',
        hu: 'Tesztelje tudását a szintről',
        hr: 'Provjeri svoje znanje iz razine',
        lt: 'Patikrinkite savo žinias iš lygio',
        ro: 'Testează-ți cunoștințele de la nivelu',
        bg: 'Тествайте знанията си от ниво',
        lv: 'Pārbaudiet savas zināšanas no līmeņa',
    },
    testLessonScreenButtonText: {
        pl: 'wypełnij test',
        cs: 'vyplňte test',
        sk: 'vyplňte test',
        en: 'fill out the test',
        hu: 'töltse ki a tesztet',
        hr: 'ispuni test',
        lt: 'užpildykite testą',
        ro: 'completează formularul',
        bg: 'попълнете теста',
        lv: 'aizpildiet testu',
    },
    testMainHeader: {
        pl: 'Test wiedzy - poziom',
        cs: 'Test znalostí - úroveň',
        sk: 'Vedomostný test - úroveň',
        en: 'Knowledge test - level',
        hu: 'Tudásteszt - szint',
        hr: 'Test znanja - razina',
        lt: 'Žinių testas – lygis',
        ro: 'Test de cunoștințe - nivel',
        bg: 'Тест за знания - ниво',
        lv: 'Zināšanu tests - līmenis',
    },
    testRulesHeader: {
        pl: 'Zasady testu wiedzy',
        cs: 'Pravidla znalostního testu',
        sk: 'Pravidlá znalostného testu',
        en: 'Knowledge test rules',
        hu: 'A tudásteszt szabályai',
        hr: 'Pravila testa znanja',
        lt: 'Žinių testo taisyklės',
        ro: 'Hodně štěstí!',
        bg: 'Правила за тест за знания',
        lv: 'Zināšanu testa noteikumi',
    },
    testWelcome: {
        pl: 'Cześć',
        cs: 'Ahoj',
        sk: 'Ahoj',
        en: 'Hi',
        hu: 'Szia',
        hr: 'Bok',
        lt: 'Sveiki',
        ro: 'Bună',
        bg: 'Здравей',
        lv: 'Sveiki',
    },
    testDesc: {
        pl: 'W tym miejscu możesz rozwiązać test wiedzy ze wszystkich poznanych słówek na poziomie {{level}}. Do każdego z zadań masz możliwość podejść tylko jeden raz. Pod koniec testu zostanie wyświetlony Twój wynik. Do testu możesz podchodzić wielokrotnie, by poprawić swój wynik.',
        cs: 'Zde můžete vyřešit znalostní test všech slov, která jste se naučili na úrovni {{level}}. Každý úkol můžete udělat pouze jednou. Na konci testu se zobrazí váš výsledek. Test můžete absolvovat vícekrát, abyste zlepšili své skóre.',
        sk: 'Tu môžete vyriešiť znalostný test všetkých slov, ktoré ste sa naučili na úrovni {{level}}. Každú úlohu môžete urobiť iba raz. Na konci testu sa zobrazí váš výsledok. Test môžete absolvovať viackrát, aby ste zlepšili svoje skóre.',
        en: 'Here you can solve the knowledge test of all the words you have learned at level {{level}}. You can do each task only once. At the end of the test, your result will be displayed. You can take the test multiple times to improve your score.',
        hu: 'Itt megoldhatja a tudástesztet az összes szóra, amelyet a {{level}} szinten tanult. Minden feladatot csak egyszer végezhet el. A teszt végén megjelenik az eredménye. A tesztet többször is elvégezheti, hogy javítsa a pontszámát.',
        hr: 'Ovdje možeš riješiti test znanja iz svih naučenih riječi razine {{level}}. Za svaki zadatak imaš priliku pristupiti samo jednom. Na kraju testa bit će prikazan tvoj rezultat. Testu možeš pristupiti više puta kako bi poboljšao/la svoj rezultat.',
        lt: 'Čia galite išspręsti visų žodžių, kuriuos išmokote {{level}} lygiu, žinių patikrinimą. Kiekvieną užduotį galite atlikti tik vieną kartą. Testo pabaigoje bus rodomas jūsų rezultatas. Galite atlikti testą kelis kartus, kad pagerintumėte savo rezultatą.',
        ro: 'Aici puteți rezolva testul de cunoștințe al tuturor cuvintelor pe care le-ați învățat la nivelul {{level}}. Puteți face fiecare sarcină o singură dată. La sfârșitul testului, rezultatul dvs. va fi afișat. Puteți susține testul de mai multe ori pentru a vă îmbunătăți scorul.',
        bg: 'Тук можете да решите теста за знания на всички думи, които сте научили на ниво {{level}}. Всяка задача можете да изпълните само веднъж. В края на теста ще се покаже вашият резултат. Можете да направите теста няколко пъти, за да подобрите резултата си.',
        lv: 'Šeit varat atrisināt zināšanu testu par visiem vārdiem, ko esat apguvis līmenī {{level}}. Katru uzdevumu var izpildīt tikai vienu reizi. Testa beigās tiks parādīts jūsu rezultāts. Varat veikt testu vairākas reizes, lai uzlabotu savu rezultātu.',
    },
    testGoodLuck: {
        pl: 'Powodzenia!',
        cs: 'Hodně štěstí!',
        sk: 'Veľa šťastia!',
        en: 'Good luck!',
        hu: 'Sok szerencsét!',
        hr: 'Sretno!',
        lt: 'Sėkmės!',
        ro: 'Mult noroc!',
        bg: 'Късмет!',
        lv: 'Veiksmi!',
    },
    testStartBtn: {
        pl: 'Wypełnij test',
        cs: 'Dokončit test',
        sk: 'Dokončite test',
        en: 'Complete the test',
        hu: 'Teszt kitöltése',
        hr: 'Ispuni test',
        lt: 'Atlikite testą',
        ro: 'Finaliza testul',
        bg: 'Попълнете теста',
        lv: 'Pabeidziet testu',
    },
    testResultHeader: {
        pl: 'Wynik testu',
        cs: 'Výsledek testu',
        sk: 'Výsledok testu',
        en: 'Test result',
        hu: 'Teszteredmény',
        hr: 'Rezultat testa',
        lt: 'Testo rezultatai',
        ro: 'Rezultatul testului',
        bg: 'Резултат от теста',
        lv: 'Testa rezultāts',
    },
    testActivitiesCompleted: {
        pl: 'Ilość dobrze zrobionych zadań:',
        cs: 'Počet dobře provedených úkolů:',
        sk: 'Počet dobre vykonaných úloh:',
        en: 'Number of tasks done well:',
        hu: 'A feladatok száma:',
        hr: 'Broj ispravno riješenih zadataka:',
        lt: 'Gerai atliktų užduočių skaičius:',
        ro: 'Număr de sarcini bine realizate:',
        bg: 'Брой добре изпълнени задачи:',
        lv: 'Labi paveikto uzdevumu skaits:',
    },
    testRepeatDesc: {
        pl: 'Jeśli chcesz możesz podejść do testu jeszcze raz i poprawić swój wynik.',
        cs: 'Chcete-li, můžete test absolvovat znovu a zlepšit svůj výsledek.',
        sk: 'Ak chcete, môžete test absolvovať znova a zlepšiť svoj výsledok.',
        en: 'If you want, you can take the test again and improve your score.',
        hu: 'Ha szeretné, megismételheti a tesztet, és javíthatja az eredményét.',
        hr: 'Ako želiš, možeš pristupiti testu ponovno i poboljšati svoj rezultat.',
        lt: 'Jei norite, galite dar kartą atlikti testą ir pagerinti savo rezultatą.',
        ro: 'Dacă doriți, puteți să faceți din nou testul și să vă îmbunătățiți rezultatul.',
        bg: 'Ако искате, можете да направите теста отново и да подобрите резултата си.',
        lv: 'Ja vēlaties, varat veikt testu vēlreiz un uzlabot savu rezultātu.',
    },
    testBestScore: {
        pl: 'Twój najlepszy wynik:',
        cs: 'Váš nejlepší výsledek:',
        sk: 'Váš najlepší výsledok:',
        en: 'Your best score:',
        hu: 'Legjobb eredménye:',
        hr: 'Tvoj najbolji rezultat:',
        lt: 'Jūsų geriausias rezultatas:',
        ro: 'Cel mai bun rezultat al tau:',
        bg: 'Вашият най-добър резултат:',
        lv: 'Jūsu labākais rezultāts:',
    },
    testBackBtn: {
        pl: 'Wszystkie lekcje',
        cs: 'Všechny lekce',
        sk: 'Všetky lekcie',
        en: 'All lessons',
        hu: 'Összes lecke',
        hr: 'Sve lekcije',
        lt: 'Visos pamokos',
        ro: 'Toate lecțiile',
        bg: 'Всички уроци',
        lv: 'Visas nodarbības',
    },
    testRepeaBtn: {
        pl: 'Wypełnij test ponownie',
        cs: 'Udělat test znovu',
        sk: 'Urobte si test znova',
        en: 'Complete the test again',
        hu: 'Teszt újbóli kitöltése',
        hr: 'Ispuni test ponovno',
        lt: 'Dar kartą atlikite testą',
        ro: 'Face din nou testul',
        bg: 'Попълнете теста отново',
        lv: 'Pabeidziet testu vēlreiz',
    },
    askExpert: {
        pl: 'Zapytaj eksperta',
        cs: 'Zeptejte se odborníka',
        sk: 'Spýtajte sa odborníka',
        en: 'Ask the expert',
        hu: 'Kérdezze a szakértőt',
        hr: 'Pitaj stručnjaka',
        lt: 'Paklauskite eksperto',
        ro: 'Întrebați-l pe expert',
        bg: 'Попитайте експерта',
        lv: 'Jautājiet ekspertam',
    },
    askExpertDescription: {
        pl: 'Masz trudności z rozwiązaniem zadania? Zadaj pytanie naszemu ekspertowi. Odpowiedź pojawi się w skrzynce odbiorczej.',
        cs: 'Máte potíže s řešením úkolu? Položte otázku našemu odborníkovi. Odpověď se objeví ve vaší schránce.',
        sk: 'Máte ťažkosti s riešením úlohy? Položte otázku nášmu odborníkovi. Odpoveď sa objaví vo vašej schránke.',
        en: 'Having difficulty solving an assignment? Ask your question to our expert. The answer will appear in your inbox.',
        hu: 'Nehézséget okoz egy feladat megoldása? Tegye fel kérdését szakértőnknek. A válasz a postaládájába fog érkezni.',
        hr: 'Imaš poteškoća s rješavanjem zadatka? Postavi pitanje našem stručnjaku. Odgovor će se pojaviti u sandučiću.',
        lt: 'Sunkumai sprendžiant užduotį? Užduokite klausimą mūsų ekspertui. Atsakymą gausite į savo pašto dėžutę.',
        ro: 'Aveți dificultăți în rezolvarea unei sarcini? Adresați o întrebare expertului nostru. Răspunsul va apărea în căsuța dvs. poștală.',
        bg: 'Имате затруднения при решаването на задача? Задайте въпроса си на нашия експерт. Отговорът ще се появи във вашата пощенска кутия.',
        lv: 'Grūtības ar uzdevuma izpildi? Uzdodiet jautājumu mūsu ekspertam. Atbilde parādīsies jūsu iesūtnē.',
    },
    askExpertSend: {
        pl: 'Wyślij',
        cs: 'Odeslat',
        sk: 'Odoslať',
        en: 'Send',
        hu: 'Küldje el',
        hr: 'Pošalji',
        lt: 'Siųsti',
        ro: 'Trimiteți',
        bg: 'Изпрати',
        lv: 'Sūtīt',
    },
    askExpertInboxHeader: {
        pl: 'Pytanie do lekcji',
        cs: 'Otázka k lekci',
        sk: 'Otázka k lekcii',
        en: 'Question for the lesson',
        hu: 'Kérdés a leckéhez',
        hr: 'Pitanje za lekciju',
        lt: 'Klausimas pamokai',
        ro: 'Întrebare pentru lecție',
        bg: 'Въпрос за урока',
        lv: 'Jautājums par nodarbību',
    },
    askExpertAnswer: {
        pl: 'Odpowiedź eksperta',
        cs: 'Odpověď odborníka',
        sk: 'Odpoveď odborníka',
        en: 'Answer from the expert',
        hu: 'A szakértő válasza',
        hr: 'Odgovor stručnjaka',
        lt: 'Eksperto atsakymas',
        ro: 'Răspuns de la expert',
        bg: 'Отговор от експерта',
        lv: 'Eksperta atbilde',
    },
    inbox: {
        pl: 'Skrzynka odbiorcza',
        cs: 'Doručená pošta',
        sk: 'Doručená pošta',
        en: 'Inbox',
        hu: 'Bejövő postaláda',
        hr: 'Sandučić',
        lt: 'Pašto dėžutė',
        ro: 'Inbox',
        bg: 'Входяща поща',
        lv: 'Iesūtne',
    },
    makeRateText: {
        pl: 'Oceń kartę:',
        cs: 'Hodnotící karta:',
        sk: 'Hodnotiaca karta:',
        en: 'Rate card:',
        hu: 'Díjtáblázat:',
        hr: 'Ocijeni kartu:',
        lt: 'Įvertinimo kortelė:',
        ro: 'Carte de tarife:',
        bg: 'Оценете картата:',
        lv: 'Novērtējiet karti:',
    },
    pollHeading: {
        pl: 'Kolejna lekcja za Tobą!',
        cs: 'Další lekce je za tebou!',
        sk: 'Ďalšia lekcia je za tebou!',
        en: 'Another lesson has been completed!',
        hu: 'Már túl vagy egy újabb leckén!',
        hr: 'Još jedna lekcija iza tebe!',
        lt: 'Baigta dar viena pamoka!',
        ro: 'O altălecție este în spatele tău!',
        bg: 'Още един урок е завършен!',
        lv: 'Vēl viena nodarbība ir pabeigta!',
    },
    pollFirstParagraph: {
        pl: 'Chcemy poznać Twoją opinię, abyśmy mogli dalej rozwijać kurs i dopasowywać go do Twoich potrzeb. Prosimy o zaznaczenie odpowiedzi.',
        cs: 'Chceme znát tvůj názor, abychom mohli kurz dále rozvíjet a přizpůsobovat ho tvým potřebám. Prosíme o označeníodpovědi.',
        sk: 'Chceme poznať tvoj názor, aby sme mohli ďalejrozvíjať kurz a prispôsobovať ho tvojim potrebám. Prosíme o označenie odpovede.',
        en: 'We are eager to hear your feedback, which enables us to continue refining and tailoring our course to meet your needs. Please, indicate your responses.',
        hu: 'Szeretnénk megismerni a véleményed, hogy tovább fejleszthessük a tanfolyamot és igazíthassuk a te igényeidhez. Kérjük, jelöld be a válaszod.',
        hr: 'Želimo saznati vaše mišljenje kako bismo mogli dalje razvijati tečaj i prilagoditi ga vašim potrebama. Molimo vas da označite odgovore.',
        lt: 'Nekantraujame išgirsti jūsų atsiliepimus, kurie leidžia mums toliau tobulinti ir pritaikyti mūsų kursą jūsų poreikiams. Prašome nurodyti savo atsakymus.',
        ro: 'Vrem săștimpărerea ta, pentru a putea dezvolta în continuare cursul și a-l adapta nevoilor tale. Te rugămsă marchezi răspunsul.',
        bg: 'Искаме да чуем вашите отзиви, които ни позволяват да продължим да усъвършенстваме и персонализираме нашия курс, за да отговори на вашите нужди. Моля, посочете отговорите си.',
        lv: 'Mēs vēlamies dzirdēt jūsu atsauksmes, kas ļauj mums turpināt pilnveidot un pielāgot mūsu kursu, lai tas atbilstu jūsu vajadzībām. Lūdzu, norādiet savas atbildes.',
    },
    pollSecondParagraph: {
        pl: 'W skali od 1 do 5, gdzie 1 to całkowicie niezadowolony, a 5 to bardzo zadowolony, jak oceniasz nasząaplikację pod kątem:',
        cs: 'Na stupnici od 1 do 5, kde 1 je zcela nespokojený a 5 je velmi spokojený, jak hodnotíš naši aplikaci z hlediska:',
        sk: 'Na stupnici od 1 do 5, kde 1 je úplne nespokojný a 5 je veľmi spokojný, ako hodnotíš našu aplikáciu z hľadiska:',
        en: 'On a scale from 1 to 5, where 1 represents complete dissatisfaction and 5 signifies great satisfaction, how would you rate our application in terms of:',
        hu: '1-től 5-ig terjedő skálán, ahol 1 teljesen elégedetlen, 5 pedig nagyon elégedett, hogyan értékelnéd az alkalmazásunkat a következő szempontok alapján:',
        hr: 'Na skali od 1 do 5, gdje je 1 potpuno nezadovoljan, a 5 vrlo zadovoljan, kako ocjenjujete našu aplikaciju s obzirom na:',
        lt: 'Kaip, skalėje nuo 1 iki 5, kur 1 reiškia visišką nepasitenkinimą, o 5 - didelį pasitenkinimą, įvertintumėte mūsų programėlę:',
        ro: 'Pe o scară de la 1 la 5, unde 1 este complet nemulțumitși 5 este foarte mulțumit, cum evaluezi aplicațianoastră în ceea ce privește:',
        bg: 'По скала от 1 до 5, където 1 означава пълно неудовлетворение, а 5 означава голямо удовлетворение, как бихте оценили нашето приложение по отношение на:',
        lv: 'Kā jūs novērtētu mūsu lietotni, izmantojot skalu no 1 līdz 5, kur 1 ir pilnīga neapmierinātība un 5 ir liela apmierinātība:',
    },
    pollFirstQuestion: {
        pl: 'jakości treści edukacyjnych',
        cs: 'Kvality vzdělávacíchobsahů',
        sk: 'Kvality vzdelávacích obsahov',
        en: 'The quality of educational content',
        hu: 'Az oktatási tartalom minősége',
        hr: 'kvalitetu edukativnih sadržaja',
        lt: 'Mokomojo turinio kokybė',
        ro: 'Calitatea conținutuluieducațional',
        bg: 'Качеството на образователното съдържание',
        lv: 'Izglītības satura kvalitāte',
    },
    pollSecondQuestion: {
        pl: 'jakości audio i zdjęć',
        cs: 'Kvality audia a fotografií',
        sk: 'Kvality audia a fotografií',
        en: 'The quality of audio and visuals',
        hu: 'Az audio és a képek minősége',
        hr: 'kvalitetu audia i fotografija',
        lt: 'Garso ir vaizdo kokybė',
        ro: 'Calitatea audio și a fotografiilor',
        bg: 'Качеството на аудио и визуализациите',
        lv: 'Audio un vizuālo materiālu kvalitāte',
    },
    pollThirdQuestion: {
        pl: 'łatwości korzystania z aplikacji',
        cs: 'Jednoduchosti používání aplikace',
        sk: 'Lahkosti používania aplikácie',
        en: 'The user-friendliness of the application',
        hu: 'Az alkalmazás könnyű használata',
        hr: 'lakoću korištenja aplikacije',
        lt: 'Programos patogumas vartotojui',
        ro: 'Ușurința de utilizare a aplicației',
        bg: 'Удобството за потребителя на приложението',
        lv: 'Lietojumprogrammas lietošanas ērtums',
    },
    pollSecondHeading: {
        pl: 'Ostatnie pytanie:',
        cs: 'Poslední otázka',
        sk: 'Posledná otázka',
        en: 'Last question',
        hu: 'Utolsó kérdés',
        hr: 'Posljednje pitanje:',
        lt: 'Paskutinis klausimas',
        ro: 'Ultima întrebare',
        bg: 'Последен въпрос:',
        lv: 'Pēdējais jautājums:',
    },
    pollFourthQuestion: {
        pl: 'Jakie zmiany w naszej platformie chcesz zobaczyć w przyszłości?',
        cs: 'Jaké změny na naší platformě chceš vidět v budoucnosti?',
        sk: 'Aké zmeny na našej platforme chceš vidieť v budúcnosti?',
        en: 'What improvements would you like to see in our platform in the future?',
        hu: 'Milyen változásokat szeretnél látni a platformunkon a jövőben?',
        hr: 'Koje promjene na našoj platformi želite vidjeti u budućnosti?',
        lt: 'Kokių patobulinimų mūsų platformoje norėtumėte ateityje?',
        ro: 'Ce schimbări în platforma noastrăți-arplăceasă vezi în viitor?',
        bg: 'Какви подобрения бихте искали да видите в нашата платформа в бъдеще?',
        lv: 'Kādus uzlabojumus jūs vēlētos redzēt mūsu platformā nākotnē?',
    },
    pollTextareaPlaceholder: {
        pl: 'Kliknij w okienko, aby rozpocząć pisanie',
        cs: 'Klepnutím na pole začněte psát',
        sk: 'Kliknutím na políčko začnite písať',
        en: 'Click on the box to start typing',
        hu: 'Kattintson a mezőre a gépelés megkezdéséhez',
        hr: 'Klikni u okvir da započneš pisati',
        lt: 'Spustelėkite langelį ir pradėkite rašyti',
        ro: 'Faceți clic pe casetă pentru a începe să tastați',
        ro: 'Click pentru a începe să tastați',
        lt: 'Spustelėkite langelį ir pradėkite rašyti',
    },
    pollThanksParagraph: {
        pl: 'Dziękujemy za wypełnienie ankiety!',
        cs: 'Děkujeme za vyplnění dotazníku!',
        sk: 'Ďakujeme za vyplnenie dotazníka!',
        en: 'Thank you for taking the time to complete our survey!',
        hu: 'Köszönjük, hogy kitöltötted a felmérést!',
        hr: 'Hvala vam što ste ispunili anketu!',
        lt: 'Dėkojame, kad skyrėte laiko mūsų apklausai užpildyti!',
        ro: 'Vămulțumim pentru completarea sondajului!',
        bg: 'Благодарим ви, че отделихте време да попълните нашето проучване!',
        lv: 'Paldies, ka veltījāt laiku, lai aizpildītu mūsu aptauju!',
    },
    pollThanksSecondParagraph: {
        pl: 'Twoja opinia jest dla nas bardzo cenna. Pomoże w dalszym rozwoju kursu.',
        cs: 'Tvůj názor je pro nás velmi cenný pro další rozvoj kurzu.',
        sk: 'Tvoj názor je pre nás veľmi cenný pri ďalšom rozvoji kurzu.',
        en: 'Your feedback is incredibly valuable to us as we continue to enhance our course.',
        hu: 'A véleményed nagyon értékes számunkra a tanfolyam továbbfejlesztésében.',
        hr: 'Vaše mišljenje je za nas vrlo vrijedno. Pomoći će u daljnjem razvoju tečaja.',
        lt: 'Jūsų atsiliepimai mums nepaprastai vertingi toliau tobulinant savo kursą.',
        ro: 'Părerea ta este foarte valoroasă pentru noi în dezvoltarea ulterioară a cursului.',
        bg: 'Вашата обратна връзка е изключително ценна за нас, тъй като продължаваме да усъвършенстваме нашия курс.',
        lv: 'Jūsu atsauksmes mums ir ārkārtīgi vērtīgas, jo mēs turpinām pilnveidot savu kursu.',
    },
    pollCancelButton: {
        pl: 'Zapytaj później',
        cs: 'Zeptejte se později',
        sk: 'Opýtajte sa neskôr',
        en: 'Ask later',
        hu: 'Kérdezz később',
        hr: 'Pitaj kasnije',
        lt: 'Paklauskite vėliau',
        ro: 'Intreaba mai tarziu',
        bg: 'Попитай по-късно',
        lv: 'Jautāt vēlāk',
    },
    pollNextButton: {
        pl: 'Dalej',
        cs: 'Další',
        sk: 'Ďalšie',
        en: 'Next',
        hu: 'Következő',
        hr: 'Dalje',
        lt: 'Kitas',
        ro: 'Următorul',
        bg: 'Следващ',
        lv: 'Nākamais',
    },
    pollSendButton: {
        pl: 'Wyślij',
        cs: 'Poslat',
        sk: 'Odoslať',
        en: 'Send',
        hu: 'Küld',
        hr: 'Pošalji',
        lt: 'Siųsti',
        ro: 'Trimite',
        bg: 'Изпрати',
        lv: 'Sūtīt',
    },
    passwordRecoveryLoginText: {
        pl: 'Nie pamiętam hasła?',
        cs: 'Nepamatuji si heslo?',
        sk: 'Nepamätám si heslo?',
        en: "I don't remember the password?",
        hu: 'Nem emlékszem a jelszóra?',
        hr: 'Ne sjećam se lozinke?',
        lt: 'Neprisimenu slaptažodžio?',
        ro: 'Nu-mi amintesc parola?',
        bg: 'Не помня паролата?',
        lv: 'Neatceros paroli?',
    },
    passwordRecoveryLoginLink: {
        pl: 'Kliknij tutaj',
        cs: 'Klikněte zde',
        sk: 'Kliknite sem',
        en: 'Click here',
        hu: 'Kattintson ide',
        hr: 'Klikni ovdje',
        lt: 'Spustelėkite čia',
        ro: 'Faceți clic aici',
        bg: 'Кликнете тук',
        lv: 'Noklikšķiniet šeit',
    },
    passwordRecoveryHeader: {
        pl: 'Nie pamiętam hasła',
        cs: 'Nepamatuji si heslo',
        sk: 'Nepamätám si heslo',
        en: "I don't remember the password",
        hu: 'Nem emlékszem a jelszóra',
        hr: 'Ne sjećam se lozinke',
        lt: 'Neprisimenu slaptažodžio',
        ro: 'Nu-mi amintesc parola',
        bg: 'Не помня паролата',
        lv: 'Neatceros paroli',
    },
    passwordRecoveryDescription: {
        pl: 'Aby otrzymać link do zresetowania hasła, wprowadź adres e-mail powiązany z Twoim kontem.',
        cs: 'Abyste obdrželi odkaz na resetování hesla, zadejte níže e-mailovou adresu spojenou s vaším účtem.',
        sk: 'Ak chcete dostať odkaz na obnovenie hesla, zadajte nižšie e-mailovú adresu priradenú k vášmu kontu.',
        en: 'To receive a link to reset your password, enter the email address associated with your account below.',
        hu: 'Ha szeretne egy linket kapni jelszava visszaállításához, adja meg a fiókjához tartozó e-mail címet az alábbiakban.',
        hr: 'Da biste primili link za resetiranje lozinke, unesite e-mail adresu povezanu s vašim računom.',
        lt: 'Norėdami gauti slaptažodžio atstatymo nuorodą, toliau įveskite su savo paskyra susietą el. pašto adresą.',
        ro: 'Pentru a primi un link de resetare a parolei, introduceți adresa de e-mail asociată contului dumneavoastră mai jos.',
        bg: 'За да получите връзка за нулиране на паролата си, въведете имейл адреса, свързан с вашия акаунт по-долу.',
        lv: 'Lai saņemtu saiti paroles atiestatīšanai, ievadiet zemāk e-pasta adresi, kas saistīta ar jūsu kontu.',
    },
    passwordRecoveryEmail: {
        pl: 'Twój adres e-mail',
        cs: 'Váš e-mail',
        sk: 'Váš e-mail',
        en: 'Your e-mail',
        hu: 'Az Ön e-mail címe',
        hr: 'Vaša e-mail adresa',
        lt: 'Jūsų el. paštas',
        ro: 'E-mail-ul dvs',
        bg: 'Вашият имейл адрес',
        lv: 'Jūsu e-pasts',
    },
    passwordRecoveryButton: {
        pl: 'Resetuj hasło',
        cs: 'Resetovat heslo',
        sk: 'Obnovenie hesla',
        en: 'Reset password',
        hu: 'Jelszó visszaállítása',
        hr: 'Resetiraj lozinku',
        lt: 'Atkurti slaptažodį',
        ro: 'Resetare parolă',
        bg: 'Нулиране на паролата',
        lv: 'Atiestatīt paroli',
    },
    passwordRecoveryButtonAfterSent: {
        pl: 'Wiadomość e-mail została wysłana',
        cs: 'E-mail byl odeslán',
        sk: 'E-mail bol odoslaný',
        en: 'The email has been sent',
        hu: 'Az e-mail elküldésre került',
        hr: 'E-mail poruka je poslana',
        lt: 'El. laiškas buvo išsiųstas',
        ro: 'E-mailul a fost trimis',
        bg: 'Имейлът е изпратен',
        lv: 'E-pasts ir nosūtīts',
    },
    changePasswordHeader: {
        pl: 'Wprowadź nowe hasło',
        cs: 'Zadejte nové heslo',
        sk: 'Zadajte nové heslo',
        en: 'Enter a new password',
        hu: 'Új jelszó megadása',
        hr: 'Unesite novu lozinku',
        lt: 'Įveskite naują slaptažodį',
        ro: 'Introduceți o nouă parolă',
        bg: 'Въведете нова парола',
        lv: 'Ievadiet jaunu paroli',
    },
    changePasswordDescription: {
        pl: 'Wprowadź nowe hasło tutaj',
        cs: 'Zde zadejte své nové heslo',
        sk: 'Zadajte svoje nové heslo tu',
        en: 'Enter your new password here',
        hu: 'Adja meg új jelszavát itt',
        hr: 'Ovdje unesite novu lozinku',
        lt: 'Įveskite naują slaptažodį čia',
        ro: 'Introduceți noua dvs. parolă aici',
        bg: 'Въведете новата си парола тук',
        lv: 'Šeit ievadiet jauno paroli',
    },
    changePasswordPasswordLabel: {
        pl: 'Nowe hasło',
        cs: 'Nové heslo',
        sk: 'Nové heslo',
        en: 'New password',
        hu: 'Új jelszó',
        hr: 'Nova lozinka',
        lt: 'Naujas slaptažodis',
        ro: 'Parolă nouă',
        bg: 'Нова парола',
        lv: 'Jauna parole',
    },
    changePasswordRepeatPasswordLabel: {
        pl: 'Potwierdź hasło',
        cs: 'Potvrďte heslo',
        sk: 'Potvrdenie hesla',
        en: 'Confirm password',
        hu: 'Jelszó megerősítése',
        hr: 'Potvrdi lozinku',
        lt: 'Patvirtinti slaptažodį',
        ro: 'Confirmați parola',
        bg: 'Потвърдете паролата',
        lv: 'Apstipriniet paroli',
    },
    changePasswordButton: {
        pl: 'Resetowanie hasła',
        cs: 'Resetovat heslo',
        sk: 'Obnovenie hesla',
        en: 'Reset password',
        hu: 'Jelszó visszaállítása',
        hr: 'Resetiranje lozinke',
        lt: 'Iš naujo nustatyti slaptažodį',
        ro: 'Resetare parolă',
        bg: 'Нулиране на паролата',
        lv: 'Atiestatīt paroli',
    },
    certificateHeader: {
        pl: 'CERTYFIKAT',
        cs: 'CERTIFIKÁT',
        sk: 'CERTIFIKÁT',
        en: 'CERTIFICATE',
        hu: 'TANÚSÍTVÁNY',
        hr: 'CERTIFIKAT',
        lt: 'SERTIFIKATAS',
        ro: 'CERTIFICAT',
        bg: 'СЕРТИФИКАТ',
        lv: 'SERTIFIKĀTS',
    },
    certificateNotAllowedHeader: {
        pl: 'UKOŃCZ WSZYSTKIE LEKCJE, ABY OTRZYMAĆ CERTYFIKAT',
        cs: 'DOKONČI VŠECHNY LEKCE, ABYS OBDRŽEL CERTIFIKÁT',
        sk: 'DOKONČI VŠETKY LEKCIE, ABY SI ZÍSKAL CERTIFIKÁT',
        en: 'COMPLETE ALL LESSONS TO GET A CERTIFICATE',
        hu: 'TÖLTSD KI AZ ÖSSZES LECKÉT, HOGY MEGKAPD A TANÚSÍTVÁNYT',
        hr: 'ZAVRŠI SVE LEKCIJE DA BI DOBIO/A CERTIFIKAT',
        lt: 'ATLIKITE VISAS PAMOKAS, KAD GAUTUMĖTE SERTIFIKATĄ',
        ro: 'SĂ COMPLETEZE TOATE LECȚIILE PENTRU A PRIMI UN CERTIFICAT',
        bg: 'ЗАВЪРШЕТЕ ВСИЧКИ УРОЦИ, ЗА ДА ПОЛУЧИТЕ СЕРТИФИКАТ',
        lv: 'IZPILDĪT VISAS NODARBĪBAS, LAI SAŅEMTU SERTIFIKĀTU',
    },
    certificateDescription: {
        pl: 'Aby otrzymać certyfikat, wpisz swoje imię i nazwisko',
        cs: 'Chceš-li získat certifikát, zadej své jméno a příjmení',
        sk: 'Ak chceš získať certifikát, zadaj svoje meno a priezvisko',
        en: 'To receive a certificate, enter your name and surname',
        hu: 'Hogy megkapd a tanúsítványt, add meg a neved és a vezetékneved',
        hr: 'Da biste primili certifikat, unesite svoje ime i prezime',
        lt: 'Norėdami gauti sertifikatą, įveskite savo vardą ir pavardę',
        ro: 'Pentru a primi certificatul, introduceți numele dvs.',
        bg: 'За да получите сертификат, въведете своето име и фамилия',
        lv: 'Lai saņemtu sertifikātu, ievadiet savu vārdu un uzvārdu',
    },
    certificateName: {
        pl: 'Imię i nazwisko',
        cs: 'Jméno a příjmení',
        sk: 'Meno a priezvisko',
        en: 'Name and surname',
        hu: 'Név és vezetéknév',
        hr: 'Ime i prezime',
        lt: 'Vardas ir pavardė',
        ro: 'Nume',
        bg: 'Име и фамилия',
        lv: 'Vārds un uzvārds',
    },
    certificateSend: {
        pl: 'Wyślij',
        cs: 'Odeslat',
        sk: 'Odoslať',
        en: 'Send',
        hu: 'Küldés',
        hr: 'Pošalji',
        lt: 'Siųsti',
        ro: 'Trimiteți',
        bg: 'Изпрати',
        lv: 'Sūtīt',
    },
    certificateWarning: {
        pl: 'Upewnij się, że wpisujesz prawidłowe dane!',
        cs: 'Ujisti se, že zadáváš správné údaje. Není možnost pozdějšízměny.',
        sk: 'Uistite sa, že zadávate správne údaje. Neskôr nie je možné ich zmeniť.',
        en: 'Make sure you enter the correct data. There is no possibility of later change.',
        hu: 'Győződj meg róla, hogy helyes adatokat adtál meg. Később nem lehetséges módosítani.',
        hr: 'Provjerite jesu li podaci točni!',
        lt: 'Įsitikinkite, kad įvedėte teisingus duomenis. Vėliau jų pakeisti nėra galimybės.',
        ro: 'Asigurați-vă că introduceți datele corecte!',
        bg: 'Уверете се, че въвеждате правилните данни!',
        lv: 'Pārliecinieties, ka ievadījāt pareizos datus!',
    },
    certificateSendInfo: {
        pl: 'Certyfikat zostanie wysłany do 3 dni roboczych na twój adres mailowy. Pobierzesz go też w tym miejscu.',
        cs: 'Certifikát bude odeslán do 3 pracovních dnů na tvou emailovou adresu. Můžeš si ho také stáhnout na tomto místě.',
        sk: 'Certifikát bude odoslaný do 3 pracovných dní na tvoju emailovú adresu. Môžeš ho stiahnuť aj na tomto mieste.',
        en: 'The certificate will be sent within 3 working days to your email address. You can also download it here.',
        hu: 'A tanúsítványt 3 munkanapon belül elküldjük az e-mail címedre. Letöltheted innen is.',
        hr: 'Certifikat će biti poslan unutar 3 radna dana na vašu e-mail adresu. Također ga možete preuzeti ovdje.',
        lt: 'Sertifikatas bus išsiųstas per 3 darbo dienas jūsų el. pašto adresu. Jį taip pat galite atsisiųsti čia.',
        ro: 'Certificatul va fi trimis la adresa dumneavoastră de e-mail în termen de 3 zile lucrătoare. De asemenea, îl puteți descărca de aici.',
        bg: 'Сертификатът ще бъде изпратен в рамките на 3 работни дни на вашия имейл адрес. Можете също да го изтеглите тук.',
        lv: 'Sertifikāts tiks nosūtīts 3 darba dienu laikā uz jūsu e-pasta adresi. Varat to arī lejupielādēt šeit.',
    },
    certificateDownload: {
        pl: 'Pobierz',
        cs: 'Stáhnout',
        sk: 'Stiahnuť',
        en: 'Download',
        hu: 'Letöltés',
        hr: 'Preuzmi',
        lt: 'Atsisiųsti',
        ro: 'Descărcați',
        bg: 'Изтегли',
        lv: 'Lejupielādēt',
    },
    task: {
        pl: 'Zadanie',
        cs: 'Úkol',
        sk: 'Úloha',
        en: 'Task',
        hu: 'Feladat',
        hr: 'Zadatak',
        lt: 'Užduotis',
        ro: 'Sarcină',
        bg: 'Задача',
        lv: 'Uzdevums',
    },
    taskActivityTitle: {
        pl: 'Sprawdźmy Twoją wiedzę z ostatnich lekcji.',
        cs: 'Zkontrolujme si tvé znalosti z posledních lekcí.',
        sk: 'Skontrolujme si tvoje znalosti z posledných lekcií.',
        en: "Let's check your knowledge from the last lessons.",
        hu: 'Nézzük át a legutóbbi leckékben tanultakat.',
        hr: 'Provjerimo tvoje znanje iz posljednjih lekcija.',
        lt: 'Pasitikrinkime savo žinias iš paskutinių pamokų.',
        ro: 'Haideți să vă testăm cunoștințele despre lecțiile recente.',
        bg: 'Нека проверим знанията ви от последните уроци.',
        lv: 'Pārbaudīsim jūsu zināšanas no pēdējām nodarbībām.',
    },
    taskActivityDescription: {
        pl: 'Nasz ekspert językowy sprawdzi twoją znajomość języka i wskaże, co możesz jeszcze poprawić.',
        cs: 'Náš jazykový expert zkontroluje tvé znalosti a ukáže ti, co můžeš ještě zlepšit.',
        sk: 'Náš jazykový expert skontroluje tvoje znalosti a ukáže ti, čo môžeš ešte zlepšiť.',
        en: 'Our language expert will check your knowledge and point out what you can improve.',
        hu: 'Nyelvi szakértőnk átnézi a tudásod és megmutatja, mit tudsz még fejleszteni.',
        hr: 'Naš jezični stručnjak provjerit će tvoje znanje jezika i ukazati na što još možeš poboljšati.',
        lt: 'Mūsų kalbos ekspertas patikrins jūsų žinias ir nurodys, ką galite patobulinti.',
        ro: 'Expertul nostru în limbi străine vă va verifica competențele lingvistice și vă va indica ce mai puteți îmbunătăți.',
        bg: 'Нашият езиков експерт ще провери вашите знания и ще посочи какво можете да подобрите.',
        lv: 'Mūsu valodu eksperts pārbaudīs jūsu zināšanas un norādīs, ko vēl varat uzlabot.',
    },
    taskActivitySecondDescription: {
        pl: 'Wiadomość zwrotna pojawi się w skrzynce odbiorczej.',
        cs: 'Zpětná vazba se objeví v tvé schránce.',
        sk: 'Spätná väzba sa objaví v tvojej schránke.',
        en: 'The feedback will appear in your inbox.',
        hu: 'A visszajelzés megjelenik a beérkezett üzeneteid között.',
        hr: 'Povratna poruka pojavit će se u sandučiću.',
        lt: 'Grįžtamasis ryšys pasirodys jūsų pašto dėžutėje.',
        ro: 'Un mesaj de răspuns va apărea în căsuța dvs. poștală.',
        bg: 'Обратната връзка ще се появи във входящата ви поща.',
        lv: 'Atsauksmes parādīsies jūsu iesūtnē.',
    },
    taskActivityName: {
        pl: 'Zadanie:',
        cs: 'Úkol:',
        sk: 'Úloha:',
        en: 'Task:',
        hu: 'Feladat:',
        hr: 'Zadatak:',
        lt: 'Užduotis:',
        ro: 'Sarcină:',
        bg: 'Задача:',
        lv: 'Uzdevums:',
    },
    taskActivityDone: {
        pl: 'Zrobione',
        cs: 'Hotovo',
        sk: 'Hotovo',
        en: 'Done',
        hu: 'Kész',
        hr: 'Gotovo',
        lt: 'Atlikta',
        ro: 'Realizat',
        bg: 'Готово',
        lv: 'Pabeigts',
    },
    taskActivityExample: {
        pl: 'Przykładowa odpowiedź',
        cs: 'Příklad odpovědi',
        sk: 'Príklad odpovede',
        en: 'Example answer',
        hu: 'Példaválasz',
        hr: 'Primjer odgovora',
        lt: 'Atsakymo pavyzdys',
        ro: 'Exemplu de răspuns',
        bg: 'Примерен отговор',
        lv: 'Piemēra atbilde',
    },
    taskActivitySkip: {
        pl: 'Pomiń',
        cs: 'Přeskočit',
        sk: 'Preskočiť',
        en: 'Skip',
        hu: 'Kihagy',
        hr: 'Preskoči',
        lt: 'Praleisti',
        ro: 'Skip',
        bg: 'Пропуснете',
        lv: 'Izlaistīt',
    },
    taskActivitySend: {
        pl: 'Wyślij',
        cs: 'Odeslat',
        sk: 'Odoslať',
        en: 'Send',
        hu: 'Küldés',
        hr: 'Pošalji',
        lt: 'Siųsti',
        ro: 'Trimiteți',
        bg: 'Изпрати',
        lv: 'Sūtīt',
    },
    taskActivityMessageSent: {
        pl: 'Wiadomość pomyślnie wysłano',
        cs: 'Zpráva byla úspěšně odeslána',
        sk: 'Správa bola úspešne odoslaná',
        en: 'Message sent successfully',
        hu: 'Az üzenet sikeresen elküldve',
        hr: 'Poruka uspješno poslana',
        lt: 'Pranešimas sėkmingai išsiųstas',
        ro: 'Mesaj trimis cu succes',
        bg: 'Съобщението е изпратено успешно',
        lv: 'Ziņojums veiksmīgi nosūtīts',
    },
    taskActivityMessageSentDescription: {
        pl: 'Odpowiedź pojawi się w skrzynce odbiorczej.',
        cs: 'Odpověď se objeví v tvé schránce.',
        sk: 'Odpoveď sa objaví v tvojej schránke.',
        en: 'The answer will appear in your inbox.',
        hu: 'A válasz megjelenik a beérkezett üzeneteid között.',
        hr: 'Odgovor će se pojaviti u sandučiću.',
        lt: 'Atsakymas pasirodys jūsų pašto dėžutėje.',
        ro: 'Răspunsul va apărea în căsuța dvs. poștală.',
        bg: 'Отговорът ще се появи във входящата ви поща.',
        lv: 'Atbilde parādīsies jūsu iesūtnē.',
    },
    expert: {
        pl: 'Ekspert',
        cs: 'Expert',
        sk: 'Expert',
        en: 'Expert',
        hu: 'Szakértő',
        hr: 'Stručnjak',
        lt: 'Ekspertas',
        ro: 'Expert',
        bg: 'Експерт',
        lv: 'Eksperts',
    },
    descriptiveTask: {
        pl: 'Zadanie opisowe',
        cs: 'Popisný úkol',
        sk: 'Popisná úloha',
        en: 'Descriptive task',
        hu: 'Leíró feladat',
        hr: 'Opisni zadatak',
        lt: 'Aprašomoji užduotis',
        ro: 'Sarcină descriptivă',
        bg: 'Описателна задача',
        lv: 'Aprakstošais uzdevums',
    },
    inboxHeader: {
        pl: 'Skrzynka odbiorcza',
        cs: 'Doručená pošta',
        sk: 'Doručená pošta',
        en: 'Inbox',
        hu: 'Beérkezett üzenetek',
        hr: 'Sandučić',
        lt: 'Gautieji',
        ro: 'Inbox',
        bg: 'Входяща поща',
        lv: 'Iesūtne',
    },
    inboxSender: {
        pl: 'Nadawca',
        cs: 'Odesílatel',
        sk: 'Odosielateľ',
        en: 'Sender',
        hu: 'Küldő',
        hr: 'Pošiljatelj',
        lt: 'Siuntėjas',
        ro: 'Expeditor',
        bg: 'Подател',
        lv: 'Sūtītājs',
    },
    inboxSubject: {
        pl: 'Temat',
        cs: 'Předmět',
        sk: 'Predmet',
        en: 'Subject',
        hu: 'Tárgy',
        hr: 'Predmet',
        lt: 'Tema',
        ro: 'Subiect',
        bg: 'Тема',
        lv: 'Temats',
    },
    inboxDate: {
        pl: 'Data',
        cs: 'Datum',
        sk: 'Dátum',
        en: 'Date',
        hu: 'Dátum',
        hr: 'Datum',
        lt: 'Data',
        ro: 'Data',
        bg: 'Дата',
        lv: 'Datums',
    },
    inboxResponse: {
        pl: 'Odpowiedź',
        cs: 'Odpověď',
        sk: 'Odpoveď',
        en: 'Response',
        hu: 'Válasz',
        hr: 'Odgovor',
        lt: 'Atsakymas',
        ro: 'Răspuns',
        bg: 'Отговор',
        lv: 'Atbilde',
    },
    inboxTypeMessage: {
        pl: 'wpisz wiadomość...',
        cs: 'napiš zprávu...',
        sk: 'napíš správu...',
        en: 'type message...',
        hu: 'írd be az üzenetet...',
        hr: 'upiši poruku…',
        lt: 'rašyti pranešimą...',
        ro: 'tip mesaj...',
        bg: 'напишете съобщение...',
        lv: 'rakstīt ziņojumu...',
    },
    inboxSend: {
        pl: 'Wyślij',
        cs: 'Odeslat',
        sk: 'Odoslať',
        en: 'Send',
        hu: 'Küldés',
        hr: 'Pošalji',
        lt: 'Siųsti',
        ro: 'Trimiteți',
        bg: 'Изпрати',
        lv: 'Sūtīt',
    },
    inboxAskExpert: {
        pl: 'Zapyaj eksperta',
        cs: 'Zeptej se experta',
        sk: 'Opýtaj sa experta',
        en: 'Ask expert',
        hu: 'Kérdezd meg a szakértőt',
        hr: 'Pitaj stručnjaka',
        lt: 'Klausti eksperto',
        ro: 'Întrebați un expert',
        bg: 'Попитайте експерт',
        lv: 'Jautājiet ekspertam',
    },
    inboxTask: {
        pl: 'Zadanie',
        cs: 'Úkol',
        sk: 'Úloha',
        en: 'Task',
        hu: 'Feladat',
        hr: 'Zadatak',
        lt: 'Užduotis',
        ro: 'Sarcină',
        bg: 'Задача',
        lv: 'Uzdevums',
    },
    transcriptionTrigger: {
        pl: 'Kliknij, aby przeczytać słowo.',
        cs: 'Klikněte pro přečtení slova.',
        sk: 'Kliknutím si prečítajte slovo.',
        en: 'Click to read the word.',
        hu: 'Kattints a szó elolvasásához.',
        ro: 'Click pentru a citi cuvântul.',
        lt: 'Spustelėkite norėdami perskaityti žodį.',
        bg: 'Щракнете, за да прочетете думата.',
        lv: 'Klikšķiniet, lai izlasītu vārdu.',
    },
    lesson: {
        pl: 'Lekcja',
        cs: 'Lekce',
        sk: 'Lekcia',
        en: 'Lesson',
        hu: 'Lecke',
        hr: 'Lekcija',
        lt: 'Pamokos',
        ro: 'Lecția',
        bg: 'Урок',
        lv: 'Nodarbība',
    },
    chooseLanguageLevel: {
        pl: 'Wybierz poziom języka',
        cs: 'Vyberte jazykovou úroveň',
        sk: 'Vyberte jazykovú úroveň',
        en: 'Choose language level',
        hu: 'Válasszon nyelvi szintet',
        hr: 'Odaberite razinu jezika',
        lt: 'Pasirinkite kalbos lygį',
        ro: 'Alegeți nivelul de limbă',
        bg: 'Изберете езиково ниво',
        lv: 'Izvēlieties valodas līmeni',
    },
    yourProgress: {
        pl: 'Twój postęp',
        cs: 'Váš pokrok',
        sk: 'Váš pokrok',
        en: 'Your progress',
        hu: 'A fejlődésed',
        hr: 'Vaš napredak',
        lt: 'Jūsų pažanga',
        ro: 'Progresul tău',
        bg: 'Вашият напредък',
        lv: 'Jūsu progress',
    },
    progressDescription: {
        pl: 'ukończone na',
        cs: 'dokončeno na',
        sk: 'dokončených na',
        en: 'finished on',
        hu: 'teljesített',
        hr: 'završeno na',
        lt: 'baigė',
        ro: 'finalizate la',
        bg: 'завършено на',
        lv: 'pabeigts ieslēgts',
    },
    progressMotivationText: {
        pl: 'Znajomość języków obcych jest nie tylko przydatna, ale także niezbędna do osiągnięcia sukcesu w życiu.',
        cs: 'Znalost cizích jazyků je nejen užitečná, ale také nezbytná pro úspěch v životě.',
        sk: 'Znalosť cudzích jazykov je nielen užitočná, ale aj nevyhnutná pre úspech v živote.',
        en: 'Knowledge of foreign languages ​​is not only useful, but also necessary for success in life.',
        hu: 'Az idegen nyelvek ismerete nemcsak hasznos, hanem szükséges is az életben való sikerességhez.',
        hr: 'Poznavanje stranih jezika nije samo korisno, već je i neophodno za uspjeh u životu.',
        lt: 'Užsienio kalbų mokėjimas yra ne tik naudingas, bet ir būtinas sėkmei gyvenime.',
        ro: 'Cunoașterea limbilor străine este nu numai utilă, ci și necesară pentru succesul în viață.',
        bg: 'Знанието на чужди езици не само е полезно, но и необходимо за успеха в живота.',
        lv: 'Svešvalodu zināšanas ir ne tikai noderīgas, bet arī nepieciešamas, lai gūtu panākumus dzīvē.',
    },
    activateAnotherCourse: {
        pl: 'Aktywuj inny kurs',
        cs: 'Aktivujte si jiný kurz',
        sk: 'Aktivujte si ďalší kurz',
        en: 'Activate another course',
        hu: 'Aktiváljon másik tanfolyamot',
        hr: 'Aktivirajte drugi tečaj',
        lt: 'Suaktyvinkite kitą kursą',
        ro: 'Activați un alt curs',
        bg: 'Активирайте друг курс',
        lv: 'Aktivizējiet citu kursu',
    },
    orderCourseText: {
        pl: 'Nie masz kodu? Wypełnij formularz',
        cs: 'Nemáte kód? Vyplnit formulář',
        sk: 'Nemáte kód? Vyplniť formu',
        en: "Don't have a code? Fill in the form",
        hu: 'Nem kell kódolni? Töltsd ki az űrlapot',
        hr: 'Ne morate kodirati? Ispunite obrazac',
        lt: 'Nereikia koduoti? Užpildykite formą',
        ro: 'Nu ai codul o? Completează formularul',
        bg: 'Нямате код? Попълнете формуляра',
        lv: 'Nav koda? Aizpildiet veidlapu',
    },
    orderCourseActionButton: {
        pl: 'Wypełnij',
        cs: 'Vyplnit',
        sk: 'Vyplniť',
        en: 'Fill in',
        hu: 'Töltsd ki',
        hr: 'Ispuniti',
        lt: 'Užpildykite',
        ro: 'Completați',
        bg: 'Попълнете',
        lv: 'Aizpildīt',
    },
};