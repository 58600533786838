import React, { useContext, useEffect, useRef, useState } from 'react';
import UserProfile from '../../../../platform/pages/MailBoxPage/components/UserProfile';
import api from '../../../../platform/api/api';
import moment from 'moment';
import { CONVERSATION_STATUS } from './MailBoxTable';
import { ArrowIcon } from '../icons/Arrow';
import Store from '../../../../Store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ConsultantHintContext } from '../../../../platform/contexts/ConsultantHintContext';

const getTypeText = ({ type }) => {
    switch (type) {
        case 'question':
            return 'Zapytaj eksperta';
        case 'activity':
            return 'Praca domowa';
        default:
            return '';
    }
};
const BoxElement = () => {
    const {isVisible} = useContext(ConsultantHintContext)
    
    const navigate = useNavigate()
    const textRef = useRef();
    const {conversationId} = useParams();

    const [messages, setMessages] = useState([]);
    const [conversation, setConversation] = useState();
    const isCompleted = useMemo(
        () => conversation && conversation.consultantStatus === CONVERSATION_STATUS.COMPLETED,
        [conversation]
    );

    const fetchMessages = async () => {
        api.get(`/messages/conversation/${conversationId}/consultant`).then(
            (response) => {
                setMessages(response.data.messages);
                setConversation(response.data.conversation);
            }
        );
    };

    const fetchChatGptHint = () => {
        api.post(`/open-ai/get-task-answer`, {
            text: messages[0].content,
            languagePairId: conversation.languagePair.id,
        }).then((response) => {
            textRef.current.value = response.data;
        });
    };

    const onCompletedToggle = (value) => {
        const status = Boolean(value)
            ? CONVERSATION_STATUS.COMPLETED
            : CONVERSATION_STATUS.NEW_IN_CONVERSATION;

        api.post(`/conversations/${conversationId}/status`, { status }).then(
            fetchMessages
        );
    };

    const handleSendMessage = (e) => {
        e && e.preventDefault();

        const message = textRef.current.value;
        api.post(`/messages/conversation/${conversationId}/consultant`, {
            content: message,
        }).then(() => {
            textRef.current.value = '';
            fetchMessages();
        });
    };

    const isMyMesage = (message) => {
        return Store.getUsername() === message.user.username;
    };

    const isNextMessageSameUser = (i) => {
        if (!messages[i + 1]) return true;
        return messages[i].user.username === messages[i + 1].user.username;
    };

    const makeBold = () => {
        let txt = textRef.current;
        const before = txt.value.substring(0, txt.selectionStart);
        const sel = txt.value.substring(txt.selectionStart, txt.selectionEnd);
        const after = txt.value.substring(txt.selectionEnd);
        txt.value = `${before}**${sel}**${after}`;
    };

    const formatText = (text) =>
        text.split('\n').map(function (item, idx) {
            return (
                <span key={idx}>
                    {handleBold(item)}
                    <br />
                </span>
            );
        });

    const handleBold = (text) => {
        const arr = text.split('**');

        const newTextArr = [];

        arr.forEach((element, index) => {
            if (index % 2 !== 0) {
                const newElement = <b>{element}</b>;
                newTextArr.push(newElement);
            } else {
                const newElement = <>{element}</>;
                newTextArr.push(newElement);
            }
        });

        return newTextArr;
    };

    useEffect(() => {
        fetchMessages();
    }, []);

    return (
        conversation && <div className="mailbox-wrapper__box__element">
            <div className="mailbox-wrapper__box__element__style">
                <div className="mailbox-wrapper__top_box">
                    <div
                        className="mailbox-button__back"
                        onClick={() => navigate('/consultant/mail-box')}
                    >
                        <ArrowIcon scale={0.7} />
                    </div>
                    <div className="mailbox-mail__topic">
                        {getTypeText(conversation)}: {conversation.name}{' '}
                        (Lekcja: {conversation.lesson.number}.
                        {conversation.lesson.subNumber} Język:{' '}
                        {conversation.languagePair.languageFrom.languageShort}-
                        {conversation.languagePair.languageTo.languageShort})
                    </div>
                    <div className="mailbox-mail__end">
                        <label for="myCheckbox" className="mailbox-mail__text">
                            Zakończ wątek
                        </label>
                        <input
                            type="checkbox"
                            id="myCheckbox"
                            checked={isCompleted}
                            onChange={(e) =>
                                onCompletedToggle(e.target.checked)
                            }
                        />
                    </div>
                </div>
                <div className="mailbox-wrapper__elements">
                    {messages.map((message, index) => (
                        <div
                            className="mailbox-element"
                            key={index}
                            style={{
                                background: isMyMesage(message)
                                    ? '#fff'
                                    : '#eee',
                                borderBottom: isNextMessageSameUser(index)
                                    ? 'none'
                                    : '1px solid #ddd',
                            }}
                        >
                            <UserProfile />
                            <div className="mailbox-sender">
                                {message.user.username}
                            </div>
                            <div className="mailbox-message__element">
                                {message.image && (
                                    <div className="mailbox-message__image-wrapper">
                                        <img
                                            src={message.image.base64}
                                            className="mailbox-message__image"
                                        />
                                    </div>
                                )}
                                {conversation.wordPair && index === 0 && (
                                    <div className="mailbox-message__word">
                                        <p>
                                            <b>Expression native:</b>{' '}
                                            {
                                                conversation.wordPair.wordFrom
                                                    .expression.text
                                            }
                                        </p>
                                        <p>
                                            <b>Sentence native:</b>{' '}
                                            {conversation.wordPair.sentenceFrom}
                                        </p>
                                        <p>
                                            <b>Expression translation:</b>{' '}
                                            {
                                                conversation.wordPair.wordTo
                                                    .expression.text
                                            }
                                        </p>
                                        <p>
                                            <b>Sentence translation:</b>{' '}
                                            {conversation.wordPair.sentenceTo}
                                        </p>
                                    </div>
                                )}
                                {formatText(message.content)}
                            </div>
                            <div className="mailbox-date">
                                {moment(message.timestamps.createdAt).format(
                                    'DD-MM-YYYY'
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <form
                    className="mailbox-wrapper__typing"
                    onSubmit={handleSendMessage}
                >
                    <textarea
                        className="mailbox-texarea__typing"
                        rows={2}
                        ref={textRef}
                        required
                    ></textarea>
                    <div className="mailbox-button__wrapper">
                        <div className="mailbox-button__utils">
                            <button
                                onClick={makeBold}
                                type="button"
                                className="mailbox-button__util-button"
                            >
                                <FontAwesomeIcon icon={faBold} />
                            </button>
                           {isVisible && <button
                                type="button"
                                onClick={fetchChatGptHint}
                                className="mailbox-button__util-button"
                            >
                                <FontAwesomeIcon icon={faLightbulb} />
                            </button>}
                        </div>
                        <button
                            className="mailbox-button__typing"
                            type="submit"
                        >
                            Wyślij
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BoxElement;
