import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";
import new_sweden from "../images/newProductPage/languages/SE.png";
import new_norway from "../images/newProductPage/languages/NO.png";
import new_grece from "../images/newProductPage/languages/GR.png";

import screen1 from "../images/newProductPage/english/1.png";

import Ildiko from "../images/newProductPage/hungarian/people/Ildiko.png";
import Katalin from "../images/newProductPage/hungarian/people/Katalin.png";
import Istvan from "../images/newProductPage/hungarian/people/Istvan.png";
import Zoltan from "../images/newProductPage/hungarian/people/Zoltan.png";
import Gabor from "../images/newProductPage/hungarian/people/Gabor.png";
import Maria from "../images/newProductPage/hungarian/people/Maria.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const latvian = {
  navbar: {
    data: [
      { title: "Pasūtīt kursu", path: "/course" },
      { title: "Pieteikties", path: "/start" },
      { title: "eLingo", path: "/" },
      { title: "Par mums", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      hero: {
        languages: [
          "angļu valodā",
          "spāņu valodā",
          "vācu valodā",
          "holandiešu valodā",
          "itāļu valodā",
          "krievu valodā",
          "poļu valodā",
          "franču valodā",
          "portugāļu valodā",
        ],
        title: "Runājiet drošāk,",
        prefix: "pārliecinošāk",
        subtitle:
          "Apgūstiet valodu prasmes, izmantojot mūsu kursu, kas balstīts uz reālām dzīves situācijām!",
        buttonText: "Pasūtīt tagad!",
      },
      labelInfo: {
        number: "9",
        numberDescription: "valodas",
        itemTwo: "14 000 atsauksmes",
        itemThree: {
          top: "Vairāk nekā 200 tūkstoši",
          bottom: "apmācāmo",
        },
      },
      learning: {
        title: "Kā izskatās kurss?",
        config: [
          {
            category: "Mācieties valodu visvieglākajā veidā",
            description:
              "Simtiem nodarbību ar valodas piemēriem, kas izmantoti reālās sarunās. Praktiskas frāzes, ko māca dzimtās valodas runātāju izstrādātos kursos. Vingrinājumi efektīvi māca lasīšanas, klausīšanās un runāšanas prasmes.",
          },
          {
            category: "Praktiskas nodarbības",
            description:
              "Mācīšanās visos līmeņos sastāv no desmitiem nodarbību. Ja nezināt savu līmeni – sāciet ar pamatlietām. Apgūstiet sasveicināšanos, nedēļas dienas, kā pasūtīt ēdienu restorānā. Katra nodarbība satur piemērus no ikdienas dzīves.",
          },
          {
            category: "Uzdevumi",
            description:
              "Neatkarīgi no tā, vai jums patīk katru dienu risināt krustvārdu mīklas vai, tieši pretēji, jūs labprātāk izvairāties no šādām aktivitātēm, nevar noliegt, ka šīs mīklas ir lieliski piemērotas atmiņas attīstīšanai un ļauj atkārtot apgūto materiālu.",
          },
          {
            category: "Vārdi, teicieni un izteicieni",
            description:
              "Katru nodarbību papildina rūpīgi atlasīti attēli, kas padara mācību procesu intuitīvu un kontekstuālu. Visbiežāk izmantotās tēmas aptver plašu vārdu krājumu no ikdienas dzīves un ceļošanas, ļaujot jums sazināties bez valodas barjerām šajās jomās.",
          },
        ],
        photoData: [screen1],
      },
      languages: {
        languages: [
          { image: new_england, lang: "Angļu" },
          { image: new_french, lang: "Franču" },
          { image: new_spanish, lang: "Spāņu" },
          { image: new_niderland, lang: "Holandiešu" },
          { image: new_germany, lang: "Vācu" },
          { image: new_italy, lang: "Itāļu" },
          { image: new_russia, lang: "Krievu" },
          { image: new_portugal, lang: "Portugāļu" },
          { image: new_polish, lang: "Poļu" },
        ],
        title: "Pieejamās valodas:",
        description:
          "Sagatavojieties savai nākamajai ceļojumam, pievienojiet valodu prasmes savam CV vai iegremdējieties jaunā kultūrā: atklājiet plašo eLingo nodarbību kolekciju un apgūstiet to, kas nepieciešams, lai uzsāktu sarunu, neatkarīgi no jūsu pieredzes līmeņa.",
      },
      success: {
        title: "Veiksmes stāsti",
        subtitle: "Apskatiet, kā citi runā tekoši, izmantojot eLingo",
        config: [
          {
            title: "Praktiskas nodarbības",
            name: "Ildikó",
            comment:
              "Neatkarīgi no tā, vai esat iesācējs, kas sāk ar pamatiem, vai pieredzējis valodas apguvējs, kas vēlas paplašināt savu vārdu krājumu, eLingo piedāvā kaut ko ikvienam. Viena lieta, ko es īpaši atzinīgi vērtēju šajā platformā, ir nodarbību daudzveidība, sākot no ēšanas restorānā līdz taksometra izsaukšanai, padarot mācīšanās pieredzi reālistisku un praktisku. Turklāt praktiskās frāzes un ieraksti, kas demonstrē pareizu izrunu, ir liels ieguvums.",
            photo: Ildiko,
          },
          {
            title: "Praktiskas nodarbības",
            name: "Katalin",
            comment:
              "Man patīk, kā eLingo nodarbības ir pielāgotas reālām situācijām, padarot viegli pielietojamu to, ko esmu iemācījies realitātē. Pēc 3 mēnešu mācībām man piedāvāja jaunu darbu!",
            photo: Katalin,
          },
          {
            title: "Interesanta platforma",
            name: "István",
            comment:
              "eLingo ir lieliska valodu apguves platforma. Nodarbību un vingrinājumu daudzveidība padara mācību procesu interesantu un pievilcīgu. Pateicoties valodu apguvei, es iepazinos ar vācu meiteni tiešsaistē, un drīz mēs precamies. Es to iesaku.",
            photo: Istvan,
          },
          {
            title: "Es to izmantoju savā darbavietā!",
            name: "Zoltán",
            comment:
              "Manuprāt, eLingo ir brīnišķīga platforma ikvienam, kas vēlas uzlabot savas valodu prasmes. Platforma ir izstrādāta tā, lai jaunas valodas apguve būtu patīkama pieredze.",
            photo: Zoltan,
          },
          {
            title: "Patīkama mācīšanās",
            name: "Gábor",
            comment:
              "Es jau vairākus mēnešus izmantoju eLingo, un varu droši teikt, ka tas ir mainījis manu dzīvi. Mani paaugstināja darbā! Es vienmēr esmu bijis ieinteresēts apgūt jaunu valodu, bet nekad nebija laika vai resursu. Ar eLingo es varu mācīties savā tempā un pēc sava grafika.",
            photo: Gabor,
          },
          {
            title: "Izmantoju jau dažus mēnešus.",
            name: "Mária",
            comment:
              "Es jau vairākus mēnešus izmantoju eLingo, un man jāsaka, ka tas ir radījis lielas pārmaiņas manī. Es vienmēr esmu bijusi ieinteresēta apgūt jaunu valodu, bet nekad nebija iespējas, taču eLingo padarīja mācīšanos tik vienkāršu un ērtu man. Šodien es saprotu un sazinos ar dažādu kultūru cilvēkiem, kas ir paplašinājis manu redzesloku un atvēris jaunas iespējas man.",
            photo: Maria,
          },
        ],
      },
    },
  },
  course: {
    title: "Kāds izskatās kurss?",
    right:
      "eLingo platformā jūs neatradīsiet garlaicīgus, sausus gramatikas likumus, kas jāapgūst vienā dienā. Jūs mācāties teikumus un izteicienus, kas tiek izmantoti ikdienas dzīvē, pēc tam nostiprinot tos atmiņā ar vienkāršiem vingrinājumiem un atkārtojumiem. Kursa mērķis ir padarīt mācīšanos jautru.",
    right2Title: "Reālas nodarbības",
    right2Bottom:
      "Mācīšanās katrā līmenī sastāv no desmitiem nodarbību. Ja nezināt savu līmeni - sāciet ar pamatlietām. Apgūstiet sasveicināšanos, nedēļas dienas, savas sajūtas. Uzziniet, kā pasūtīt ēdienu restorānā un kā pasūtīt taksometru. Katra nodarbība satur piemērus no ikdienas dzīves. Katra nodarbība ir nedaudz atšķirīga, sastāv no dažādiem uzdevumiem un vingrinājumiem, bet katra veido veselumu – tādējādi jūs varat viegli apgūt valodu. Jūs varat sākt nodarbību jebkurā laikā.",
    flexDown: [
      {
        title: "Mācīšanās laikā jūs sastapsieties ar tādiem elementiem kā:",
        text: `
        Audio ieraksti, kas māca pareizu izrunu,
      `,
        text1: "Izteicieni un teikumi no reālās dzīves,",
        text2: "Attēli, kas palīdz vizuāli asociēt ",
        text3: "un atcerēties vārdus un izteicienus,",
        text4: "...un daudzi citi.",
      },
      {
        title: "Uzdevumi",
        text: "Neatkarīgi no tā, vai jums patīk risināt puzles katru dienu vai, tieši pretēji, jūs labprātāk izvairāties no šādām aktivitātēm, nevar noliegt, ka šīs puzles ir lieliski piemērotas atmiņas attīstīšanai un ļauj atkārtot apgūto materiālu.",
      },
      {
        title: "Atkārtojumi",
        text: "Pieredzējušāki apmācāmie var atkārtot vārdu krājumu nepārtrauktā mācīšanās režīmā un pārbaudīt savas zināšanas katrā tēmas jomā. Tas ietver visu pieejamo vārdu krājumu no katras kategorijas. Mācīšanās algoritms pastāvīgi pārbauda zināšanu līmeni un attiecīgi pielāgo nākamās nodarbības.",
      },
    ],
  },
  learningWithVerbalia: {
    title: "Mācīšanās eLingo valodu platformā:",
    cards: [
      {
        icon: learning1,
        headline: "Augsta kvalitāte",
        text: "eLingo izstrādes laikā ekspertu komanda izvirzīja vienu no svarīgākajiem mērķiem: padarīt svešvalodas apguvi pēc iespējas ātrāku ar mūsu metodi (neatkarīgi no apmācāmā vecuma!).",
      },
      {
        icon: learning2,
        headline: "Nodarbības, neizejot no mājām",
        text: "Lai izmantotu eLingo mācību lietotni, jums ir nepieciešams tikai jūsu tālrunis, dators vai planšetdators. Ar eLingo jūs varat mācīties jebkur: rindā, autobusā un, kas ir vissvarīgākais, mājās, neizejot no tām. Mācīšanās vannā vai pastaigājoties ar suni? Ar eLingo tas ir iespējams!",
        text2:
          "Mācīšanās ar eLingo ietaupa daudz laika ceļā, un jūs varat viegli integrēt mācīšanos savā grafikā!",
      },
      {
        icon: learning3,
        headline: "Īsas, bet sistemātiskas nodarbības",
        text: "Vissvarīgākā lieta valodas apguvē ir regularitāte. Tajā pašā laikā mums visiem ir grūti atrast nepieciešamo laiku. Ņemot to vērā, eLingo ir izstrādāts, lai mācītu īsās, bet sistemātiskās nodarbībās.",
      },
    ],
  },
  whyVerbalia: {
    title: "Kā izskatās kurss?",
    textUp:
      "Šī ir daudzpusīga mācīšanās pieejas platforma, kas palīdz jums apgūt jaunu valodu tikpat dabiski kā jūsu dzimto valodu.",
    textBottom:
      "Svešvalodas apguve var mainīt jūsu dzīvi. Tā ne tikai atver logu uz pasauli, pilnu ar profesionālām un personīgām iespējām, bet arī attīsta jūsu prātu un ceļ jūsu pašapziņu. Tā kā tādas tehnoloģijas kā internets samazina attālumus un mēs kļūstam par globālās kopienas locekļiem, spēja apgūt valodas kļūst par būtisku daļu no mūsu mijiedarbības ar pasauli un mūsu panākumiem.",
    cards: [
      {
        headline:
          "Apgūstiet ikdienas dzīves izteicienus un frāzes ar mūsu progresīvo mācīšanās tehnoloģiju.",
        text: "Nodarbības tiek nepārtraukti pielāgotas jūsu līmenim, lai jūs nekad nezaudētu interesi vai motivāciju.",
      },
      {
        headline:
          "Iegremdējieties svešvalodā. Mēģiniet pilnībā izprast jaunu valodu, balstoties uz jūsu kontekstuālajām zināšanām.",
        text: "Ar tūkstošiem grafiku un audio ierakstu jūs redzēsiet un dzirdēsiet kā dzimtās valodas runātājs.",
      },
      {
        headline:
          "Iegūstiet pārliecību runāšanā. Valodu apguves patiesais mērķis ir arī saprast.",
        text: "Ar eLingo jums tikai jāatkārto dzirdētie vārdi - nebaidieties runāt! Mūsu sistēma jūs labos.",
      },
      {
        headline:
          "Neļaujiet vārdiem izslīdēt no jūsu atmiņas. Mācieties caur dažādiem vingrinājumiem.",
        text: "Mēs rūpīgi izstrādājām lietotnes elementus, lai jūsu prāts nekad negarlaikotos. Mēs nepārtraukti gatavojam un paplašinām eLingo ar jauniem izaicinājumiem, lai padarītu mācīšanos aizraujošu.",
      },
    ],
  },
  aboutUs: {
    hero: {
      title: "Misija",
      description:
        "Mēs ticam, ka valoda veido tiltu starp kultūrām, un mūsu misija ir pārvarēt komunikācijas barjeras un apvienot cilvēkus caur valodu izglītību.",
    },
    why: {
      title: "Kāpēc Mēs?",
      config: [
        {
          title: "Inovatīvas izglītības metodes",
          image: dlaczego1,
          description:
            "Mūsu sarežģītais un praksē pārbaudītais mācīšanās algoritms pielāgo materiālus jūsu pašreizējam zināšanu līmenim un paplašina to caur efektīvu mācīšanu.",
        },
        {
          title: "Plašs valodu klāsts",
          image: dlaczego2,
          description:
            "Mēs piedāvājam kursus pasaulē populārākajās valodās. Tas ļauj jums izvēlēties valodu, kas atbilst jūsu vajadzībām.",
        },
        {
          title: "Izglītība jautrā veidā",
          image: dlaczego3,
          description:
            "Mūsu platforma padara valodu apguvi par jautru un patīkamu pieredzi, tāpēc jūs būsiet motivēti mācīties valodas.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalizācija un pieejamība",
          description:
            "Mūsu efektīvais mašīnmācīšanās algoritms nepārtraukti strādā, lai nodrošinātu atbilstoša līmeņa materiālus katram indivīdam. eLingo ļauj izglītojamajiem atklāt modeļus patstāvīgi, nekoncentrējoties uz valodas likumiem - tieši tāpat, kā jūs apguvāt savu dzimto valodu bērnībā.",
          image: zdjecie1,
        },
        {
          title: "Tehnoloģijas un zinātnes kombinācija",
          description:
            "eLingo izmanto drosmīgu tehnoloģijas un zinātnes kombināciju, lai stimulētu prātu darbībai. Neskaitāmu vārdu un izteicienu sarakstu atkārtošana ir garlaicīga, un jūsu smadzenes to zina. Mūsu metode ir aizraujoša un efektīva, liekot jums izmantot iegūtās zināšanas dažādos veidos konkrētos brīžos - jūsu smadzenes novērtēs šo risinājumu!",
          image: zdjecie2,
        },
        {
          title: "Kopiena un vērtības",
          description:
            "Valodas palīdz cilvēkiem iesaistīties. Valodu apguve veicina sapratni. eLingo kopiena apvieno cilvēkus no visas pasaules. Mēs esam ambiciozi un vienmēr pārvaramies robežas. Mēs ticam, ka valodu apguve var būt jautra, un esam ziņkārīgi izpētīt jaunas kultūras caur valodu.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Interesē kurss?",
      title1: "Vēlaties vairāk informācijas?",
      subtitle: "Atstājiet savu tālruņa numuru - mēs jums atzvanīsim.",
      subtitle1: "Ja jums jau ir konts, lūdzu, aizpildiet šādus laukus:",
      button1: "Vārds un Uzvārds",
      button2: "Tālruņa numurs",
      button3: "E-pasts",
      buttonSub: "Sūtīt",
      checkOne:
        "Iesniedzot šo veidlapu, es piekrītu noteikumiem un apliecinu, ka esmu izlasījis sekojošo",
      checkOneTwo: "",
      checkTwo: "Es piekrītu, ka ar mani sazinās mārketinga nolūkos",
      textOne: "Lūdzu, ņemiet vērā, ka",
      textTwo:
        "Jūsu personas datu pārzinis ir EOU Sp. z o.o., ar juridisko adresi Varšavā, Młynarska 42 / 115, 01-171, reģistrēts uzņēmēju reģistrā, ko uztur Varšavas rajona tiesa ar KRS5273057993, REGON: 525396900, NIP5273057993. Jūsu personas dati tiks apstrādāti saistībā ar kontaktformas pieprasījumu vai noslēgtā līguma izpildi. Sazinoties ar mums, jūs brīvprātīgi piekrītat savu personas datu apstrādei iepriekš minētajiem mērķiem. Jums ir tiesības piekļūt saviem datiem, tos labot, dzēst vai ierobežot apstrādi, iebilst pret apstrādi un datu pārnesamību. Jums arī ir tiesības jebkurā laikā atsaukt savu piekrišanu, taču piekrišanas atsaukšana neietekmē apstrādes likumību, kas balstīta uz piekrišanu pirms tās atsaukšanas. Jums arī ir tiesības iesniegt sūdzību uzraudzības iestādei. Datu sniegšana ir brīvprātīga, bet var būt nepieciešama, lai atbildētu uz pieprasījumu vai izpildītu līgumu. Personas dati tiks glabāti laika periodā, kas nepieciešams iepriekš minētajiem mērķiem, bet ne ilgāk kā vispārējo tiesību aktu prasījumu noilguma periods. Lūdzu, uzmanīgi izlasiet privātuma politiku",
      buttonMore: "Lasīt vairāk",
      buttonLess: "Lasīt mazāk",
      subOne: "noteikumus",
      subTwo: "un privātuma noteikumus.",
      buttonTXT: "Paldies",
      rulesLink: "/Regulations_EN.pdf",
      privacyLink: "/Privacy_EN.pdf",
      product: "elv",
      market: "lv",
      send_to_url: "https://crm.europa-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_eLingo",
      product_variant: "lv-en",
      server_link: "https://cms.europa-assets.eu/integration/lead-from-landing",
      currency: "eur",
      price: 87,
    },
  },
  footer: {
    privacy: "Privātums",
    privacyLink: "/Privacy_EN.pdf",
    rules: "Pakalpojuma noteikumi",
    rulesLink: "/Regulations_EN.pdf",
  },
};
